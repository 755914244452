import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
};

const taskDateTimeFormat = "MMM D, h:mm A";
export const AddMinutesToTaskTimeAndFormat = (date, minutes) => {
  //console.log(moment(props.startDate).add(minutes, "minutes"));
  if (!date || minutes < 0) {
    return "";
  }

  return moment(date).add(minutes, "minutes").format(taskDateTimeFormat);
};

export const formatTaskDateTime = (date, minutes) => {
  //console.log(moment(props.startDate).add(minutes, "minutes"));
  if (!date || minutes < 0) {
    return "";
  }

  return moment(date).format(taskDateTimeFormat);
};

export const formatOnlyDateToISOString = (date) => {
  return date ? moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z" : moment().format("YYYY-MM-DD") + "T00:00:00.000Z";
};

export const formatOnlyDateToStartEndOfDay = (date, startEnd) => {
  if (startEnd === "start")
    return date
      ? new Date(moment(date).startOf("day").toDate()).toISOString()
      : new Date(moment().startOf("day").toDate()).toISOString();
  else
    return date
      ? new Date(moment(date).endOf("day").toDate()).toISOString()
      : new Date(moment().endOf("day").toDate()).toISOString();
};

export const convertToDaysHoursMinutes = (minutesToConvert) => {
  const day = Math.floor(minutesToConvert / 1440); // 60*24
  const hour = Math.floor((minutesToConvert - day * 1440) / 60);
  const minute = Math.round(minutesToConvert % 60);

  let result = "";

  if (minutesToConvert === 0) {
    return "0 min";
  }

  if (day !== 0) {
    result += day;
    if (day === 1) {
      result += " day ";
    } else {
      result += " days ";
    }
  }

  if (hour !== 0) {
    result += hour;

    if (hour === 1) {
      result += " hr ";
    } else {
      result += " hrs ";
    }
  }

  if (minute !== 0) {
    result += minute;

    if (minute === 1) {
      result += " min";
    } else {
      result += " mins";
    }
  }

  return result;
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};
