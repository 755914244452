import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    userName: "",
    token: null, //"test-token",
    userId: "",
    userEmail: "",
    userRole: "",
    userCustomer: null,
    saasTitle: "",
    otherInfo: {},
    error: null,
    loading: false,
    authRedirectPath: '/',
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
    return updateObject( state, { 
        userName: action.user.name,
        token: action.idToken,
        userId: action.user._id,
        userEmail: action.user.email,
        userRole: action.user.role,
        userCustomer: action.user.customer,
        saasTitle: action.saasTitle,
        otherInfo: action.otherInfo,
        error: null,
        loading: false
     } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { userName: null, token: null, userId: "", userRole: "", error: null, loading: false });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        default:
            return state;
    }
};

export default reducer;