import { put, call, delay } from "redux-saga/effects";
import axios from "axios";
import * as axiosAPI from "../../components/utils/getSetAPIData"; //"utils/getSetAPIData";
import setAuthToken from "../../shared/setAuthToken";

import * as actions from "../actions/index";
import { InfoOutlined } from "@material-ui/icons";

export function* logoutSaga(action) {
  yield call([localStorage, "removeItem"], "jwtToken");
  setAuthToken(null);
  //yield call([localStorage, "removeItem"], "expirationDate");
  //yield call([localStorage, "removeItem"], "userId");
  yield put(actions.logoutSucceed());
}

export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000);
  yield put(actions.logout());
}

export function* authUserSaga(action) {
  yield put(actions.authStart());

  //  yield delay(3000)

  const authData = {
    email: action.email,
    password: action.password,
    saasID: action.saasID
  };

  const dataUrl = "/api/v1/users/login";

  const response = yield axiosAPI.getSetAxiosData("post", dataUrl, authData);

  //console.log("auth data:", response);

  //  yield put(actions.authFail("Cannot Login Fake"));

  if (response.status === "success") {
    //    const response = yield axios.post(url, authData);

    //const expirationDate = yield new Date(
    //  new Date().getTime() + response.data.expiresIn * 1000
    //);

    yield localStorage.setItem("jwtToken", response.data.token);
    //yield localStorage.setItem("expirationDate", expirationDate);
    //yield localStorage.setItem("userId", response.data.data.user.email);
    //yield localStorage.setItem("userName", response.data.data.user.name);
    setAuthToken(response.data.token);

    yield put(
      actions.authSuccess(
        response.data.token,
        response.data.data.user,
        response.data.data.saasTitle,
        response.data.data.otherInfo || {}
      )
    );

    //yield put(actions.checkAuthTimeout(response.data.expiresIn));
  } else {
    yield put(actions.authFail(response.message));
  }
}

export function* authCheckStateSaga(action) {
  const token = yield localStorage.getItem("token");
  if (!token) {
    yield put(actions.logout());
  } else {
    const expirationDate = yield new Date(localStorage.getItem("expirationDate"));
    if (expirationDate <= new Date()) {
      yield put(actions.logout());
    } else {
      const userId = yield localStorage.getItem("userId");
      yield put(actions.authSuccess(token, userId));
      yield put(actions.checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
    }
  }
}
