import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
// import { DisplayFormikState } from './formikHelper';

const useStyles = makeStyles((theme) => ({
  textField123: {
    color: "red",
  },
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${
      theme.spacing.unit * 0
    }px`,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "70%",
      width: "70%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
  formikForm: {
    width: "100%",
  },
}));

const FormikNew1 = () => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={{ firstName: "", lastName: "", email: "" }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .max(15, "Must be 15 characters or less")
          .required("Required"),
        lastName: Yup.string()
          .max(20, "Must be 20 characters or less")
          .required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 5000);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;

        return (
          <React.Fragment>
            <div className={classes.container}>
              <Paper elevation={1} className={classes.paper}>
                <h1>Customer Form</h1>

                <form className={classes.formikForm} onSubmit={handleSubmit}>
                  <TextField
                    id="firstName"
                    fullWidth
                    {...props.getFieldProps("firstName")}
                    label="First Name"
                    // className={classes.textField}
                    helperText={touched.firstName ? errors.firstName : ""}
                    error={touched.firstName && Boolean(errors.firstName)}
                    margin="dense"
                  />

                  <TextField
                    id="lastName"
                    fullWidth
                    {...props.getFieldProps("lastName")}
                    label="Last Name"
                    //className={classes.textField}
                    helperText={touched.lastName ? errors.lastName : ""}
                    error={touched.lastName && Boolean(errors.lastName)}
                    margin="dense"
                  />

                  <TextField
                    id="email"
                    fullWidth
                    {...props.getFieldProps("email")}
                    label="Email"
                    //className={classes.textField}
                    helperText={touched.email ? errors.email : ""}
                    error={touched.email && Boolean(errors.email)}
                    margin="dense"
                  />

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      style={{ marginBottom: "25px", marginTop: "25px"  }}
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </Paper>
            </div>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};

export default FormikNew1;
