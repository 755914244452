import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import { makeStyles, useTheme, duration } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import * as Yup from "yup";

import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import { AddMinutesToTaskTimeAndFormat, convertToDaysHoursMinutes } from "shared/utility";

//const styles = theme => ({
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "80%",
      width: "80%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "80%",
      width: "80%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
}));

let validationFeilds = {};
let initialValues = {};

let roleData = null; // [{ _id: "", name: "" }];

let validationSchema;
let stateData = {};
let formTitle = "Add Userr";
let isNew = true;

function AddAmmendTask(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [autoCompleteKey, setAutoCompleteKey] = useState(Math.random());
  const [initialValues1, setInitialValues1] = useState({});

  const getTaskTime = (minutes) => {

    //console.log(moment(props.startDate).add(minutes, "minutes"));
    if(!stateData.startDate || (stateData.task.taskEST === 0 && stateData.task.taskEFT === 0)) {
      return "";
    }

    return AddMinutesToTaskTimeAndFormat(stateData.startDate, minutes);
  }


  useEffect(() => {
    //console.log("use effect user");

    setAutoCompleteKey(Math.random());

    validationFeilds = {
      name: Yup.string("Enter a Task Name").required("Task Name is required"),
      description: Yup.string("Enter Task Detail"),
      duration: Yup.number()
        .required("Task Duration Required")
        .positive("Task Duration should be a positive number.")
        .integer("Task Duration should be an integer.")
        .moreThan(0, "Task Duration should be greater tham 0"),
    };

    initialValues = {
      name: "",
      description: "",
      duration: 0,
    };

    formTitle = "";
    isNew = true;
    stateData = {};
    if (props.taskDetails && !props.taskDetails.isNew) {
      stateData = props.taskDetails;
      isNew = props.taskDetails.isNew;
      formTitle = " Task ID: " + stateData.task.taskID;

      initialValues.name = stateData.task.name;
      initialValues.description = stateData.task.description;
      initialValues.duration = stateData.displayOnly
        ? convertToDaysHoursMinutes(stateData.task.duration)
        : stateData.task.duration;
    }

    setInitialValues1(initialValues);

    //console.log(validationFeilds, initialValues);

    if (stateData.displayOnly) {
      validationSchema = Yup.object({});
    } else {
      validationSchema = Yup.object(validationFeilds);
    }

    resetForm();
  }, [props.taskDetails]);

  const {
    handleSubmit,
    setFieldValue,
    errors,
    isSubmitting,
    isValid,
    touched,
    getFieldProps,
    setTouched,
    resetForm,
    dirty,
  } = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (data, actions) => {
      actions.setSubmitting(true);

      toastMessage.DisplayToast(isNew ? "Task Added" : "Task Updated...", {
        type: toastMessage.messageType.SUCCESS,
      });

      const submitData = { ...data };
      if (!isNew) {
        submitData.taskID = stateData.task.taskID;
      }

      submitData.isNew = isNew;

      //console.log("dialogdata", submitData);

      props.handleSubmit(submitData);

      /*           if (isNew) {
            actions.resetForm({});
            roleData = null; //[{ _id: "", name: "" }];
            setAutoCompleteKey(Math.random());
            actions.setSubmitting(false);
          } else {
            props.history.replace("/user", { isNew: false });
          }
 */
      actions.setSubmitting(false);
    },
  });

  return (
    <React.Fragment>
      <div className={classes.container}>
        {/* <Paper elevation={1} className={classes.paper}> */}
        <form autoComplete="nope" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/*             {!isNew ? 
            <Grid item xs={12}>
              <h3 style={{ color: theme.sidedrawer.subListTextColor }}>{formTitle}</h3>
            </Grid>
            : null }
 */}
            <Grid item xs={12}>
              <TextField
                style={{ marginBottom: "0px" }}
                id="name"
                autoComplete="nope"
                helperText={touched.name ? errors.name : ""}
                error={touched.name && Boolean(errors.name)}
                label="Name"
                fullWidth
                {...getFieldProps("name")}
                {...(!isNew && { InputLabelProps: { shrink: true } })}
                {...(stateData.displayOnly && { InputProps: { readOnly: true } })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ marginBottom: "0px" }}
                id="description"
                autoComplete="nope"
                multiline
                rows={4}
                helperText={touched.description ? errors.description : ""}
                error={touched.description && Boolean(errors.description)}
                label="Description"
                fullWidth
                {...getFieldProps("description")}
                {...(!isNew && { InputLabelProps: { shrink: true } })}
                {...(stateData.displayOnly && { InputProps: { readOnly: true } })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                style={{ marginBottom: "0px" }}
                id="duration"
                autoComplete="nope"
                helperText={touched.duration ? errors.duration : ""}
                error={touched.duration && Boolean(errors.duration)}
                label={stateData.displayOnly ? "Duration" : "Duration (in minutes)"}
                fullWidth
                {...getFieldProps("duration")}
                {...(!isNew && { InputLabelProps: { shrink: true } })}
                {...(stateData.displayOnly && { InputProps: { readOnly: true } })}
              />
            </Grid>
            <Grid sm={6}></Grid>
            {!stateData.isCheckList || !stateData.displayOnly ? null : (
              <React.Fragment>

                <Grid item xs={12} sm={6}>
                  <TextField
                    style={{ marginBottom: "0px" }}
                    id="EST"
                    autoComplete="nope"
                    value={getTaskTime(stateData.task.taskEST)}
                    label="Early Start Time(EST)"
                    fullWidth
                    {...(!isNew && { InputLabelProps: { shrink: true } })}
                    {...(stateData.displayOnly && { InputProps: { readOnly: true } })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    style={{ marginBottom: "0px" }}
                    id="EET"
                    autoComplete="nope"
                    value={getTaskTime(stateData.task.taskEFT)}
                    label="Early Finish Time(EFT)"
                    fullWidth
                    {...(!isNew && { InputLabelProps: { shrink: true } })}
                    {...(stateData.displayOnly && { InputProps: { readOnly: true } })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    style={{ marginBottom: "0px" }}
                    id="LST"
                    autoComplete="nope"
                    value={getTaskTime(stateData.task.taskLST)}
                    label="Late Start Time(LST)"
                    fullWidth
                    {...(!isNew && { InputLabelProps: { shrink: true } })}
                    {...(stateData.displayOnly && { InputProps: { readOnly: true } })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    style={{ marginBottom: "0px" }}
                    id="LET"
                    autoComplete="nope"
                    value={getTaskTime(stateData.task.taskLFT)}
                    label="Late Finish Time(LFT)"
                    fullWidth
                    {...(!isNew && { InputLabelProps: { shrink: true } })}
                    {...(stateData.displayOnly && { InputProps: { readOnly: true } })}
                  />
                </Grid>
              </React.Fragment>
            )}
            <div style={{ width: "100%", textAlign: "center" }}>
              {!isSubmitting ? (
                <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                  {!stateData.displayOnly ? (
                    <Button
                      //                  style={{ marginTop: "15px", marginBottom: "15px" }}
                      style={{ marginRight: "15px" }}
                      type="submit"
                      //fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      Submit
                    </Button>
                  ) : null}
                  <Button
                    //type="button"
                    //fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => props.handleClose()}
                  >
                    {stateData.displayOnly ? "Exit" : "Cancel"}
                  </Button>
                </div>
              ) : (
                <div style={{ marginTop: "15px", marginBottom: "30px" }}>{/* <CircularProgress /> */}</div>
              )}
            </div>
            {/* <div> {isSubmitting.toString()} </div> */}
          </Grid>
        </form>
        {/* </Paper> */}
      </div>
    </React.Fragment>
  );
}

export default AddAmmendTask;
