import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    //padding: theme.spacing(0.5),
    backgroundColor: "orange"
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function DependencyArray(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {props.dependencyData.map((data) => {
        let icon;

        if (data.description === 'React') {
          icon = <TagFacesIcon />;
        }

        const disableDelete=(props.taskID === "0" || props.taskID === "9999" || data.taskID === "0" || data.taskID === "9999") ? true : false;
        
        return (
          <Chip
            size="small"
            color="primary"
            key={data.taskID + "-chip"}
            icon={icon}
            label={data.taskID === "0" || data.taskID === "9999" ? data.description : data.taskID}
            //onDelete={(data.description === 'React' || disableDelete) ? undefined : () => props.handleDependencyDelete(data, props.dependencyType)}
            className={classes.chip}
          />
        );
      })}
    </Paper>
  );
}
