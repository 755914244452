import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";

import { createMuiTheme } from "@material-ui/core/styles";
import { purple, green, orange, yellow } from "@material-ui/core/colors";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import authReducer from "./store/reducers/auth";
//import { watchAuth, watchBurgerBuilder, watchOrder } from "./store/sagas";
import { watchAuth } from "./store/sagas";
import axios from "axios";

let composeEnhancers = process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null;

if (!composeEnhancers) composeEnhancers = compose;

const rootReducer = combineReducers({
  //  burgerBuilder: burgerBuilderReducer,
  //  order: orderReducer,
  auth: authReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(watchAuth);
//sagaMiddleware.run(watchBurgerBuilder);
//sagaMiddleware.run(watchOrder);

//AXIOS

if (process.env.NODE_ENV === "development") axios.defaults.baseURL = "http://192.168.15.170:4000"; // "http://192.168.15.171:4000"; // "http://localhost:4000";
else {
//  axios.defaults.baseURL = "http://3.134.114.162";   //prv ip
  axios.defaults.baseURL = "https://www.multisiterollouttracker.com" //"3.21.200.156";
}

//axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post["Content-Type"] = "application/json";
/* 
axios.interceptors.request.use(
  (request) => {
    console.log(request);
    // Edit request config
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    console.log(response);
    // Edit request config
    return response;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
 */
let theme = createMuiTheme({
  palette: {
    //primary: { main: '#dc004e'},
    //secondary: green
  },
  sidedrawer: {
    mainHeaderTextColor: "orange",
    subListTextColor: "",
    subListIconColor: "",
    optionTextColor: "black",
    optionIconColor: "black",
  },
  globalVariables: {
    currentSize: "",
  },
});

theme = responsiveFontSizes(theme);
theme.sidedrawer.subListTextColor = theme.palette.primary.main;
theme.sidedrawer.subListIconColor = theme.palette.primary.main;
theme.sidedrawer.mainHeaderTextColor = theme.palette.secondary.main;

const app = (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <App />
      </MuiPickersUtilsProvider>        
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
