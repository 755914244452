import React, { useState, useCallback } from "react";
import Card from "./card";
import update from "immutability-helper";
const style = {
  width: "300"
};
const Container = () => {
  const [cards, setCards] = useState([
    {
      id: 1,
      toText: " ",
      text: "Task A"
    },
    {
      id: 2,
      toText: " ",
      text: "Task B"
    },
    {
      id: 3,
      toText: " ",
      text: "Task C"
    },
    {
      id: 4,
      toText: " ",
      text: "Task D"
    },
    {
      id: 5,
      toText: " ",
      text:
        "Task E"
    },
    {
      id: 6,
      toText: " ",
      text: "Task F"
    },
    {
      id: 7,
      toText: " ",
      text: "Task G"
    }
  ]);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        /*           update(cards, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          }),
        )
 */

        update(cards, 
            {[hoverIndex]: {toText: {$set: cards[dragIndex].text}}
        })
        

      );
      console.log("upd_cards", cards);
    },
    [cards]
  );
  const renderCard = (card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.text} //{card.text + " => " + card.toText}
        toText={card.toText} //{card.text + " => " + card.toText}
        moveCard={moveCard}
      />
    );
  };
  return (
    <>
      <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};

export default Container;
