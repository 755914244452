import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MaterialTable, { MTableToolbar } from "material-table";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import moment from "moment";

//import axios from "axios";

import * as axiosAPI from "../utils/getSetAPIData";
import tableIcons, { addCustomButton } from "../utils/materialTableIcons";
import * as materialTableDefaults from "../utils/materialTableDefaults";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import { convertToDaysHoursMinutes } from "shared/utility";
import mtQueryClass from "shared/mtQueryDataClass";

//const styles = theme => ({
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      width: "100",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
      maxWidth: "90%",
      width: "90%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
    padding: 0,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
}));

let tableColumns = [];
const title = "Job Execution List";
//let tableClickActions = {};
const mtQuery = new mtQueryClass();
mtQuery.searchUrl = "&searchField=name&searchValue=";
mtQuery.url = "/api/v1/checkLists/getUserJobs?sort=startDateTime";
const dateFormat = "MMM D, YYYY h:mm A";

function JobList(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [serverError, setServerError] = useState({ isError: false, message: " ", type: "SERVER", statusCode: 0 });

  const isSMUp = useMediaQuery(theme.breakpoints.up("sm"));
  swal2.setTheme(theme);
  const tableRef = React.useRef();

  const getMTActions = () => {
    const tableClickActions = {
      onClickRefreshFab: (event) => {
        if (tableRef.current) {
          tableRef.current.onQueryChange();
        }
      },
    };

    addCustomButton("execJob", "Start/Join Job");

    const mtActions = materialTableDefaults.mtActions(props, tableIcons, { tableClickActions }, "Jobs");

    mtActions.push({
      icon: tableIcons.execJob,
      //iconProps: { style: { fontSize: "14px", color: "blue" } },
      tooltip: "Start/Join Job",
      onClick: async (event, rowData) => {
        if (!rowData.startedAt) {
          const confirm = await swal2.confirmationDialog("Are you sure to Start CheckList", "Confirm Action", {
            type: "danger",
          });

          if (confirm.status === ":OK") {
            const { tableData, ...stateData } = rowData;
            props.history.push("/jobExecutionForm", { isNew: false, stateData });
          }
        }
        else {
          const { tableData, ...stateData } = rowData;
          props.history.push("/jobExecutionForm", { isNew: false, stateData });
        }
      },
    });

    return mtActions;
  };

  if (isSMUp) {
    tableColumns = [
      { title: "CheckList Name", field: "name" }, // width: 500, minWidth: 500 },
      { title: "Customer Name", field: "customer.name" },
      { title: "Site", field: "branch.name" },
      {
        title: "Start Date/TIme",
        field: "startDateTime",
        render: (rowData) => moment(rowData.startDateTime).format(dateFormat),
      },
      { title: "Status", field: "status", width: 100, maxWidth: 100 },
      //{ title: "Doğum Yılı", field: "birthYear", type: "numeric" },
      /*       {
        title: "Doğum Yeri",
        field: "birthCity",
        lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      },
 */
    ];
  } else {
    tableColumns = [
      { title: "CheckList Name", field: "name" },
      //{ title: "Soyadı", field: "surname" },
      //{ title: "Doğum Yılı", field: "birthYear", type: "numeric" },
      //{ title: "Email", field: "email" }
    ];
  }

  const detailPanel = (rowData) => {
    let fieldArray = [];

    if (isSMUp) {
      fieldArray = [
        { heading: "Users :", value: rowData.users.map((user) => user.name).join(", ") },
        { heading: "Time Zone :", value: rowData.branch.timeZone },
        { heading: "Start Date/Time :", value: moment(rowData.startDateTime).format(dateFormat) },
        { heading: "End Date/Time :", value: moment(rowData.endDateTime).format(dateFormat) },
        { heading: "Total Time :", value: convertToDaysHoursMinutes(rowData.otherInfo.criticalPathDuration) },
      ];
    } else {
      fieldArray = [
        { heading: "Customer :", value: rowData.customer.name },
        { heading: "Site :", value: rowData.branch.name },
        { heading: "Users :", value: rowData.users.map((user) => user.name).join(", ") },
        { heading: "Status :", value: rowData.status },
        { heading: "Time Zone :", value: rowData.branch.timeZone },
        { heading: "Start Date/Time :", value: moment(rowData.startDateTime).format(dateFormat) },
        { heading: "End Date/Time :", value: moment(rowData.endDateTime).format(dateFormat) },
        { heading: "Total Time :", value: convertToDaysHoursMinutes(rowData.otherInfo.criticalPathDuration) },
      ];
    }

    return (
      <div style={{ marginTop: "15px", marginBottom: "15px" }}>
        {fieldArray.map((tableField) => {
          return (
            <Grid container spacing={1} alignItems="center" flex={1}>
              <Grid item xs={5} sm={3} style={{ paddingLeft: "10px" }}>
                <Typography variant="subtitle2" component="span" color="primary">
                  {tableField.heading}
                </Typography>
              </Grid>
              <Grid item xs={7} sm={9}>
                <Typography variant="body2" component="span">
                  {/* {tableField[1] === "country" ? rowData.country.name : rowData[tableField[1]]} */}
                  {tableField.value}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [isSMUp]);

  //  useEffect(() => {
  //console.log("tableref", tableRef);
  //  }, [tableRef]);

  //  console.log("PAGE RENDERED");

  const GetQueryData = (query) => {
    return mtQuery.getMaterialTableQueryData(query, setServerError);
  };

  //console.log("currdata", currentData) ;
  //  alert("render");
  return (
    <React.Fragment>
      {/* <ToastMessages alertOpen={alertOpen} setAlertOpen={setAlertOpen} /> */}
      {/* <ToastContainer /> */}
      <div className={classes.container}>
        {/* <Paper elevation={1} className={classes.paper}> */}
        {/* <h2>User Form</h2> */}
        <div className={classes.paper}>
          {serverError.isError ? (
            <div style={{ width: "100%" }}>
              <h1 style={{ textAlign: "center" }}>{serverError.statusCode === "403" ? "UNAUTHORIZED" : "SERVER ERROR"}</h1>
              <h2 style={{ textAlign: "center" }}>{serverError.message}</h2>
            </div>
          ) : (
            <MaterialTable
              //              isLoading={true}
              tableRef={tableRef}
              components={{
                Toolbar: (props) => (
                  <React.Fragment>
                    {!isSMUp ? (
                      <div style={{ width: "100%", marginTop: "15px" }}>
                        <Typography variant="h6" color="primary" align="center" gutterBottom>
                          {" "}
                          {title}{" "}
                        </Typography>
                      </div>
                    ) : null}

                    <div style={{ color: theme.sidedrawer.subListTextColor }}>
                      <MTableToolbar {...props} />
                    </div>
                  </React.Fragment>
                ),
              }}
              style={{ width: "100%", paddingLeft: "0px" }}
              columns={tableColumns}
              //data={[{ name: "Mehmet", surname: "Baran", birthYear: 1987, birthCity: 63 }]}
              data={GetQueryData}
              title={isSMUp ? title : ""}
              icons={tableIcons}
              detailPanel={detailPanel}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
              //              {...(!isSMUp && { detailPanel: detailPanel })}
              //              {...(!isSMUp && { onRowClick: (event, rowData, togglePanel) => togglePanel() })}
              actions={getMTActions()}
              options={{
                sorting: false,
                detailPanelType: "single",
                actionsColumnIndex: -1,
                actionsCellStyle: {
                  //backgroundColor: "#ffccdd",
                  color: theme.sidedrawer.subListTextColor,
                  textAlign: "center",
                  minWidth: "140px", // "rgba(255, 175, 0, 0.6)"
                },
                headerStyle: {
                  backgroundColor: "#01579b", //'#e8eaf5',
                  color: "#FFF",
                },
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} of {count}",
                },
              }}
            />
          )}

          {/* </Hidden> */}
        </div>
        {/* </Paper> */}
      </div>
    </React.Fragment>
  );
}

export default JobList;
