import React from "react";
import Typography from "@material-ui/core/Typography";

//{ id: 0, taskid: "START", duration: 0, desc: "START", childs: [1,10] },

const PathFinder = (params) => {
  const taskData = [
    { id: 0, taskid: "START", duration: 0, desc: "START", childs: [1,10] },
    { id: 10, taskid: "H", duration: 3000, desc: "Install Router", childs: [11] },
    {
      id: 1,
      taskid: "A",
      duration: 30,
      desc: "Assemble Hardware",
      childs: [2],
    },
    {
      id: 2,
      taskid: "B",
      duration: 40,
      desc: "Windows 2008",
      childs: [3, 4, 5],
    },
    { id: 3, taskid: "C", duration: 150, desc: "ISA Server", childs: [6] },
    { id: 4, taskid: "D", duration: 60, desc: "Email Server", childs: [6] },
    {
      id: 5,
      taskid: "E",
      duration: 20,
      desc: "Install Virtual Machine",
      childs: [7],
    },
    {
      id: 6,
      taskid: "F",
      duration: 30,
      desc: "Integrate ISA & EMail Server",
      childs: [9999],
    },
    {
      id: 7,
      taskid: "G",
      duration: 40,
      desc: "Linux on Virtual Machine",
      childs: [9999],
    },
    { id: 9999, taskid: "END", duration: 0, desc: "END", childs: [] },
    { id: 11, taskid: "H", duration: 30, desc: "Cableing", childs: [9999] },
  ];

  var startNode = taskData[0];
  var finishNodeID = 9999;

  function clone(currPath) {
    return JSON.parse(JSON.stringify(currPath));
  }

  var validPaths = [];
  var criticalPath = 0;
  var maxDuration = 0;

  function traverse(node, path) {
    if (node === undefined) {
      node = startNode;
    }
    if (path === undefined) {
      path = [];
    }
    path.push(node.id);

//    console.log("Current Path", path);

    if (node.id === finishNodeID) {
//      console.log("Found Valid", path);
      validPaths.push(clone(path));
      return;
    }
    node.childs.forEach((x) => {
      const currNode = taskData.find((task) => task.id === x);

      if (path.indexOf(currNode.id) === -1) {
        var newPath = clone(path);
        traverse(currNode, newPath);
      }
    });
  }

  traverse();

  var totalDuration = 0;

  validPaths.forEach((x, index) => {
    totalDuration = 0;
    x.forEach((y) => {
      const currTask = taskData.find((task) => task.id === y);
      totalDuration = totalDuration + currTask.duration;
    });

    if (totalDuration > maxDuration) {
      maxDuration = totalDuration;
      criticalPath = index;
    }

    //    console.log("duration", totalDuration);
    //    console.log("cp and index", criticalPath, index);
  });

  return (
    <React.Fragment>
      <Typography
        paragraph
        variant="h1"
        style={{ textDecoration: "underline" }}
      >
        All Paths
      </Typography>
      <br />
      <Typography paragraph>
        <div>
          {validPaths.map((x, index) => {
            //console.log("index map", index);
            return (
              <div>
                <div>
                  <h1>
                    PATH{" " + (index + 1)}
                    {index === criticalPath
                      ? " - CRITICAL PATH"
                      : " "}
                  </h1>
                </div>{" "}
                {x.map((y) => {
                  const currTask = taskData.find((task) => task.id === y);
                  return currTask.desc + (y === finishNodeID ? " " : " => ");
                })}{" "}
                <br />
                <br />
              </div>
            );
          })}
        </div>
      </Typography>
      <br />
      <br />
      {/*       <Button variant="contained" color="primary" onClick={logout} style={{textTransform: "none"}}>
         Log Out
      </Button>
 */}
    </React.Fragment>
  );
};

export default PathFinder;
