import isEqual from "lodash/isEqual";
import * as axiosAPI from "components/utils/getSetAPIData";

export default class MaterialTableQuery {
  currentSearch = "";
  currentPageNo = 0;
  currentData = [];
  currentTotalCount = 0;
  lastMTQuery = {};

  respData = {};
  promiseData = {};
  url = null;
  searchUrl = null;
  serverErrorJSON = null;

  getMaterialTableQueryData(query, setServerError) {
    this.serverErrorJSON = null;
    this.respData = {};

    return new Promise(async (resolve, reject) => {

      if (1===2 && isEqual(query, this.lastMTQuery)) {
//        console.log("using last query data");

        return resolve({
          data: [],
          page: 0,
          totalCount: 0
        });

      }
 
      this.lastMTQuery = query;
      //          console.log("get new query data");

      //console.log(query, tableRef.current);
      let searchQueryString = "";
      /*       if (useInitialQuery) {
            query.page = 1;
          }
     */
      if (this.currentSearch !== query.search) {
        //alert("Search Changed");
        const lastCurrentSearch = this.currentSearch;
        this.currentSearch = query.search;
        if (query.search.length < 3) {
          if (lastCurrentSearch.length >= 3) {
            searchQueryString = "";
          } else {
            return resolve({
              data: this.currentData,
              page: this.currentPageNo,
              totalCount: this.currentTotalCount,
            });
          }
        }
      }

      if (this.currentSearch.length >= 3) {
        searchQueryString = this.searchUrl + query.search;
      }

      const dataUrl = this.url + "&page=" + ((query.page ? query.page : 0)  * 1 + 1) + "&limit=" + query.pageSize + searchQueryString;

      this.respData = await axiosAPI.getSetAxiosData("get", dataUrl);
      //console.log("get new query data");

      if (this.respData.status === "success") {
        this.currentData = this.respData.data.data.data;
        this.currentPageNo = this.currentData.length > 0 ? this.respData.data.page - 1 : 0;
        this.currentTotalCount = this.currentData.length > 0 ? this.respData.data.totalCount : 0;
        return resolve({
          data: this.currentData,
          page: this.currentPageNo,
          totalCount: this.currentTotalCount, //testdata.length,
        });
      } else {
        resolve({
          data: [],
          page: 0,
          totalCount: 0,
        });
        setServerError({
          isError: true,
          message: this.respData.message,
          statusCode: this.respData.statusCode ? this.respData.statusCode : 0,
        });
        //setAlertOpen({ open: true, message: respData.message, severity: "error" });
        //console.log("server error", this.respData);
      }
    });
  }
}
