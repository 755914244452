import React from "react";
import Typography from "@material-ui/core/Typography";
import { Activity, ActivityList } from "../utils/calculate-cpm-etc";

//{ id: 0, taskid: "START", duration: 0, desc: "START", childs: [1,10] },

const NewPathFinder = (params) => {

  if (1===2) {
  var table = new ActivityList();
  table.addActivity(
    new Activity({
      id: "A",
      duration: 8,
    })
  );

  table.addActivity(
    new Activity({
      id: "B",
      duration: 3,
    })
  );

  table.addActivity(
    new Activity({
      id: "C",
      duration: 1,
      predecessors: ["A", "B"],
    })
  );

  table.addActivity(
    new Activity({
      id: "D",
      duration: 6,
      predecessors: ["C", "B"],
    })
  );

  table.addActivity(
    new Activity({
      id: "E",
      duration: 4,
      predecessors: ["D", "C", "F", "G"],
    })
  );

  table.addActivity(
    new Activity({
      id: "F",
      duration: 18,
      predecessors: ["B"],
    })
  );

  table.addActivity(
    new Activity({
      id: "G",
      duration: 10,
      predecessors: ["A", "C"],
    })
  );

  console.log("TABLE", table.getList());

  var path = table.getCriticalPath("E");

  //RETURNS E->F->B
  console.log(path);

  //########################## Example 2 ######################
  /**
   * A -> C -> D
   *     /
   *    B
   */
  var table2 = new ActivityList();
  table2.addActivity(
    new Activity({
      id: "A",
      duration: 1,
    })
  );

  table2.addActivity(
    new Activity({
      id: "B",
      duration: 1,
      predecessors: [],
    })
  );

  table2.addActivity(
    new Activity({
      id: "C",
      duration: 3,
      predecessors: ["A", "B"],
    })
  );

  table2.addActivity(
    new Activity({
      id: "D",
      duration: 6,
      predecessors: ["C"],
    })
  );

  console.log("TABLE 2", table2.getList());

  var path2 = table2.getCriticalPath("D");

  /* 
   * RETURNS D->C->A
   *	       \
   *		B
   */
  console.log(path2);

  }

 /* *************** EXAPLE 3 *************  */
 
  var startNodeID = "0";
  var finishNodeID = "9999";

  var table3 = new ActivityList();

  table3.addActivity(
    new Activity({
      id: startNodeID,
      duration: 0,
    })
  );

  table3.addActivity(
    new Activity({
      id: "1",
      duration: 30,
      predecessors: ["0"],
    })
  );

  table3.addActivity(
    new Activity({
      id: "2",
      duration: 45,
      predecessors: ["1"],
    })
  );

  table3.addActivity(
    new Activity({
      id: "3",
      duration: 150,
      predecessors: ["2"],
    })
  );

  table3.addActivity(
    new Activity({
      id: "4",
      duration: 60,
      predecessors: ["2"],
    })
  );

  table3.addActivity(
    new Activity({
      id: "5",
      duration: 30,
      predecessors: ["2"],
    })
  );

  table3.addActivity(
    new Activity({
      id: "6",
      duration: 20,
      predecessors: ["3", "4"],
    })
  );

  table3.addActivity(
    new Activity({
      id: "7",
      duration: 40,
      predecessors: ["5"],
    })
  );

  table3.addActivity(
    new Activity({
    id: "10",
    duration: 100,
    predecessors: ["0"],
  })
);

  table3.addActivity(
    new Activity({
      id: finishNodeID,
      duration: 0,
      predecessors: ["6", "7", "10"],
    })
  );

  var path3 = table3.getCriticalPath(finishNodeID);
  var table3List = table3.getList();

  var criticalArrayIDs = [];

  if (1===2) {
  console.log("Path3", path3);

  
  function genCriticalArray(obj) {
    //console.log("obj", obj);
    if(!obj.predecessors[0])
       return;

    criticalArrayIDs.push(obj.id);
      genCriticalArray(obj.predecessors[0]);
  }
  
  genCriticalArray(path3);

  criticalArrayIDs.reverse();
  
  console.log("critical array",criticalArrayIDs);
}

  return (
    <React.Fragment>
      <Typography
        paragraph
        variant="h1"
        style={{ textDecoration: "underline" }}
      >
        All Paths
      </Typography>
      <br />
      <div style={{ fontSize: "30px" }}>
        <div>
          {criticalArrayIDs.map(y => {
//            const currTask = table3.list.find((task) => task.id === y);
            const currTask = table3List[y];
            return  currTask.id + (currTask.id === finishNodeID ? " " : " => ");
          })}
        </div>
      <div>
      {Object.keys(table3List).map((currID,i) => {
          return <div>
              {currID + "  Dur:" + table3List[currID].duration + "  EST:" + table3List[currID].est + "  LST:" + table3List[currID].lst + "  EET:" + table3List[currID].eet + "  LET:" + table3List[currID].let}
          </div>
      })}
}          </div>  
      </div>
      <br />
      <br />
      {/*       <Button variant="contained" color="primary" onClick={logout} style={{textTransform: "none"}}>
         Log Out
      </Button>
 */}
    </React.Fragment>
  );
};

export default NewPathFinder;
