export const startTaskID = "0";
export const endTaskID = "9999";

export const defaultCheckListProps = {
  taskEST: 0,
  taskEFT: 0,
  taskLST: 0,
  taskLFT: 0,
};

export const addNewTask = (taskData, taskDetails, addCheckListProps = false) => {
  let newID = Math.max.apply(
    Math,
    taskData.map(function (task) {
      return task.taskID === endTaskID ? startTaskID : task.taskID;
    })
  );

  newID = parseInt(newID) + 1;

  let propsToAdd = {};

  if (addCheckListProps) {
    propsToAdd = defaultCheckListProps;
  }

  const newTaskJSON = {
    taskID: newID.toString().trim(),
    duration: taskDetails.duration,
    name: taskDetails.name,
    description: taskDetails.description,
    predecessor: [startTaskID],
    successor: [endTaskID],
    ...propsToAdd,
  };

  //console.log(newTaskJSON);

  let sortIndex = getMaxPredecessorIndexForSort(taskData, startTaskID, 0, newTaskJSON.taskID);
  if (sortIndex === -1) sortIndex = 0;

  const newTaskData = Array.from(taskData);

  newTaskData.splice(sortIndex + 1, 0, newTaskJSON);

  newTaskData[0].successor.push(newTaskJSON.taskID);
  newTaskData[newTaskData.length - 1].predecessor.push(newTaskJSON.taskID);

  return newTaskData;
};

export const editTask = (taskData, taskDetails) => {
  const taskID = taskDetails.taskID;

  const taskIindex = taskData.findIndex((x) => x.taskID === taskID);

  if (taskIindex === -1) return;

  const newTaskData = Array.from(taskData);

  newTaskData[taskIindex].name = taskDetails.name;
  newTaskData[taskIindex].description = taskDetails.description;
  newTaskData[taskIindex].duration = taskDetails.duration;

  return newTaskData;
};

export const reorder = (list, startIndex, endIndex, mutateList = true) => {
  let result;
  if (mutateList === true) result = Array.from(list);
  else result = list;

  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const addStartEndIDIfEmpty = (list, startID, endID) => {
  const startIndex = list.findIndex((x) => x.taskID === startID);
  const endIndex = list.findIndex((x) => x.taskID === endID);

  if (list[startIndex].predecessor.length === 0) {
    list[startIndex].predecessor = [startTaskID];

    if (list[0].successor.indexOf(startID) === -1) {
      list[0].successor.push(startID);
    }
  }

  if (list[endIndex].successor.length === 0) {
    list[endIndex].successor = [endTaskID];

    if (list[list.length - 1].predecessor.indexOf(endID) === -1) {
      list[list.length - 1].predecessor.push(endID);
    }
  }
};

export const deleteDependencyRelation = (taskData, sourceTaskID, depedencyType, relatedTaskID) => {
  //    const sourceIndex = taskData.findIndex((x) => x.taskID === sourceTaskID);
  //    const relatedIndex = taskData.findIndex((x) => x.taskID === relatedTaskID);

  const newTaskData = Array.from(taskData);

  //    console.log("deldep", sourceTaskID, depedencyType, relatedTaskID);

  if (depedencyType === "P") {
    deleteDependency(newTaskData, sourceTaskID, relatedTaskID, "P", false);
    deleteDependency(newTaskData, relatedTaskID, sourceTaskID, "S", false);

    //if (newTaskData[sourceIndex].predecessor.length === 0) newTaskData[sourceIndex].predecessor = [startTaskID];
    //if (newTaskData[relatedIndex].successor.length === 0) newTaskData[relatedIndex].successor = [endTaskID];

    addStartEndIDIfEmpty(newTaskData, sourceTaskID, relatedTaskID);
  } else {
    deleteDependency(newTaskData, sourceTaskID, relatedTaskID, "S", false);
    deleteDependency(newTaskData, relatedTaskID, sourceTaskID, "P", false);

    addStartEndIDIfEmpty(newTaskData, relatedTaskID, sourceTaskID);
  }

  return newTaskData;
};

const deleteDependency = (list, taskID, DependencyID, depedencyType, mutateList = true) => {
  let taskIndex = -1;

  if (taskID === startTaskID) taskIndex = 0;
  else if (taskID === endTaskID) taskIndex = list.length - 1;
  else {
    taskIndex = list.findIndex((x) => x.taskID === taskID);
  }

  if (taskIndex === -1) return;

  let result;

  if (mutateList === true) result = Array.from(list);
  else result = list;

  const array = depedencyType === "S" ? result[taskIndex].successor : result[taskIndex].predecessor;

  let i = array.indexOf(DependencyID);
  if (i !== -1) array.splice(i, 1);
};

export const deleteTask = (taskData, taskID) => {
  try {
    //      const taskID = deleteTaskRef.current.value;
    const taskIndex = taskData.findIndex((x) => x.taskID === taskID);

    if (taskIndex === -1) return;

    //const newTaskData = Array.from(taskData);

    //    const [removed] = newTaskData.splice(taskIndex, 1);

    const newTaskData = taskData.filter((task) => task.taskID !== taskID);

    deleteDependency(newTaskData, startTaskID, taskID, "S", false);
    deleteDependency(newTaskData, endTaskID, taskID, "P", false);

    //newTaskData.splice(taskIndex, 1);

    //if (removed) {

    return { newTaskData, deletedTask: { ...taskData[taskIndex] } };
  } catch (error) {
    const ss = 1;
  }
};
//////////////////////////////////////   DRAG END

function getMaxPredecessorIndexForSort(list, taskID, taskIndex = -1, idToSort) {
  if (taskIndex === -1) {
    taskIndex = list.findIndex((task) => task.taskID === taskID);
    if (taskIndex === -1) return -1;
  }

  if (taskID === startTaskID && list[taskIndex].successor.length === 0) return 0;

  if (list[taskIndex].successor.length === 1 && list[taskIndex].successor[0] === idToSort) return -1;

  let maxTaskID = Math.max.apply(
    Math,
    list[taskIndex].successor.map(function (id) {
      return id === endTaskID || id * 1 >= idToSort * 1 ? startTaskID : id;
    })
  );

  if (maxTaskID === 0) return -1;

  maxTaskID = maxTaskID.toString();

  const sortIndex = list.findIndex((task) => task.taskID === maxTaskID);

  return sortIndex;
}

export const onDragEnd = (taskData, result) => {
  let destinationIndex;

  if (result.combine) {
    //console.log(result.combine);

    if (!result.combine.draggableId) {
      return;
    }
    destinationIndex = taskData.findIndex((x) => x.taskID === result.combine.draggableId); //  result.combine.draggableId;
  } else {
    if (!result.destination) {
      return;
    }
    destinationIndex = result.destination.index;
  }
  //    console.log("destindex", destinationIndex);

  const sourceIndex = result.source.index;

  if (destinationIndex === sourceIndex) {
    return;
  }

  if (taskData[destinationIndex].taskID === startTaskID || taskData[destinationIndex].taskID === endTaskID) {
    return;
  }

  ///////////////// COMBINE
  if (result.combine) {
    //console.log("combined");

    if (taskData[destinationIndex].predecessor.indexOf(taskData[sourceIndex].taskID) !== -1) return;

    if (taskData[destinationIndex].successor.indexOf(taskData[sourceIndex].taskID) !== -1) return;

    let newTaskData = Array.from(taskData);

    if (newTaskData[destinationIndex].successor.length === 1 && newTaskData[destinationIndex].successor[0] === endTaskID) {
      newTaskData[destinationIndex].successor = [newTaskData[sourceIndex].taskID];
      deleteDependency(newTaskData, endTaskID, newTaskData[destinationIndex].taskID, "P", false);
    } else {
      newTaskData[destinationIndex].successor.push(newTaskData[sourceIndex].taskID);
    }

    if (newTaskData[sourceIndex].predecessor.length === 1 && newTaskData[sourceIndex].predecessor[0] === startTaskID) {
      newTaskData[sourceIndex].predecessor = [newTaskData[destinationIndex].taskID];
      deleteDependency(newTaskData, startTaskID, newTaskData[sourceIndex].taskID, "S", false);
    } else {
      newTaskData[sourceIndex].predecessor.push(newTaskData[destinationIndex].taskID);
    }

    let sortIndex = getMaxPredecessorIndexForSort(
      newTaskData,
      newTaskData[destinationIndex].taskID,
      destinationIndex,
      newTaskData[sourceIndex].taskID
    );
    if (sortIndex === -1) sortIndex = destinationIndex;

    if (sourceIndex !== sortIndex + 1) {
      if (sourceIndex > sortIndex) {
        newTaskData = reorder(newTaskData, sourceIndex, sortIndex + 1);
      } else {
        newTaskData = reorder(newTaskData, sourceIndex, sortIndex);
      }
    }

    return newTaskData;

    return;
  }
  //console.log("dragend",result.destination, taskData[result.destination.index]);
  const newTaskData = reorder(taskData, sourceIndex, destinationIndex);

  return newTaskData;
};

export const getDependencyData = (taskData, dependencyType, taskID, isExection = false) => {
  //console.log("getdepency",dependencyType, taskID)
  const newTaskData = Array.from(taskData);

  const currTask = newTaskData.find((task) => task.taskID === taskID);

  //console.log("data", dependencyType, currTask);

  const dependencyTasks = dependencyType === "S" ? Array.from(currTask.successor) : Array.from(currTask.predecessor);

  const dependencyData = [];

  dependencyTasks.forEach((dependencyTaskID, index) => {
    const task = newTaskData.find((x) => x.taskID === dependencyTaskID);

    if (task) {
      if (isExection) {
        dependencyData.push({ taskID: task.taskID, description: task.description, taskData: task });
      } else {
        dependencyData.push({ taskID: task.taskID, description: task.description });
      }
    }
  });

  const finalData = [...dependencyData];
  return finalData; // dependencyData;
};

/*   const buildDepArray = (list) => {
    let s = [];

    taskData.forEach((task) => {
      //const currTask = taskData.find((task) => task.taskID === y);

      const depS = getDependencyData("S", task.taskID);
      const depP = getDependencyData("P", task.taskID);

      s.push({ S: depS, P: depP });
    });

    return s;
  };
 */

export const getIntitialData = (isCheckList = false) => {
  let initailTaskData;

  let checkListProps = {};

  if (isCheckList) {
    checkListProps = { ...defaultCheckListProps };
  }

  console.log("checkListProps", isCheckList, checkListProps);

  if (process.env.NODE_ENV !== "development") {
    initailTaskData = [
      {
        taskID: startTaskID,
        duration: 0,
        name: "START        ",
        description: "Start",
        predecessor: [],
        successor: [],
        ...checkListProps,
      },
      {
        taskID: endTaskID,
        duration: 0,
        name: "END                    ",
        description: "End",
        predecessor: [],
        successor: [],
        ...checkListProps,
      },
    ];
  } else {
    initailTaskData = [
      {
        ...checkListProps,
        taskID: startTaskID,
        taskid: "START",
        duration: 0,
        name: "START        ",
        description: "Start",
        predecessor: [],
        successor: ["1", "2", "3", "4"],
        ...checkListProps,
      },
      {
        taskID: "1",
        duration: 60,
        name: "Install Router",
        description: "Router",
        predecessor: [startTaskID],
        successor: [endTaskID],
        ...checkListProps,
      },
      {
        taskID: "2",
        duration: 30,
        name: "Install Hardware",
        description: "Install HW",
        predecessor: [startTaskID],
        successor: [endTaskID],
        ...checkListProps,
      },
      {
        taskID: "3",
        duration: 45,
        name: "Install Windows",
        description: "Win 2000",
        predecessor: [startTaskID],
        successor: [endTaskID],
        ...checkListProps,
      },
      {
        taskID: "4",
        duration: 30,
        name: "Install Email Server",
        description: "Email Srv",
        predecessor: [startTaskID],
        successor: [endTaskID],
        ...checkListProps,
      },
      {
        taskID: endTaskID,
        duration: 0,
        name: "END                    ",
        description: "End",
        predecessor: ["1", "2", "3", "4"],
        successor: [],
        ...checkListProps,
      },
    ];
  }

  return initailTaskData;
};
