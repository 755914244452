import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import { makeStyles, useTheme, duration } from "@material-ui/core/styles";

import * as Yup from "yup";

import * as toastMessage from "../utils/toastMessages";
import AutoCompleteFormik from "../UI/autocomplete-formik";
import * as swal2 from "../utils/sweet2-toast-dialog";

//const styles = theme => ({
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "80%",
      width: "80%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "80%",
      width: "80%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    width: "100%",
    minWidth: "100%"
    //    margin: 'auto',
  },
}));

let validationFeilds = {};
let initialValues = {};

let roleData = null; // [{ _id: "", name: "" }];

let validationSchema;
let stateData = {};
let isNew = true;

function AddCheckListData(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [autoCompleteKey, setAutoCompleteKey] = useState(Math.random());
  const [initialValues1, setInitialValues1] = useState({});

  useEffect(() => {
    //console.log("use effect user");

    setAutoCompleteKey(Math.random());

    initialValues = {
      fldData: null,
    };

    stateData = props.fldDetails;

    validationFeilds = {
      fldData: Yup.string("Enter a Task Name").required(`${stateData.fldTitle} is required`).nullable(),
    };

    setInitialValues1(initialValues);

    //console.log(validationFeilds, initialValues);

    if (stateData.displayOnly) {
      validationSchema = Yup.object({});
    } else {
      validationSchema = Yup.object(validationFeilds);
    }

//    resetForm();
  }, [props.fldDetails]);

  const {
    handleSubmit,
    setFieldValue,
    errors,
    isSubmitting,
    isValid,
    touched,
    getFieldProps,
    setTouched,
    resetForm,
    dirty,
    values,
  } = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (data, actions) => {
      actions.setSubmitting(true);

//      toastMessage.DisplayToast(isNew ? "Task Added" : "Task Updated...", {
//        type: toastMessage.messageType.SUCCESS,
//      });

      props.handleSubmit(data.fldData);

      actions.setSubmitting(false);
    },
  });

  return (
    <React.Fragment>
      <div className={classes.container}>
        {/* <Paper elevation={1} className={classes.paper}> */}
        <form autoComplete="nope" onSubmit={handleSubmit} style={{width: "100%"}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AutoCompleteFormik
                updateValue={(value) => setFieldValue("fldData", value ? value : "")}
                getOptionLabel={(option) => option.name}
                fldName="fldData"
                labelName={stateData.fldTitle}
                value1={values.fldData}
                key={"fldData-" + autoCompleteKey}
                touched={touched}
                errors={errors}
                setTouched={setTouched}
                url={stateData.url}
              />
            </Grid>

            <div style={{ width: "100%", textAlign: "center" }}>
              {!isSubmitting ? (
                <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                  {!stateData.displayOnly ? (
                    <Button
                      style={{ marginRight: "15px" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      Submit
                    </Button>
                  ) : null}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.handleClose()}
                  >
                    {stateData.displayOnly ? "Exit" : "Cancel"}
                  </Button>
                </div>
              ) : (
                <div style={{ marginTop: "15px", marginBottom: "30px" }}>{/* <CircularProgress /> */}</div>
              )}
            </div>
          </Grid>
        </form>
      </div>
    </React.Fragment>
  );
}

export default AddCheckListData;
