import React, { Component, useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import * as Yup from "yup";

import * as axiosAPI from "../utils/getSetAPIData";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import AutoCompleteFormikStatic from "../UI/autocomplete-formik-static";
import AutoCompleteFormik from "../UI/autocomplete-formik";

//const styles = theme => ({
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "80%",
      width: "80%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "60%",
      width: "60%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
  switchClass: {
    root: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  labelPlacementStart: {
    justifyContent: "space-between",
  },
}));

let validationFeilds = {};
let initialValues = {};

const roleOptions = [
  { role: "viewer", name: "Dashboard Viewer" },
  { role: "user", name: "User" },
  { role: "super-user", name: "Super User" },
  { role: "admin", name: "Administrator" },
];

let roleData = null; // [{ _id: "", name: "" }];

let resetForm = false;
let validationSchema;
let stateData = {};
let formTitle = "Add Userr";
let isNew = true;
let customerData = null;

function AddAmmendUser(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [autoCompleteKey, setAutoCompleteKey] = useState(Math.random());
  const { userId } = useSelector((state) => state.auth, shallowEqual);  
  const [initialValues1, setInitialValues1] = useState({});

  useEffect(() => {
    //console.log("use effect user");

    setAutoCompleteKey(Math.random());

    validationFeilds = {
      name: Yup.string("Enter a Name").required("Name is required"),
      role: Yup.string("Enter Role").required("Role is requird"),
      active: Yup.boolean("Enable/Disable User").required("Enable/Disable User is requird"),
    };

    initialValues = {
      email: "",
      name: "",
      role: "",
      active: true,
      customer: null,
    };

    roleData = null;
    customerData = null;
    formTitle = "Add User";
    isNew = true;
    stateData = {};
    if (props.location.state && props.location.state.stateData) {
      stateData = props.location.state.stateData;
      isNew = false;
      formTitle = "Edit User";

      initialValues.email = stateData.email;
      initialValues.name = stateData.name;
      initialValues.role = stateData.role;
      initialValues.active = stateData.active;
      console.log("statedata", stateData);

      const sortIndex = roleOptions.findIndex((role) => role.role === stateData.role);
      if (sortIndex !== -1) roleData = roleOptions[sortIndex];

      if (stateData.role === "viewer" && stateData.customer) {
        initialValues.customer = stateData.customer._id;
        customerData = {
          _id: stateData.customer._id,
          name: stateData.customer.name,
          //branch: { _id: stateData.branch._id, name: stateData.branch.name },
        };
      }

      //      console.log(roleData, initialValues);
    }

    if (!isNew) {
      //isNew = false;
    } else {
      validationFeilds.email = Yup.string("Enter your email").email("Enter a valid email").required("Email is required");

      validationFeilds.password = Yup.string("")
        .min(8, "Password must contain atleast 8 characters")
        .required("Enter your password");

      validationFeilds.passwordConfirm = Yup.string("Enter your password")
        .required("Confirm your password")
        .oneOf([Yup.ref("password")], "Password does not match");

      initialValues.password = "";
      initialValues.passwordConfirm = "";
    }

    setInitialValues1(initialValues);
    setAutoCompleteKey(Math.random());

    //console.log(validationFeilds, initialValues);

    validationSchema = Yup.object(validationFeilds);

    resetForm();
  }, [props.location.state]);

  const {
    handleSubmit,
    setFieldValue,
    errors,
    isSubmitting,
    isValid,
    touched,
    getFieldProps,
    setTouched,
    resetForm,
    dirty,
    values,
  } = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (data, actions) => {

      console.log(stateData._id, userId);
      if(!isNew && (stateData._id === userId && !data.active)) {

        toastMessage.DisplayToast("Cannot disable this User", { type: toastMessage.messageType.ERROR });
        return;

      }

      actions.setSubmitting(true);

      const confirm = await swal2.confirmationDialog(
        "Are you sure to " + (isNew ? "add new " : "update") + " User",
        "Confirmation"
      );

      let dataUrl = "",
        respData = {};
      if (confirm.status === ":OK") {
        if (data.active) {
          dataUrl = "/api/v1/users/checkSubscriptionUsers" + (isNew ? "" : "?userID=" + stateData._id);

//          respData = await axiosAPI.getSetAxiosData("get", dataUrl, { userID: isNew ? null : stateData._id });
          respData = await axiosAPI.getSetAxiosData("get", dataUrl);

          if (respData.status === "success") {
            //console.log("active user data", respData.data.data.data);
            const { subscriptionUsers, activeUsers } = respData.data.data.data;

            if (activeUsers >= subscriptionUsers) {

              toastMessage.DisplayToast("Active users should not be greater than subscription users", { type: toastMessage.messageType.ERROR });
              actions.setSubmitting(false);

              return;
            }
          } else {
            toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
            actions.setSubmitting(false);
            return;
          }
        }

        dataUrl = "/api/v1/users" + (isNew ? "/signup" : "/" + stateData._id);

        let apiData = {};
        if (isNew) {
          apiData = data;
          //console.log(apiData);
        } else {
          apiData = { name: data.name, role: data.role, customer: data.customer, active: data.active };
        }

        respData = await axiosAPI.getSetAxiosData(isNew ? "post" : "patch", dataUrl, apiData);

        if (respData.status === "success") {
          toastMessage.DisplayToast(isNew ? "User Added" : "User Updated...", {
            type: toastMessage.messageType.SUCCESS,
          });

          if (isNew) {
            actions.resetForm({});
            roleData = null; //[{ _id: "", name: "" }];
            setAutoCompleteKey(Math.random());
            actions.setSubmitting(false);
          } else {
            props.history.replace("/user", { isNew: false });
          }
        } else {
          toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
          actions.setSubmitting(false);
        }
      } else {
        actions.setSubmitting(false);
      }
    },
  });

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Paper elevation={1} className={classes.paper}>
          <h2 style={{ color: theme.sidedrawer.subListTextColor }}>{formTitle}</h2>
          <form autoComplete="nope" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="email"
                  helperText={touched.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)}
                  label="Email"
                  disabled={isNew ? false : true}
                  fullWidth
                  {...getFieldProps("email")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="name"
                  autoComplete="nope"
                  helperText={touched.name ? errors.name : ""}
                  error={touched.name && Boolean(errors.name)}
                  label="Name"
                  fullWidth
                  {...getFieldProps("name")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              <Grid item xs={12}>
                <AutoCompleteFormikStatic
                  disabled={!isNew && stateData._id === userId}
                  options={roleOptions}
                  updateValue={(value) => {
                    if (!value || value.role !== "viewer") {
                      customerData = null;
                      setFieldValue("customer", null);
                    }
                    setFieldValue("role", value ? value.role : "");
                  }}
                  fldName="role"
                  labelName="Role"
                  value={roleData}
                  key={"role-" + autoCompleteKey}
                  touched={touched.role}
                  errors={errors.role}
                  setTouched={setTouched}
                />
              </Grid>

              <Grid item xs={12}>
                <AutoCompleteFormik
                  updateValue={(value) => {
                    customerData = value ? value : null;
                    //                    setFieldValue("branch", value ? value : "");
                    setFieldValue("customer", value ? value._id : null);
                  }}
                  getOptionLabel={(option) => option.name} //+ " - Customer: " + option.customer.name}
                  disabled={!values.role || values.role !== "viewer"}
                  fldName="customer"
                  labelName="Customer"
                  value1={customerData}
                  key={"customer-" + autoCompleteKey}
                  touched={touched}
                  errors={errors}
                  setTouched={setTouched}
                  url="/api/v1/customers?sort=name"
                />
              </Grid>

              {isNew ? (
                <React.Fragment>
                  <Grid item xs={12}>
                    <TextField
                      style={{ marginBottom: "10px" }}
                      id="password"
                      helperText={touched.password ? errors.password : ""}
                      error={touched.password && Boolean(errors.password)}
                      label="Password"
                      type="password"
                      autoComplete="new-password"
                      fullWidth
                      {...getFieldProps("password")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      style={{ marginBottom: "10px" }}
                      id="passwordConfirm"
                      helperText={touched.passwordConfirm ? errors.passwordConfirm : ""}
                      error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
                      label="Confirm Password"
                      type="password"
                      autoComplete="new-password"
                      fullWidth
                      {...getFieldProps("passwordConfirm")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </React.Fragment>
              ) : null}

              <Grid item xs={12} align="center">
                <FormControlLabel
                  control={
                    <Switch
                    disabled={!isNew && stateData._id === userId}
                    color="primary"
                      size="medium"
                      checked={values.active ? true : false}
                      onChange={(event) => {
                        setFieldValue("active", event.target.checked ? true : false);
                        //setLockDateFlag(event.target.checked ? 2 : 1);
                      }}
                    />
                  }
                  label="Active"
                  labelPlacement="top"
                />
              </Grid>

              <div style={{ width: "100%", textAlign: "center" }}>
                {!isSubmitting ? (
                  <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                    <Button
                      //                  style={{ marginTop: "15px", marginBottom: "15px" }}
                      type="submit"
                      //fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      Submit
                    </Button>
                    {isNew ? null : (
                      <Button
                        style={{ marginLeft: "15px" }}
                        //type="button"
                        //fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          props.history.replace("/user", { isNew: false });
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                ) : (
                  <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                    <CircularProgress />
                  </div>
                )}
              </div>
              {/* <div> {isSubmitting.toString()} </div> */}
            </Grid>
          </form>
        </Paper>
      </div>
    </React.Fragment>
  );
}

export default AddAmmendUser;
