import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';

function PaperComponent(props) {
    return (
      <Draggable handle="#form-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }
export default function FormDialog(props) {
  //  const [open, setOpen] = React.useState(false);

  //  const handleClickOpen = () => {
  //    setOpen(true);
  //  };

  const handleClose = () => {
    props.handleClose();
  };

  let contentStyle={ overflow: "hidden" }
  if (props.contentStyle) {
    contentStyle = props.contentStyle
  } else if (props.currWidth) {
    if (props.currWidth === "xs" || props.currWidth === "sm") {
      contentStyle={ }
    }
  }

  let titleStyle={ cursor: 'move' }
  if (props.titleStyle) {
    titleStyle = props.titleStyle;
    titleStyle.cursor = 'move';
  }

  let content
  return (
    <div>
      <Dialog 
      fullWidth
      open={props.open} 
      onClose={handleClose} 
      PaperComponent={PaperComponent}
      disableBackdropClick={props.disableBackdrop ? props.disableBackdrop : false}
      aria-labelledby="form-dialog-title">
        <DialogTitle style={titleStyle} id="form-dialog-title">{props.title}</DialogTitle> 
        <DialogContent fullWidth  style={contentStyle} >{props.children}</DialogContent>
      </Dialog>
    </div>
  );
}
