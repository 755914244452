import React, { useEffect, Suspense } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import jwt_decode from "jwt-decode";

import setAuthToken from "./shared/setAuthToken";

import Layout from "./components/layout/layout.component";
import DefaultPage from "./components/misc/default-page.component";
import cardDBDExample from "./components/misc/card-dnd-example";
import FormikInputForm from "./components/misc/formik-form-container";
import PathFinder from "./components/misc/path-finder";
import NewPathFinder from "./components/misc/new-path-finder";
import CPMTesting from "./components/testing/tm-cpm-testing";

import FormikNew1 from "./components/misc/formik-new1";

import CustomerList from "./components/customer/customerList";
import AddAmmendCustomer from "./components/customer/addAmmendCustomer";

import BranchList from "./components/branch/branchList";
import AddAmmendBranch from "./components/branch/addAmmendBranch";

import Login from "./components/auth/login";
import ChangeUserPassword from "./components/auth/changeUserPassword";
import UserList from "./components/user/userList";
import AddAmmendUser from "./components/user/addAmmendUser";

import CheckListTemplate from "./components/checkList/templateList";
import CheckListTemplateForm from "./components/checkList/checkList-template";

import CheckList from "./components/checkList/checkList-List";
import CheckListForm from "./components/checkList/addAmmendcheckList";

import JobExecutionList from "./components/jobExection/jobList";
import JobExecutionForm from "./components/jobExection/jobExecution";

import JobSummaryList from "./components/dashboard/jobSummaryList";
import JobDetailList from "./components/dashboard/jobDetailList";

import * as axiosAPI from "./components/utils/getSetAPIData";
import * as storeActions from "./store/actions/index";

//import sslLogo from "%PUBLIC_URL%/positivessl_trust_seal_md_167x42.png";


//alert("App Run")

const App = (props) => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth, shallowEqual);
  //const userEmail = authData.userId;
  const userName = authData.userName;
  const isLoggedin = authData.token != null ? true : false;
  const userRole = authData.userRole;
  const isExcessUsers = authData.otherInfo.activeUsers > authData.otherInfo.subscriptionUsers

  //  console.log("authData", authData);

  useEffect(() => {
    const getUser = async (token) => {
//      const dataUrl = "/api/v1/users/me";
      const dataUrl = "/api/v1/users/getLoginInfo";

      const response = await axiosAPI.getSetAxiosData("get", dataUrl);

      if (response.status === "success") {
        //console.log("app auto login:", response.data.data, token);
        dispatch(storeActions.authSuccess(token, response.data.data.data, response.data.data.saasTitle, response.data.data.otherInfo));
      }
    };

    if (localStorage.jwtToken) {
      // Set auth token header auth
      const token = localStorage.jwtToken;
      // Decode token and get user info and exp
      const decoded = jwt_decode(token);
      // Set user and isAuthenticated

      const currentTime = Date.now() / 1000; // to get in milliseconds
      if (decoded.exp < currentTime) {
        // Logout user
        //store.dispatch(logoutUser());

        // Redirect to login
        //window.location.href = "./login";
        dispatch(storeActions.logout());

        return;
      }

      setAuthToken(token);
      getUser(token);
      // Check for expired token
    }

    //    window.location.href = "./login";
    //props.history.replace("/login");
  }, []);

  useEffect(() => {
    if (!isLoggedin) {
      props.history.replace("/login");
    }
  }, [isLoggedin]);

  useEffect(() => {
    if (isLoggedin && userRole !== "admin" && isExcessUsers) {
      props.history.replace("/login");
    }else if (isLoggedin && isExcessUsers) {
      props.history.replace("/user");
    }
  }, [isLoggedin, isExcessUsers, userRole]);


  let routes = (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/changeUserPassword" exact component={ChangeUserPassword} />
      
      <Route path="/user" exact component={UserList} />
      <Route path="/userForm" exact component={AddAmmendUser} />

      <Route path="/customer" exact component={CustomerList} />
      <Route path="/customerForm" exact component={AddAmmendCustomer} />

      <Route path="/branch" exact component={BranchList} />
      <Route path="/branchForm" exact component={AddAmmendBranch} />

      <Route path="/checkListTemplate" exact component={CheckListTemplate} />
      <Route path="/checkListTemplateForm" exact component={CheckListTemplateForm} />

      <Route path="/checkList" exact component={CheckList} />
      <Route path="/checkListForm" exact component={CheckListForm} />

      <Route path="/jobExecutionList" exact component={JobExecutionList} />
      <Route path="/jobExecutionForm" exact component={JobExecutionForm} />

      <Route path="/jobSummary" exact component={JobSummaryList} />
      <Route path="/jobDetail" exact component={JobDetailList} />

      <Route path="/cpmTesting" exact component={CPMTesting} />
      <Route path="/test" exact component={cardDBDExample} />
      <Route path="/formik" exact component={FormikInputForm} />
      <Route path="/formikNew1" exact component={FormikNew1} />
      <Route path="/pathFinder" exact component={PathFinder} />
      <Route path="/newPathFinder" exact component={NewPathFinder} />

      {userRole === "user" ? (
        <Route path="/" exact component={JobExecutionList} />
      ) : (
        <Route path="/" exact component={JobSummaryList} />
      )}
      {/* <Route path="/" exact component={DefaultPage} /> */}
      <Redirect to="/" />
    </Switch>
  );

  return (
    <div>
      <Layout>
        <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
      </Layout>
    </div>
  );
};

export default withRouter(App);
