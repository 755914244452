import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import { AddIcon, AccountCircle, AccountCircleOutlined } from "@material-ui/icons";
import SideDrawer from "./sidedrawer.component";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import checkListLogo from "../../assets/images/checklist-logo.png";

import * as storeActions from "../../store/actions/index";
import { useWidth } from "shared/utility";

const drawerWidth = 260;

/* function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
} */

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: "100%",
    //    overflowX: 'hidden'  GOOD for disable Horizontal scrolling in Component
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      //width: `calc(100% - ${drawerWidth}px)`,
      //marginLeft: drawerWidth
      width: "100%",
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    //marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    //marginTop: theme.mixins.toolbar.minHeight,
    //overflow: "hidden"
    height: "100%",
  },
  content: {
    //display: "flex",
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
      //width: `calc(100% - ${drawerWidth}px)`,
      //marginLeft: drawerWidth
    },

    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginLeft: "auto",
  },
  close: {
    //margin: 10,
    opacity: 0.7,
  },
  drawerMainHeaderText: { color: theme.sidedrawer.mainHeaderTextColor },
  drawerSubListText: { color: theme.sidedrawer.subListTextColor },
  drawerSubListIcon: { color: theme.sidedrawer.subListIconColor },
  drawerOptionText: { color: theme.sidedrawer.optionTextColor },
  drawerOptionIcon: { color: theme.sidedrawer.optionIconColor },
  fabButton: {
    [theme.breakpoints.down("sm")]: {
      size: "small",
      //width: 10,
      //height:10,
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    position: "absolute",
    zIndex: 1,
    //top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  rightToolbar: {
    display: "flex",
    direction: "row",
    alignItems: "center",

    marginLeft: "auto",

    //marginRight: -12,
  },
  logo: {
    marginRight: 5,
    //textAlign: "center",
    //maxWidth: "50%",
    height: "70%",
    //border: "2px solid white"
    //width: "70%"
  },
  logoHorizontallyCenter: {
    display: "flex",
    alignItems: "center" /*vertical align*/,
    //display: "inline-block",
    height: theme.mixins.toolbar.minHeight,
    // position: "absolute",
    // left: "50%",
    // top: "50%",
    // transform: "translate(-50%, -50%)"
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
}));

const Layout = (props) => {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const authData = useSelector((state) => state.auth, shallowEqual);
  //const userEmail = authData.userId;
  const userName = authData.userName;
  const isLoggedin = authData.token != null ? true : false;

  const saasTitle =
    process.env.NODE_ENV === "development"
      ? authData.saasTitle + " " + authData.otherInfo.subscriptionType
      : authData.saasTitle;
  const isExcessUsers = authData.otherInfo.activeUsers > authData.otherInfo.subscriptionUsers;

  //const userCustomer = authData.userCustomer;

  //console.log("user Name:", userName, authData);
  //console.log("userCustomer", userCustomer)
  const userRole = authData.userRole;

  const width = useWidth();
  theme.globalVariables.currentSize = width;

  const isSmall = width === "xs" || width === "sm" ? true : false;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dispatch = useDispatch();

  const logout = () => {
    handleClose();
    //dispatch(actions.authSuccess(null, "aa@bb.com"));
    dispatch(storeActions.logout());
  };

  const changePassword = () => {
    handleClose();
    //dispatch(actions.authSuccess(null, "aa@bb.com"));
    props.history.push("/changeUserPassword");
  };

  //  console.log(theme.sidedrawer.text1);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar disableGutters style={{ marginLeft: "10px", marginRight: "10px" }}>
          <div className={classes.logoHorizontallyCenter}>
            <img src={checkListLogo} className={classes.logo} alt="logo" />
          </div>
          <Typography variant="h6" noWrap>
            {isLoggedin ? saasTitle : "Multi Site Rollout Tracker (MSRT)"}{" "}
            {process.env.NODE_ENV === "development" ? width : ""} {/*{width}  {userEmail}  {width} */}
          </Typography>

          {/*           <Fab
            size={isSmall ? "small" : "medium"}
            color="secondary"
            aria-label="add"
            className={classes.fabButton}
          >
            <AddIcon />
          </Fab> */}

          <section className={classes.rightToolbar}>
            {isLoggedin && (
              <React.Fragment>
                {/*                 <Hidden xsDown implementation="css">
                  <Typography fontSize={5} style={{ marginRight: "10px" }}>
                    Welcome {userName}
                  </Typography>
                </Hidden>
 */}
                <div>
                  <Tooltip title={"Welcome " + userName} aria-label="rightmenu">
                    <Fab
                      size={isSmall ? "small" : "medium"}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <AccountCircle fontSize="large" />
                    </Fab>
                  </Tooltip>
                  <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem disabled style={{ color: "blue", justifyContent: "center", fontWeight: "bold" }}>
                      {userName}
                    </MenuItem>
                    <MenuItem onClick={changePassword}>Change Password</MenuItem>
                    {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                    <MenuItem onClick={logout}>Logout</MenuItem>
                  </Menu>
                </div>
              </React.Fragment>
            )}

            {/*             <IconButton>
              <AccountCircleOutlined fontSize="large" />
            </IconButton>
 */}

            {isLoggedin && !isExcessUsers && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                //edge="start"
                edge="end"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            )}
          </section>
        </Toolbar>
      </AppBar>

      {isLoggedin && !isExcessUsers && (
        <nav className={classes.drawer} aria-label="Side Drawer">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <SideDrawer
            authInfo={authData.otherInfo} 
            userRole={userRole}
            container={container}
            theme={theme}
            mobileOpen={mobileOpen}
            DrawerToggle={handleDrawerToggle}
            classes={classes}
          />
        </nav>
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
};

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(typeof Element === "undefined" ? Object : Element),
};

export default withRouter(Layout);
