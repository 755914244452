import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MaterialTable, { MTableToolbar } from "material-table";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import useSWR, { mutate } from "swr";

import { KeyboardDatePicker } from "@material-ui/pickers";

import moment from "moment";

//import axios from "axios";

import AutoCompleteFormik from "../UI/autocomplete-formik";
import AutoCompleteFormikStatic from "../UI/autocomplete-formik-static";
import * as axiosAPI from "../utils/getSetAPIData";
import tableIcons, { addCustomButton } from "../utils/materialTableIcons";
import * as materialTableDefaults from "../utils/materialTableDefaults";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import { convertToDaysHoursMinutes, formatOnlyDateToISOString, formatOnlyDateToStartEndOfDay } from "shared/utility";
import mtQueryClass from "shared/mtQueryDataClass";
import { date } from "yup";

//const styles = theme => ({
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      width: "100",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
      maxWidth: "90%",
      width: "90%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
    padding: 0,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
}));

const jobStatusOptions = [
  { _id: "anyLate", name: "Any Late" },
  { _id: "late", name: "Pending & started Late" },
  { _id: "pendingLate", name: "Pending Late" },
  { _id: "startedLate", name: "Started Late" },
  { _id: "completedLate", name: "Completed Late" },
  { _id: "pending", name: "Pending" },
  { _id: "started", name: "Started" },
  { _id: "completed", name: "Completed" },
];

let tableColumns = [];
let stateData = {};

const title = "Job Wise Details";
//let tableClickActions = {};
const mtQuery = new mtQueryClass();
mtQuery.searchUrl = "&searchField=name&searchValue=";
mtQuery.url = "/api/v1/checkLists/jobSummary?sort=customerName";
const dateFormat = "MMM D, YYYY h:mm A";
const swrKey = "/api/v1/checkLists/jobDetail";

function JobDetailList(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [autoCompleteKey, setAutoCompleteKey] = useState(Math.random());
  const [isStartupLoading, setIsStartupLoading] = useState(true);
  const [serverError, setServerError] = useState({ isError: false, message: " ", type: "SERVER", statusCode: 0 });
  const [startSWRFetch, setStartSWRFetch] = useState(false);
  const [tablePage, setTablePage] = useState(0);
  const [jobQueryParams, setJobQueryParams] = useState({});

  const isSMUp = useMediaQuery(theme.breakpoints.up("sm"));
  swal2.setTheme(theme);
  const tableRef = React.useRef();

  const { otherInfo: {jobDetailsPollingIntervel} } = useSelector((state) => state.auth, shallowEqual);
  
  const getMTActions = () => {
    const tableClickActions = {
      onClickRefreshFab: (event) => {
        if (startSWRFetch === false) {
          setStartSWRFetch(true);
        } else {
          mutate(swrKey);
        }
      },
    };

    addCustomButton("execJob", "View");

    const mtActions = materialTableDefaults.mtActions(props, tableIcons, { tableClickActions }, "Jobs");

    mtActions.push({
      icon: tableIcons.execJob,
      //iconProps: { style: { fontSize: "14px", color: "blue" } },
      tooltip: "View Job",
      onClick: async (event, rowData) => {
        if (rowData.status === "pending") {
          toastMessage.DisplayToast("Job not started yet", { type: toastMessage.messageType.ERROR });
          return;
        }

        setStartSWRFetch(false);
        const detailState = { _id: rowData._id, userRole: stateData.userRole };
        props.history.push("/jobExecutionForm", {
          summaryState: props.location.state.summaryState,
          isNew: false,
          isViewer: true,
          stateData: detailState,
          jobQueryParams,
        });
      },
    });

    return mtActions;
  };

  if (isSMUp) {
    tableColumns = [
      { title: "CheckList Name", field: "name" }, // width: 500, minWidth: 500 },
      //{ title: "Customer Name", field: "customerName" },
      { title: "Site", field: "branchName" },
      {
        title: "Start Date/TIme",
        field: "startDateTime",
        render: (rowData) => moment(rowData.startDateTime).format(dateFormat),
      },
      { title: "Status", field: "status", width: 100, maxWidth: 100 },
      { title: "Tot. Tasks", field: "transData.totalTasks", width: 100, maxWidth: 100 },
      {
        title: "Completed Tasks",
        field: "transData.tasksLeft",
        width: 100,
        maxWidth: 100,
        render: (rowData) =>
          rowData.status === "pending" ? null : rowData.transData.totalTasks - rowData.transData.tasksLeft,
      },
      /*       {
        title: "Started/Completed At",
        field: "completedAt",
        render: (rowData) =>
          rowData.status === "completed"
            ? moment(rowData.completedAt).format(dateFormat)
            : rowData.status === "started"
            ? moment(rowData.startedAt).format(dateFormat)
            : null,
      },
 */ {
        title: "Remarks",
        field: "completedAt",
        width: 100,
        maxWidth: 100,
        render: (rowData) => {
          return getRemarksOrLateStatus(rowData);
        },
      },

      /*       {
      { title: "Status", field: "status", width: 100, maxWidth: 100 },
        title: "Start Date/TIme",
        field: "startDateTime",
        render: (rowData) => moment(rowData.startDateTime).format(dateFormat),
      }, */
    ];
  } else {
    tableColumns = [
      { title: "CheckList Name", field: "name" }, // width: 500, minWidth: 500 },
      { title: "Status", field: "status", width: 80, maxWidth: 80 },
      /*       {
        title: "Final Status",
        field: "finalStatus",
        hidden: true,
        render: (rowData) => {
           return getRemarksOrLateStatus(rowData, true);
        },
      },
 */ //{ title: "Soyadı", field: "surname" },
      //{ title: "Doğum Yılı", field: "birthYear", type: "numeric" },
      //{ title: "Email", field: "email" }
    ];
  }

  const getRemarksOrLateStatus = (rowData, isStatus = false) => {
    let mRemarks = "";
    const startTime = moment(rowData.startDateTime);
    const endTime = moment();

    if (isStatus) {
      //console.log(rowData);
      if (rowData.transData) {
        if (rowData.transData.isLateLFT > 0 || rowData.transData.isLateLST > 0) {
          return "L";
        }
      }
    }

    if (rowData.status === "completed") {
      if (rowData.isLateCompleted === 1) {
        mRemarks = "Late";
        return isStatus ? "L" : mRemarks;
      }
    } else if (rowData.status === "started") {
      const timeRemainingInMinutes = rowData.otherInfo.criticalPathDuration - endTime.diff(startTime, "minutes"); // - 60;

      if (timeRemainingInMinutes >= 0) {
        mRemarks = convertToDaysHoursMinutes(timeRemainingInMinutes);
        return isStatus ? "" : mRemarks;
      } else {
        mRemarks = "-" + convertToDaysHoursMinutes(Math.abs(timeRemainingInMinutes));
        return isStatus ? "L" : mRemarks;
      }
    } else if (rowData.status === "pending") {
      if (rowData.isLatePending === 1) {
        const timeRemainingInMinutes = endTime.diff(startTime, "minutes"); // - 60;
        mRemarks = convertToDaysHoursMinutes(timeRemainingInMinutes) + " Late";
        return isStatus ? "L" : mRemarks;
      }
    }

    return mRemarks;
  };

  const detailPanel = (rowData) => {
    let fieldArray = [];

    if (isSMUp) {
      fieldArray = [
        { heading: "Customer :", value: rowData.customerName },
        { heading: "Start Date/Time :", value: moment(rowData.startDateTime).format(dateFormat) },
        {
          heading: "Started At :",
          value: rowData.status !== "pending" ? moment(rowData.startedAt).format(dateFormat) : null,
        },
        { heading: "End Date/Time :", value: moment(rowData.endDateTime).format(dateFormat) },
        {
          heading: "Completed At :",
          value: rowData.status === "completed" ? moment(rowData.completedAt).format(dateFormat) : null,
        },
      ];
    } else {
      fieldArray = [
        { heading: "Customer :", value: rowData.customerName },
        { heading: "Site :", value: rowData.branchName },
        { heading: "Start Date/Time :", value: moment(rowData.startDateTime).format(dateFormat) },
        {
          heading: "Started At :",
          value: rowData.status !== "pending" ? moment(rowData.startedAt).format(dateFormat) : null,
        },
        { heading: "End Date/Time :", value: moment(rowData.endDateTime).format(dateFormat) },
        {
          heading: "Completed At :",
          value: rowData.status === "completed" ? moment(rowData.completedAt).format(dateFormat) : null,
        },
        { heading: "Total Tasks :", value: rowData.transData.totalTasks },
        {
          heading: "Completed Tasks :",
          value: rowData.status === "pending" ? null : rowData.transData.totalTasks - rowData.transData.tasksLeft,
        },
        { heading: "Remarks :", value: getRemarksOrLateStatus(rowData) },
      ];
    }

    return (
      <div style={{ marginTop: "15px", marginBottom: "15px" }}>
        {fieldArray.map((tableField) => {
          return (
            <Grid container spacing={1} alignItems="center" flex={1}>
              <Grid item xs={5} sm={3} style={{ paddingLeft: "10px" }}>
                <Typography variant="subtitle2" component="span" color="primary">
                  {tableField.heading}
                </Typography>
              </Grid>
              <Grid item xs={7} sm={9}>
                <Typography variant="body2" component="span">
                  {/* {tableField[1] === "country" ? rowData.country.name : rowData[tableField[1]]} */}
                  {tableField.value}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      stateData = {
        jobStartDate: new Date(),
        jobEndDate: new Date(),
        customerData: null,
        statusData: null,
      };

      if (props.location.state) {
        if (props.location.state.detailState) {
          stateData = props.location.state.detailState.stateData;
        } else {
          stateData = props.location.state.summaryState.stateData;
        }
        setJobQueryParams({ ...stateData });
      }
      //console.log(stateData);

      setIsStartupLoading(false);

      setAutoCompleteKey(Math.random());
    })();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [isSMUp]);

  useEffect(() => {
    setStartSWRFetch(false);

    mtQuery.url = "/api/v1/checkLists/jobDetail?startDate=";
    mtQuery.url = mtQuery.url + formatOnlyDateToStartEndOfDay(jobQueryParams.jobStartDate, "start") + "&endDate=";
    mtQuery.url = mtQuery.url + formatOnlyDateToStartEndOfDay(jobQueryParams.jobEndDate, "end");

    //    mtQuery.url = mtQuery.url + formatOnlyDateToISOString(jobQueryParams.jobStartDate) + "&endDate=";
    //    mtQuery.url = mtQuery.url + formatOnlyDateToISOString(jobQueryParams.jobEndDate);

    if (jobQueryParams.customerData) {
      if (jobQueryParams.customerData._id) {
        mtQuery.url = mtQuery.url + "&customerID=" + jobQueryParams.customerData._id;
      }
    }

    if (jobQueryParams.statusData) {
      if (jobQueryParams.statusData._id) {
        mtQuery.url = mtQuery.url + "&lateParam=" + jobQueryParams.statusData._id;
      }
    }

    //    console.log(jobQueryParams);

    if (tableRef.current) {
      tableRef.current.onChangePage(0);
    }

    setStartSWRFetch(true);
  }, [jobQueryParams]);

  const fetchExecutionData = () => {
    if (startSWRFetch && tableRef.current) {
      tableRef.current.onQueryChange();
    }

    return [];
  };

  useSWR(startSWRFetch ? swrKey : null, fetchExecutionData, {
    onFailure: (data, key, config) => {},
    onSuccess: (data, key, config) => {
      //      if (data.length > 0) {
      //        if (isLoading) setIsLoading(false);
      //        setTaskData(data);
      //        if (stateData.completedAt) {
      //          setStartSWRFetch(false);
      //        }
      //      }
    },
    refreshInterval: 1000 * jobDetailsPollingIntervel,
  });

  //  useEffect(() => {
  //console.log("tableref", tableRef);
  //  }, [tableRef]);

  //  console.log("PAGE RENDERED");

  const queryParamChange = () => {};

  const GetQueryData = async (query) => {
    const returnData = await mtQuery.getMaterialTableQueryData(query, setServerError);

    if (startSWRFetch === false) {
      setStartSWRFetch(true);
    }

    //console.log(returnData.data[0].transData);
    return returnData;
  };

  //console.log("currdata", currentData) ;
  //  alert("render");
  return (
    <React.Fragment>
      {/* <ToastMessages alertOpen={alertOpen} setAlertOpen={setAlertOpen} /> */}
      {/* <ToastContainer /> */}
      <div className={classes.container}>
        {/* <Paper elevation={1} className={classes.paper}> */}
        {/* <h2>User Form</h2> */}
        <div className={classes.paper}>
          {serverError.isError ? (
            <div style={{ width: "100%" }}>
              <h1 style={{ textAlign: "center" }}>{serverError.statusCode === "403" ? "UNAUTHORIZED" : "SERVER ERROR"}</h1>
              <h2 style={{ textAlign: "center" }}>{serverError.message}</h2>
            </div>
          ) : (
            <React.Fragment>
              <Grid container spacing={2} style={{ marginBottom: "15px" }}>
                <Grid item xs={6} md={3}>
                  <KeyboardDatePicker
                    fullWidth
                    value={jobQueryParams.jobStartDate}
                    id="startDate"
                    name="startDate"
                    label={"From Date"} // ( Time Zone: " + (!values.branch || !values.branch.timeZone ? "N/A" : values.branch.timeZone) + " )"  }
                    format="MM/dd/yyyy"
                    onChange={(value) => {
                      setJobQueryParams({ ...jobQueryParams, jobStartDate: value ? value : null });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <KeyboardDatePicker
                    fullWidth
                    value={jobQueryParams.jobEndDate}
                    id="endDate"
                    name="endDate"
                    label="To Date"
                    format="MM/dd/yyyy"
                    onChange={(value) => {
                      setJobQueryParams({ ...jobQueryParams, jobEndDate: value ? value : null });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AutoCompleteFormik
                    updateValue={(value) => {
                      setJobQueryParams({ ...jobQueryParams, customerData: value ? value : null });
                    }}
                    getOptionLabel={(option) => option.name} //+ " - Customer: " + option.customer.name}
                    disabled={!stateData.userRole || stateData.userRole === "viewer"}
                    fldName="customer"
                    labelName="Customer"
                    value1={stateData.customerData ? stateData.customerData : null}
                    key={"customer" + autoCompleteKey}
                    touched={{}}
                    errors={{}}
                    setTouched={(x) => {}}
                    url="/api/v1/customers?sort=name"
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <AutoCompleteFormikStatic
                    options={jobStatusOptions}
                    updateValue={(value) => {
                      setJobQueryParams({ ...jobQueryParams, statusData: value ? value : null });
                    }}
                    fldName="jobStatus"
                    labelName="job Status"
                    value={stateData.statusData}
                    key={"status-" + autoCompleteKey}
                    touched={null}
                    errors={null}
                    setTouched={(x) => {}}
                  />
                </Grid>
              </Grid>

              {isStartupLoading ? (
                <div style={{ marginTop: "15px", marginBottomxx: "30px" }}>
                  <CircularProgress />
                </div>
              ) : (
                <MaterialTable
                  //              isLoading={true}
                  tableRef={tableRef}
                  components={{
                    Toolbar: (props) => (
                      <React.Fragment>
                        {!isSMUp ? (
                          <div style={{ width: "100%", marginTop: "15px" }}>
                            <Typography variant="h6" color="primary" align="center" gutterBottom>
                              {" "}
                              {title}{" "}
                            </Typography>
                          </div>
                        ) : null}

                        <div style={{ color: theme.sidedrawer.subListTextColor }}>
                          <MTableToolbar {...props} />
                        </div>
                      </React.Fragment>
                    ),
                  }}
                  style={{ width: "100%", paddingLeft: "0px" }}
                  columns={tableColumns}
                  //data={[{ name: "Mehmet", surname: "Baran", birthYear: 1987, birthCity: 63 }]}
                  data={GetQueryData}
                  title={isSMUp ? title : ""}
                  icons={tableIcons}
                  detailPanel={detailPanel}
                  onRowClick={(event, rowData, togglePanel) => togglePanel()}
                  actions={getMTActions()}
                  options={{
                    //pageSizeOptions:[2,5,10,50],
                    //initialPage: tablePage,
                    sorting: false,
                    search: false,
                    loadingType: "linear",
                    //detailPanelType: "single",
                    actionsColumnIndex: -1,
                    actionsCellStyle: {
                      //backgroundColor: "#ffccdd",
                      color: theme.sidedrawer.subListTextColor,
                      textAlign: "center",
                      minWidth: "90px", // "rgba(255, 175, 0, 0.6)"
                    },
                    headerStyle: {
                      backgroundColor: "#01579b", //'#e8eaf5',
                      color: "#FFF",
                    },
                    rowStyle: (rowData) => ({
                      color: getRemarksOrLateStatus(rowData, true) === "L" ? "red" : "black",
                    }),
                  }}
                  localization={{
                    pagination: {
                      labelDisplayedRows: "{from}-{to} of {count}",
                    },
                  }}
                />
              )}

              <Grid container xs={12} spacing={2} style={{ marginTop: "15px" }}>
                <Grid item xs={12} align={isSMUp ? "right" : "center"}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      props.history.replace("/jobSummary", {
                        isNew: false,
                        stateData: {
                          ...props.location.state.summaryState.stateData,
                          customerData: props.location.state.summaryState.customerData,
                        },
                      });
                    }}
                    style={{ textTransform: "none" }}
                  >
                    Exit
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )}

          {/* </Hidden> */}
        </div>
        {/* </Paper> */}
      </div>
    </React.Fragment>
  );
}

export default JobDetailList;
