import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MaterialTable, { MTableToolbar } from "material-table";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//import axios from "axios";

import * as axiosAPI from "../utils/getSetAPIData";
import tableIcons, { addCustomButton } from "../utils/materialTableIcons";
import * as materialTableDefaults from "../utils/materialTableDefaults";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import { timeZoneList } from "shared/timeZonesUtils"

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "95%",
      width: "95%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
      maxWidth: "80%",
      width: "80%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
    padding: 0,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
}));

let currentSearch = "";
let currentPageNo = 0;
let currentData = [];
let currentTotalCount = 0;
let respData = {};
let customerData = {};


//class FormikInputForm extends Component {
function BranchList(props) {
  const classes = useStyles();
  const theme = useTheme();
  //const [alertOpen, setAlertOpen] = useState({ open: false, message: " ", severity: "error" });
  const [serverError, setServerError] = useState({ isError: false, message: " ", type: "SERVER", statusCode: 0 });
  //const [useInitialQuery, setUseInitialQuery] = useState(true);

  const isSMUp = useMediaQuery(theme.breakpoints.up("sm"));
  let tableRef = React.useRef();

  useEffect(() => {
    if (tableRef.current) {
      //console.log("tableref", tableRef);
      tableRef.current.onQueryChange();
    }
  }, [isSMUp]);

  if (props.location.state) {
    customerData = props.location.state.customerData;
  }

//  console.log(customerData);
console.log("time zones", timeZoneList);

  //  useEffect(() => {
  //console.log("tableref", tableRef);
  //  }, [tableRef]);

  //  console.log("PAGE RENDERED");

  swal2.setTheme(theme);

  let tableColumns = {};
  if (isSMUp) {
    tableColumns = [
      { title: "Name", field: "name" }, // width: 500, minWidth: 500 },
      { title: "Email", field: "email" },
      { title: "City", field: "city" },
      { title: "Country", field: "country.name" },
      //{ title: "Doğum Yılı", field: "birthYear", type: "numeric" },
      /*       {
        title: "Doğum Yeri",
        field: "birthCity",
        lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      },
 */
    ];
  } else {
    tableColumns = [
      { title: "Name", field: "name" },
      //{ title: "Soyadı", field: "surname" },
      //{ title: "Doğum Yılı", field: "birthYear", type: "numeric" },
      //{ title: "Email", field: "email" }
    ];
  }

  const detailPanel = (rowData) => {
    const fieldArray = [
//      ["Customer:", "customer"],
      ["Contact Person:", "contactPerson"],
      ["Email:", "email"],
      ["Address:", "address"],
      ["TelePhone:", "telephone"],
      ["City:", "city"],
      ["State:", "state"],
      ["Country:", "country"],
      ["Postal Code:", "postalCode"],
    ];

    return (
      <div style={{ marginTop: "15px", marginBottom: "15px" }}>
        {fieldArray.map((tableField) => {
          return (
            <Grid container spacing={1} alignItems="center" flex={1}>
              <Grid item xs={4} style={{ paddingLeft: "10px" }}>
                <Typography variant="subtitle2" component="span" color="primary">
                  {tableField[0]}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" component="span">
                  {tableField[1] === "country"
                    ? rowData.country.name
                    : tableField[1] === "customer"
                    ? rowData.customer.name
                    : rowData[tableField[1]]}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  };

  const title = "Sites";

  const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const getQueryData = (query) => {
    respData = {};

    return new Promise(async (resolve, reject) => {
      //console.log(query, tableRef.current);
      let searchQueryString = "";
      /*       if (useInitialQuery) {
        query.page = 1;
      }
 */
      if (currentSearch !== query.search) {
        //alert("Search Changed");
        const lastCurrentSearch = currentSearch;
        currentSearch = query.search;
        if (query.search.length < 3) {
          if (lastCurrentSearch.length >= 3) {
            searchQueryString = "";
          } else {
            resolve({
              data: currentData,
              page: currentPageNo,
              totalCount: currentTotalCount,
            });
            return;
          }
        }
      }

      if (currentSearch.length >= 3) {
        searchQueryString = "&searchField=name&searchValue=" + query.search;
      }

      const dataUrl =
        "/api/v1/branches?customer=" + customerData._id + "&sort=name&page=" + (query.page * 1 + 1) + "&limit=" + query.pageSize + searchQueryString;

      respData = await axiosAPI.getSetAxiosData("get", dataUrl);

      //      console.log("axiosData", respData, dataUrl);

      if (respData.status === "success") {
        currentData = respData.data.data.data;
        currentPageNo = currentData.length > 0 ? respData.data.page - 1 : 0;
        currentTotalCount = currentData.length > 0 ? respData.data.totalCount : 0;

        return resolve({
          data: currentData,
          page: currentPageNo,
          totalCount: currentTotalCount, //testdata.length,
        });
      } else {
        resolve({
          data: [],
          page: 0,
          totalCount: 0,
        });
        setServerError({
          isError: true,
          message: respData.message,
          statusCode: respData.statusCode ? respData.statusCode : 0,
        });
        //setAlertOpen({ open: true, message: respData.message, severity: "error" });
        //console.log("server error", respData);
      }

      /*       axios
        .get(dataUrl)
        .then((response) => {
          //console.log("Template Load", response.data.data.data);
          //console.log(response.data.data.totalCount);

          currentPageNo = query.page;
          currentData = response.data.data.data;
          currentTotalCount = response.data.totalCount;
          resolve({
            data: response.data.data.data,
            page: currentPageNo,
            totalCount: response.data.totalCount, //testdata.length,
          });
        })
        .catch((error) => {
          console.log("Template Load Error", error.response.data, error.response.status, error.response.data.message);
          //templateNameRef.current.error = true;
          //templateNameRef.current.helperText = "Not FOOOOND";
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
        });
 */
    });
  };

  const tableClickActions = {
    onClickAdd: (event) => {
      props.history.push("/branchForm", { isNew: true, customerData, timeZoneList });
    },
    onClickEdit: (event, rowData) => {
      const { tableData, ...stateData } = rowData;
      props.history.replace("/branchForm", { isNew: false, stateData, customerData, timeZoneList });
    },

    onClickDelete: async (event, rowData) => {
      //alert("You want to delete " + rowData.name);
      //setAlertOpen({ open: true, message: "Record Deleted", severity: "success" });
      //toastMessage.DisplayToast("Record Deleted", { type: toastMessage.messageType.SUCCESS });

      //const ss = await swal2.confirmationDialog("AMir Zulfiqar Deleted", "Confirm Deletion",  { type: 'danger' });

      //console.log("sweet result", ss);

      const lastQuery = tableRef.current.state.query;
      //console.log("last query bef delete",lastQuery);

      const confirm = await swal2.confirmationDialog("Are you sure to Delete Site", "Confirm Deletion", {
        type: "danger",
      });

      if (confirm.status === ":OK") {
        const dataUrl = "/api/v1/branches/" + rowData._id;

        const respData = await axiosAPI.getSetAxiosData("delete", dataUrl);

        if (respData.status === "success") {
          toastMessage.DisplayToast("Site Deleted...", { type: toastMessage.messageType.SUCCESS });

          setTimeout(function () {
            tableRef.current.onQueryChange(lastQuery);
          }, 300);
        } else {
          toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
        }
      }
    },
  };

  const mtActions = materialTableDefaults.mtActions(props, tableIcons, { tableClickActions }, "Site");

  //console.log("currdata", currentData) ;
  //  alert("render");
  return (
    <React.Fragment>
      {/* <ToastMessages alertOpen={alertOpen} setAlertOpen={setAlertOpen} /> */}
      {/* <ToastContainer /> */}
      <div className={classes.container}>
        {/* <Paper elevation={1} className={classes.paper}> */}
        {/* <h2>User Form</h2> */}
        <div className={classes.paper}>
          {serverError.isError ? (
            <div style={{ width: "100%" }}>
              <h1 style={{ textAlign: "center" }}>{serverError.statusCode === "403" ? "UNAUTHORIZED" : "SERVER ERROR"}</h1>
              <h2 style={{ textAlign: "center" }}>{serverError.message}</h2>
            </div>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5" display="inline" style={{ color: theme.sidedrawer.subListTextColor }}>Customer: </Typography>
                <Typography variant="h6" display="inline">{customerData.name}</Typography>
                {/* <h2 style={{ color: theme.sidedrawer.subListTextColor }}>Customer:</h2> */}
              </Grid>
              <MaterialTable
                tableRef={tableRef}
                components={{
                  Toolbar: (props) => (
                    <React.Fragment>
                      {!isSMUp ? (
                        <div style={{ width: "100%", marginTop: "15px" }}>
                          <Typography variant="h6" color="primary" align="center" gutterBottom>
                            {" "}
                            {title}{" "}
                          </Typography>
                        </div>
                      ) : null}

                      <div style={{ color: theme.sidedrawer.subListTextColor }}>
                        <MTableToolbar {...props} />
                      </div>
                    </React.Fragment>
                  ),
                }}
                style={{ width: "100%" }}
                columns={tableColumns}
                //data={[{ name: "Mehmet", surname: "Baran", birthYear: 1987, birthCity: 63 }]}
                data={getQueryData}
                title={isSMUp ? title : ""}
                icons={tableIcons}
                {...(!isSMUp && { detailPanel: detailPanel })}
                {...(!isSMUp && { onRowClick: (event, rowData, togglePanel) => togglePanel() })}
                actions={mtActions}
                options={{
                  sorting: false,
                  detailPanelType: "single",
                  actionsColumnIndex: -1,
                  actionsCellStyle: {
                    //backgroundColor: "#ffccdd",
                    color: theme.sidedrawer.subListTextColor, // "rgba(255, 175, 0, 0.6)"
                  },
                  headerStyle: {
                    backgroundColor: "#01579b", //'#e8eaf5',
                    color: "#FFF",
                  },
                }}
                localization={{
                  pagination: {
                    labelDisplayedRows: "{from}-{to} of {count}",
                  },
                }}
              />
            </Grid>
          )}

          {/* </Hidden> */}
        </div>
        {/* </Paper> */}
      </div>
    </React.Fragment>
  );
}

export default BranchList;
