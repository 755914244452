import React, { Component, useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import FormDialog from "components/UI/dialog-form";

import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";

import * as Yup from "yup";

import * as storeActions from "../../store/actions/index";
import * as axiosAPI from "../utils/getSetAPIData";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";

import { useWidth } from "shared/utility";
import TermsOfUse from "./termsOfUse";

//const styles = theme => ({
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "60%",
      width: "60%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "40%",
      width: "40%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
}));

const validationSchema = Yup.object({
  email: Yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
  password: Yup.string("").min(8, "Password must contain atleast 8 characters").required("Enter your password"),
  saasID: Yup.string("").required("Enter your Company ID"),
});

let resetForm = false;
let criticalErrorTitle = "";
let criticalError = "";

//class FormikInputForm extends Component {
function AddAmmendCustomer(props) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [criticalError, setCriticalError] = useState({ isError: false, error: "", title: "", message: "" });
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const authData = useSelector((state) => state.auth, shallowEqual);
  const userEmail = authData.userId;
  const userName = authData.UserName;
  const isLoggedin = authData.token != null ? true : false;
  const isLoginInProgress = authData.loading;
  const authError = authData.error;

  const currWidth = useWidth();

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  useEffect(() => {
    /*     const script = document.createElement("script");

    script.innerHTML =
      "TrustLogo('https://multisiterollouttracker.com/positivessl_trust_seal_md_167x42.png', 'CL1', 'none');";
    script.async = true;

    document.body.appendChild(script);
 */
    const sslImageDiv = document.getElementById("sslImageDiv");
    if (sslImageDiv) {
      sslImageDiv.style["display"] = "block"; //.style["background-color"] = "green";
    }

    return () => {
      sslImageDiv.style["display"] = "none";
    };
  }, []);

  useEffect(() => {
    //console.log("login progress:", isLoginInProgress);
    if (!isLoginInProgress && authError) {
      if (authError === ":SUBS-EXPIRED") {
        setCriticalError({
          isError: true,
          error: authError,
          title: "SUBSCRIPTION EXPIRED",
          message: "Please contact your System Administrator",
        });
      } else if (authError === ":EXCESS-USERS") {
        setCriticalError({
          isError: true,
          error: authError,
          title: "LICENSE COUNT EXCEEDED",
          message: "Please contact your System Administrator",
        });
      } else {
        toastMessage.DisplayToast(authError, { type: toastMessage.messageType.ERROR });
      }
    }
  }, [isLoginInProgress, authError]);

  useEffect(() => {
    if (isLoggedin) {
      props.history.replace("/");
    }
  }, [isLoggedin]);

  swal2.setTheme(theme);

  let formTitle = "User Login";

  const initialValues = {
    email: "",
    password: "",
  };

  const { handleSubmit, setFieldValue, errors, isSubmitting, isValid, touched, getFieldProps, setTouched } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: async (data, actions) => {
      //actions.setSubmitting(true);

      //const confirm = await swal2.confirmationDialog("Are you sure to Update Record", "Confirmation");

      dispatch(storeActions.auth(data.email, data.password, data.saasID.trim().toUpperCase(), false));

      return;

      if (1 === 2) {
        //confirm.status === ":OK"
        const dataUrl = "/api/v1/customers";

        const respData = await axiosAPI.getSetAxiosData("post", dataUrl, data);

        if (respData.status === "success") {
          toastMessage.DisplayToast("Customer Updated...", {
            type: toastMessage.messageType.SUCCESS,
          });

          // if (isNew) {
          //   actions.resetForm({});
          //   countryData = null; //[{ _id: "", name: "" }];
          //   setAutoCompleteKey(Math.random());
          //   actions.setSubmitting(false);
          // } else {
          //   props.history.replace("/customer", { isNew: false });
          // }
        } else {
          toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
          actions.setSubmitting(false);
        }
      } else {
        actions.setSubmitting(false);
      }
    },
  });

  //const fakeInputStyle = { opacity: 0, float: "left", border: "none", height: "0", width: "0" };

  return (
    <React.Fragment>
      <div autoComplete="off" className={classes.container}>
        <Paper elevation={1} className={classes.paper}>
          <h2 style={{ color: theme.sidedrawer.subListTextColor }}>{formTitle}</h2>
          {/* {props.location.state ? props.location.state.Name : null} */}
          {/* <Form {...props} formik={formik} isNew={isNew} isSub={formik.isSubmitting} /> */}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <input
                  type="password"
                  name="fake-password"
                  autoComplete="new-password"
                  tabIndex="-1"
                  style={{ opacity: 0, float: "left", border: "none", height: "0", width: "0" }}
                />
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="email"
                  helperText={touched.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)}
                  //                  autoComplete="tm-email-address"
                  //                  autoComplete="tm_passwd_for_email"
                  label="Email"
                  fullWidth
                  {...getFieldProps("email")}
                  InputProps={{
                    autoComplete: "tm-email-address",
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  style={{ marginBottom: "10px" }}
                  id="password"
                  helperText={touched.password ? errors.password : ""}
                  error={touched.password && Boolean(errors.password)}
                  label="Password"
                  type="password"
                  autoComplete="new-password"
                  fullWidth
                  {...getFieldProps("password")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  style={{ marginBottom: "10px" }}
                  id="saasID"
                  helperText={touched.saasID ? errors.saasID : ""}
                  error={touched.saasID && Boolean(errors.saasID)}
                  label="Company ID"
                  type="saasID"
                  autoComplete="new-password"
                  fullWidth
                  {...getFieldProps("saasID")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {criticalError.isError ? (
                <Grid item xs={12}>
                  <div style={{ width: "100%", textAlign: "center", marginBottom: "30px" }}>
                    <Typography variant="h4">
                      <span style={{ color: "red" }}>{criticalError.title}</span>
                    </Typography>
                  </div>
                </Grid>
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  {!isLoginInProgress ? (
                    <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                      <Button
                        //                  style={{ marginTop: "15px", marginBottom: "15px" }}
                        type="submit"
                        //fullWidth
                        variant="contained"
                        color="primary"
                        disabled={!isValid || isSubmitting}
                      >
                        Login
                      </Button>
                    </div>
                  ) : (
                    <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                      <CircularProgress />
                    </div>
                  )}
{/*                   <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                    <Button
                      //                  style={{ marginTop: "15px", marginBottom: "15px" }}
                      onClick={() => setInfoDialogOpen(true)}
                      //fullWidth
                      variant="contained"
                      color="primary"
                    >
                      Terms
                    </Button>
                  </div>
 */}                </div>
              )}
            </Grid>
          </form>
        </Paper>
      </div>
      <div className={classes.container}>
        <Link
          component="button"
          variant="body2"
          style={{fontSize: "10px", marginTop: "10px"}}
          onClick={() => {
            setInfoDialogOpen(true);
          }}
        >
          Terms of Use
        </Link>
        {criticalError.isError && criticalError.message ? (
          <div style={{ width: "100%", textAlign: "center", marginTop: "25px" }}>
            <Typography variant="subtitle2">
              <span style={{ color: "red" }}>{criticalError.message}</span>
            </Typography>
          </div>
        ) : null}
        <FormDialog
          open={infoDialogOpen}
          currWidth={currWidth}
          title="TERMS OF USE"
          titleStyle={{ color: "blue", minWidth: "600px", textAlign: "center" }}
          contentStyle={{ minWidth: "600px", overflowX: "hidden" }}
          disableBackdrop={false}
          handleClose={handleInfoDialogClose}
        >
          <TermsOfUse handleClose={handleInfoDialogClose}></TermsOfUse>
          {/* {criticalPathJSXDisplay} */}
        </FormDialog>
      </div>
      {/*       <div className={classes.container}>
        <div class="sslImage">
          {process.env.NODE_ENV === "development" ? (
            <img src={process.env.PUBLIC_URL + "/positivessl_trust_seal_md_167x42.png"} />
          ) : (
            <React.Fragment>
              <script language="JavaScript" type="text/javascript">
                TrustLogo("https://multisiterollouttracker.com/positivessl_trust_seal_md_167x42.png", "CL1", "none");
              </script>

              <a href="https://www.instantssl.com/" id="comodoTL">
                Essential SSL
              </a>
            </React.Fragment>
          )}
        </div>
      </div>
 */}
    </React.Fragment>
  );
}

export default AddAmmendCustomer;
