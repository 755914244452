export const mtActions = (formProps, tableIcons, actionsProps, tooltipTitle) => {
  const actions = [];

  if (actionsProps.tableClickActions.onClickAdd) {
    actions.push({
      icon: tableIcons.NewAdd,
      tooltip: "Add " + tooltipTitle,
      isFreeAction: true,
      onClick: actionsProps.tableClickActions.onClickAdd,
    });
  }

  if (actionsProps.tableClickActions.onClickRefreshFab) {
    actions.push({
      icon: tableIcons.RefreshFab,
      tooltip: "Refresh " + tooltipTitle,
      isFreeAction: true,
      onClick: actionsProps.tableClickActions.onClickRefreshFab,
    });
  }

  if (actionsProps.tableClickActions.onClickEdit) {
    actions.push({
      icon: tableIcons.Edit,
      iconProps: { style: { fontSize: "14px", color: "#FF00dd" } },
      tooltip: "Edit " + tooltipTitle,
      onClick: actionsProps.tableClickActions.onClickEdit
    });
  }

  if (actionsProps.tableClickActions.onClickDelete) {
    actions.push({
        icon: tableIcons.Delete,
        iconProps: { style: { fontSize: "14px", color: "blue" } },
        tooltip: "Delete " + tooltipTitle,
        onClick: actionsProps.tableClickActions.onClickDelete
    });
  }

  return actions;

};
