import React, { useState, useRef } from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import RootRef from "@material-ui/core/RootRef";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import TaskItem from "./task-item";
import { Typography } from "@material-ui/core";
import { func } from "prop-types";
import { RestaurantRounded } from "@material-ui/icons";

// import { DisplayFormikState } from './formikHelper';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 330;

let depDataArray = [];

const useStyles = makeStyles((theme) => ({
  textField123: {
    color: "red",
  },
  paper: {
    display: "flex",
    flex: 1,
    //overflowY: "scroll",
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 1}px`,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      width: "100%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100%",
      width: "100%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: `calc(100% - ${drawerWidth}px)`,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    //flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
}));

const startTaskID = "0";
const endTaskID = "9999";

const getIntitialData = () => {
  let initailTaskData;

  if (process.env.NODE_ENV !== "development") {
    initailTaskData = [
      {
        id: startTaskID,
        taskid: "START",
        duration: 0,
        desc: "START        ",
        label: "Start",
        predecessor: [],
        successor: [],
      },
      {
        id: endTaskID,
        taskid: "END",
        duration: 0,
        desc: "END                    ",
        label: "End",
        predecessor: [],
        successor: [],
      },
    ];
  } else {
    initailTaskData = [
      {
        id: startTaskID,
        taskid: "START",
        duration: 0,
        desc: "START        ",
        label: "Start",
        predecessor: [],
        successor: ["1", "2", "3", "4"],
      },
      {
        id: "1",
        taskid: "H",
        duration: 60,
        desc: "Install Router",
        label: "Router",
        predecessor: [startTaskID],
        successor: [endTaskID],
      },
      {
        id: "2",
        taskid: "H",
        duration: 30,
        desc: "Install Hardware",
        label: "Install HW",
        predecessor: [startTaskID],
        successor: [endTaskID],
      },
      {
        id: "3",
        taskid: "H",
        duration: 45,
        desc: "Install Windows",
        label: "Win 2000",
        predecessor: [startTaskID],
        successor: [endTaskID],
      },
      {
        id: "4",
        taskid: "H",
        duration: 30,
        desc: "Install Email Server",
        label: "Email Srv",
        predecessor: [startTaskID],
        successor: [endTaskID],
      },
      {
        id: endTaskID,
        taskid: "END",
        duration: 0,
        desc: "END                    ",
        label: "End",
        predecessor: ["1", "2", "3", "4"],
        successor: [],
      },
    ];
  }

  return initailTaskData;
};

const CPMTesting = () => {
  const [taskData, setTaskData] = useState(getIntitialData());

  const [showPredecessor, setShowPredecessor] = useState(true);
  const [criticalPathJSX, setCriticalPathJSX] = useState({ jsxData: " ", duration: 0 });
  const [showSuccessor, setShowSuccessor] = useState(true);
  const [alertOpen, setAlertOpen] = useState({ open: false, message: " ", severity: "error" });

  const classes = useStyles();

  const newTaskDescription = useRef();
  const newTaskLabel = useRef();
  const newTaskDuration = useRef(0);
  const editTaskRef = useRef("");
  const templateNameRef = useRef("");

  let validPaths = [];
  let criticalPaths = [];
  let criticalPath = 0;
  let maxDuration = 0;

  //let criticalPathJSX = "dddddd "
  const loadCheckListTemplate = () => {
    if (templateNameRef.current.value.length === 0) return;

    axios
      .get("/api/v1/checkList/template/" + templateNameRef.current.value.toUpperCase())
      .then((response) => {
        //      console.log("Template Load", response.data.data);
        setAlertOpen({ open: true, message: "Check List Loaded", severity: "success" });
        setTaskData(JSON.parse(response.data.data.template.templateJSON));
      })
      .catch((error) => {
        //        console.log("Template Load Error", error.response.data, error.response.status, error.response.data.message);
        //templateNameRef.current.error = true;
        //templateNameRef.current.helperText = "Not FOOOOND";
        setAlertOpen({ open: true, message: error.response.data.message, severity: "error" });
      });
  };

  const saveCheckListTemplate = () => {
    if (templateNameRef.current.value.length === 0 || taskData.length <= 2) return;

    //    axios.get( '/api/v1/tours' )

    const templateData = {
      name: templateNameRef.current.value.toString().toUpperCase(),
      templateJSON: JSON.stringify(taskData),
    };

    axios
      .post("/api/v1/checkList/template", templateData)
      .then((response) => {
        //        console.log("Template Saved", response.data.data);
        setAlertOpen({ open: true, message: "Check List Saved", severity: "success" });
      })
      .catch((error) => {
        //        console.log("Template Saved Error my error", error.response.data);
        setAlertOpen({ open: true, message: error.response.data.message, severity: "error" });
      });
  };

  const reorder = (list, startIndex, endIndex, mutateList = true) => {
    let result;
    if (mutateList === true) result = Array.from(list);
    else result = list;

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const checkValidTaskData = (checkEditID = true) => {
    if (checkEditID) {
      if (editTaskRef.current.value.length === 0) return false;
    }

    if (
      newTaskDescription.current.value.length === 0 ||
      newTaskLabel.current.value.length === 0 ||
      newTaskDuration.current.value.length === 0
    )
      return false;

    if (isNaN(newTaskDuration.current.value)) return false;

    return true;
  };

  const editTask = () => {
    if (!checkValidTaskData()) return;

    const taskID = editTaskRef.current.value;

    const taskIindex = taskData.findIndex((x) => x.id === taskID);

    if (taskIindex === -1) return;

    const newTaskData = Array.from(taskData);

    newTaskData[taskIindex].desc = newTaskDescription.current.value;
    newTaskData[taskIindex].label = newTaskLabel.current.value;
    newTaskData[taskIindex].duration = parseInt(newTaskDuration.current.value);

    setTaskData(newTaskData);
  };

  const addNewTask = () => {
    if (!checkValidTaskData(false)) return;

    let newID = Math.max.apply(
      Math,
      taskData.map(function (task) {
        return task.id === endTaskID ? startTaskID : task.id;
      })
    );
    newID = parseInt(newID) + 1;

    const newTaskJSON = {
      id: newID.toString().trim(),
      taskid: "H",
      duration: parseInt(newTaskDuration.current.value),
      desc: newTaskDescription.current.value,
      label: newTaskLabel.current.value,
      predecessor: [startTaskID],
      successor: [endTaskID],
    };

    //console.log(newTaskJSON);

    let sortIndex = getMaxPredecessorIndexForSort(taskData, startTaskID, 0, newTaskJSON.id);
    if (sortIndex === -1) sortIndex = 0;

    const newTaskData = Array.from(taskData);

    //newTaskData.splice(1, 0, newTaskJSON);

    newTaskData.splice(sortIndex + 1, 0, newTaskJSON);

    newTaskData[0].successor.push(newTaskJSON.id);
    newTaskData[newTaskData.length - 1].predecessor.push(newTaskJSON.id);

    setTaskData(newTaskData);
  };

  const addStartEndIDIfEmpty = (list, startID, endID) => {
    const startIndex = taskData.findIndex((x) => x.id === startID);
    const endIndex = taskData.findIndex((x) => x.id === endID);

    if (list[startIndex].predecessor.length === 0) {
      list[startIndex].predecessor = [startTaskID];

      if (list[0].successor.indexOf(startID) === -1) {
        list[0].successor.push(startID);
      }
    }

    if (list[endIndex].successor.length === 0) {
      list[endIndex].successor = [endTaskID];

      if (list[list.length - 1].predecessor.indexOf(endID) === -1) {
        list[list.length - 1].predecessor.push(endID);
      }
    }
  };

  const deleteDependencyRelation = (sourceTaskID, depedencyType, relatedTaskID) => {
    const sourceIndex = taskData.findIndex((x) => x.id === sourceTaskID);
    const relatedIndex = taskData.findIndex((x) => x.id === relatedTaskID);

    const newTaskData = Array.from(taskData);

    //    console.log("deldep", sourceTaskID, depedencyType, relatedTaskID);

    if (depedencyType === "P") {
      deleteDependency(newTaskData, sourceTaskID, relatedTaskID, "P", false);
      deleteDependency(newTaskData, relatedTaskID, sourceTaskID, "S", false);

      //if (newTaskData[sourceIndex].predecessor.length === 0) newTaskData[sourceIndex].predecessor = [startTaskID];
      //if (newTaskData[relatedIndex].successor.length === 0) newTaskData[relatedIndex].successor = [endTaskID];

      addStartEndIDIfEmpty(newTaskData, sourceTaskID, relatedTaskID);
    } else {
      deleteDependency(newTaskData, sourceTaskID, relatedTaskID, "S", false);
      deleteDependency(newTaskData, relatedTaskID, sourceTaskID, "P", false);

      addStartEndIDIfEmpty(newTaskData, relatedTaskID, sourceTaskID);
    }

    setTaskData(newTaskData);
  };

  const deleteDependency = (list, taskID, DependencyID, depedencyType, mutateList = true) => {
    let taskIndex = -1;

    if (taskID === startTaskID) taskIndex = 0;
    else if (taskID === endTaskID) taskIndex = list.length - 1;
    else {
      taskIndex = list.findIndex((x) => x.id === taskID);
    }

    if (taskIndex === -1) return;

    let result;

    if (mutateList === true) result = Array.from(list);
    else result = list;

    const array = depedencyType === "S" ? result[taskIndex].successor : result[taskIndex].predecessor;

    let i = array.indexOf(DependencyID);
    if (i !== -1) array.splice(i, 1);
  };

  const fillTaskDataForEdit = (task) => {
    editTaskRef.current.value = task.id;
    newTaskDescription.current.value = task.desc;
    //.value = task.desc;
    newTaskLabel.current.value = task.label;
    newTaskDuration.current.value = task.duration;
  };

  const deleteTask = (taskID) => {
    try {
      //      const taskID = deleteTaskRef.current.value;
      const taskIndex = taskData.findIndex((x) => x.id === taskID);

      if (taskIndex === -1) return;

      //const newTaskData = Array.from(taskData);

      //    const [removed] = newTaskData.splice(taskIndex, 1);

      const newTaskData = taskData.filter((task) => task.id !== taskID);

      deleteDependency(newTaskData, startTaskID, taskID, "S", false);
      deleteDependency(newTaskData, endTaskID, taskID, "P", false);

      //newTaskData.splice(taskIndex, 1);

      //if (removed) {

      setTaskData(newTaskData);
      return;
    } catch (error) {
      const ss = 1;
    }
  };
  //////////////////////////////////////   DRAG END

  function getMaxPredecessorIndexForSort(list, taskID, taskIndex = -1, idToSort) {
    if (taskIndex === -1) {
      taskIndex = list.findIndex((task) => task.id === taskID);
      if (taskIndex === -1) return -1;
    }

    if (taskID === startTaskID && list[taskIndex].successor.length === 0) return 0;

    if (list[taskIndex].successor.length === 1 && list[taskIndex].successor[0] === idToSort) return -1;

    let maxTaskID = Math.max.apply(
      Math,
      list[taskIndex].successor.map(function (id) {
        return id === endTaskID || (id * 1) >= (idToSort * 1) ? startTaskID : id;
      })
    );

    if (maxTaskID === 0) return -1;

    maxTaskID = maxTaskID.toString();

    const sortIndex = list.findIndex((task) => task.id === maxTaskID);

    return sortIndex;
  }

  function onDragEnd(result) {
    let destinationIndex;

    if (result.combine) {
      //console.log(result.combine);

      if (!result.combine.draggableId) {
        return;
      }
      destinationIndex = taskData.findIndex((x) => x.id === result.combine.draggableId); //  result.combine.draggableId;
    } else {
      if (!result.destination) {
        return;
      }
      destinationIndex = result.destination.index;
    }
    //    console.log("destindex", destinationIndex);

    const sourceIndex = result.source.index;

    if (destinationIndex === sourceIndex) {
      return;
    }

    if (taskData[destinationIndex].id === startTaskID || taskData[destinationIndex].id === endTaskID) {
      return;
    }

    ///////////////// COMBINE
    if (result.combine) {
      //console.log("combined");

      if (taskData[destinationIndex].predecessor.indexOf(taskData[sourceIndex].id) !== -1) return;

      if (taskData[destinationIndex].successor.indexOf(taskData[sourceIndex].id) !== -1) return;

      let newTaskData = Array.from(taskData);

      if (newTaskData[destinationIndex].successor.length === 1 && newTaskData[destinationIndex].successor[0] === endTaskID) {
        newTaskData[destinationIndex].successor = [newTaskData[sourceIndex].id];
        deleteDependency(newTaskData, endTaskID, newTaskData[destinationIndex].id, "P", false);
      } else {
        newTaskData[destinationIndex].successor.push(newTaskData[sourceIndex].id);
      }

      if (newTaskData[sourceIndex].predecessor.length === 1 && newTaskData[sourceIndex].predecessor[0] === startTaskID) {
        newTaskData[sourceIndex].predecessor = [newTaskData[destinationIndex].id];
        deleteDependency(newTaskData, startTaskID, newTaskData[sourceIndex].id, "S", false);
      } else {
        newTaskData[sourceIndex].predecessor.push(newTaskData[destinationIndex].id);
      }

      let sortIndex = getMaxPredecessorIndexForSort(
        newTaskData,
        newTaskData[destinationIndex].id,
        destinationIndex,
        newTaskData[sourceIndex].id
      );
      if (sortIndex === -1) sortIndex = destinationIndex;

      if (sourceIndex !== sortIndex + 1) {
        if (sourceIndex > sortIndex) {
          newTaskData = reorder(newTaskData, sourceIndex, sortIndex + 1);
        } else {
          newTaskData = reorder(newTaskData, sourceIndex, sortIndex);
        }
      }

      setTaskData(newTaskData);

      return;
    }
    //console.log("dragend",result.destination, taskData[result.destination.index]);
    const newTaskData = reorder(taskData, sourceIndex, destinationIndex);

    setTaskData(newTaskData);
  }

  //console.log("successor", dependencyData);
  function getDependencyData(dependencyType, taskID) {
    //console.log("getdepency",dependencyType, taskID)
    const newTaskData = Array.from(taskData);

    const currTask = newTaskData.find((task) => task.id === taskID);

    //console.log("data", dependencyType, currTask);

    const dependencyTasks = dependencyType === "S" ? Array.from(currTask.successor) : Array.from(currTask.predecessor);

    const dependencyData = [];

    dependencyTasks.forEach((dependencyTaskID, index) => {
      const task = newTaskData.find((x) => x.id === dependencyTaskID);
      if (task) {
        dependencyData.push({ id: task.id, label: task.label });
      }
    });

    const finalData = [...dependencyData];
    return finalData; // dependencyData;
  }

  const buildDepArray = (list) => {
    let s = [];

    taskData.forEach((task) => {
      //const currTask = taskData.find((task) => task.id === y);

      const depS = getDependencyData("S", task.id);
      const depP = getDependencyData("P", task.id);

      s.push({ S: depS, P: depP });
    });

    return s;
  };

  //depDataArray = buildDepArray();
  //console.log(depDataArray);

  const drawer = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="taskDesc"
          autoComplete="off"
          fullWidth
          label="Task Description"
          inputRef={newTaskDescription}
          InputLabelProps={{ shrink: true }}
          // className={classes.textField}
          //      helperText={touched.firstName ? errors.firstName : ""}
          //      error={touched.firstName && Boolean(errors.firstName)}
          margin="dense"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="taskLabel"
          autoComplete="off"
          fullWidth
          label="Task Label"
          inputRef={newTaskLabel}
          InputLabelProps={{ shrink: true }}
          // className={classes.textField}
          //      helperText={touched.firstName ? errors.firstName : ""}
          //      error={touched.firstName && Boolean(errors.firstName)}
          margin="dense"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="taskDuration"
          autoComplete="off"
          fullWidth
          label="Duration"
          inputRef={newTaskDuration}
          InputLabelProps={{ shrink: true }}
          // className={classes.textField}
          //      helperText={touched.firstName ? errors.firstName : ""}
          //      error={touched.firstName && Boolean(errors.firstName)}
          margin="dense"
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Button variant="contained" color="primary" onClick={addNewTask} style={{ textTransform: "none" }}>
          Add New Task
        </Button>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
        <TextField
          id="TaskID"
          style={{ width: "120px" }}
          label="Task ID"
          inputRef={editTaskRef}
          InputLabelProps={{ shrink: true }}
          // className={classes.textField}
          //      helperText={touched.firstName ? errors.firstName : ""}
          //      error={touched.firstName && Boolean(errors.firstName)}
          margin="dense"
        />
        <Button variant="contained" color="primary" onClick={editTask} style={{ textTransform: "none" }}>
          Edit Task
        </Button>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setShowPredecessor(!showPredecessor)}
          style={{ textTransform: "none" }}
        >
          Toggle Predecessor
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setShowSuccessor(!showSuccessor)}
          style={{ textTransform: "none" }}
        >
          Toggle Successors
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => calculateCriticalPath()}
          style={{ textTransform: "none" }}
        >
          Calculate Critical Path
        </Button>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography variant="h6">
          Critical Path {criticalPathJSX.duration === 0 ? " " : "( " + criticalPathJSX.duration + " )"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {criticalPathJSX.jsxData}
      </Grid>
    </Grid>
  );

  return (
    //    <DragDropContext onDragEnd={this.onDragEnd}>
    <React.Fragment>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                xxxxheight: "100%",
              }}
            >
              <div style={{ width: "45%" }}>
                <h1 style={{ marginTop: 0, padding: 0 }}>Add Check List</h1>
              </div>
              <div style={{ width: "55%" }}>
                <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
                  <TextField
                    id="templateName"
                    style={{ width: "140px" }}
                    label="Check List Name"
                    autoComplete="off"
                    inputRef={templateNameRef}
                    InputLabelProps={{ shrink: true }}
                    // className={classes.textField}
                    //      helperText={touched.firstName ? errors.firstName : ""}
                    //      error={touched.firstName && Boolean(errors.firstName)}
                    margin="dense"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={loadCheckListTemplate}
                    style={{ textTransform: "none" }}
                  >
                    Load Check List
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={saveCheckListTemplate}
                    style={{ textTransform: "none" }}
                  >
                    Save Check List
                  </Button>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>

        {/*         <Paper elevation={1} className={classes.paper}>

        <TaskItem />
        <TaskItem />
        <TaskItem />

        </Paper>
 */}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="taskitems" type="TASK" isCombineEnabled>
            {(provided, snapshot) => (
              <Paper elevation={1} className={classes.paper} ref={provided.innerRef} {...provided.droppableProps}>
                {taskData.map((task, index) => (
                  <TaskItem
                    key={task.id}
                    task={task}
                    index={index}
                    successorDependencyData={getDependencyData("S", task.id)}
                    predecessorDependencyData={getDependencyData("P", task.id)}
                    /*                     successorDependencyData={depDataArray[index].S}
                    predecessorDependencyData={depDataArray[index].P}
 */

                    showPredecessor={showPredecessor}
                    showSuccessor={showSuccessor}
                    deleteDependencyRelation={deleteDependencyRelation}
                    deleteTask={deleteTask}
                    fillTaskDataForEdit={fillTaskDataForEdit}
                  />
                ))}
                {provided.placeholder}
              </Paper>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Hidden smDown implementation="css">
        <div style={{ width: drawerWidth }}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>
        </div>
      </Hidden>
      <Snackbar
        open={alertOpen.open}
        autoHideDuration={4000}
        onClose={() => {
          setAlertOpen({ open: false });
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setAlertOpen({ open: false });
          }}
          severity={alertOpen.severity}
        >
          {alertOpen.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );

  function clone(currPath) {
    return JSON.parse(JSON.stringify(currPath));
  }

  function traverse(node, path) {
    if (node === undefined) {
      node = taskData[0];
    }
    if (path === undefined) {
      path = [];
    }
    path.push(node.id);

    //    console.log("Current Path", path);

    if (node.id === endTaskID) {
      //      console.log("Found Valid", path);
      validPaths.push(clone(path));
      return;
    }
    node.successor.forEach((x) => {
      const currNode = taskData.find((task) => task.id === x);

      if (currNode) {
        if (path.indexOf(currNode.id) === -1) {
          let newPath = clone(path);
          traverse(currNode, newPath);
        }
      }
    });
  }

  function calculateCriticalPath() {
    validPaths = [];
    let validPathsDuration = [];
    criticalPath = 0;
    criticalPaths = [];
    maxDuration = 0;

    traverse();

    let totalDuration = 0;

    const getAllValidTasks = [];

    //console.log("valid paths: ", validPaths);

    validPaths.forEach((x, index) => {
      totalDuration = 0;
      x.forEach((y) => {
        const currTask = taskData.find((task) => task.id === y);
        totalDuration = totalDuration + currTask.duration;
        if (getAllValidTasks.indexOf(y) === -1) {
          getAllValidTasks.push(y);
        }
      });

      if (totalDuration > maxDuration) {
        maxDuration = totalDuration;
        criticalPath = index;
      }

      validPathsDuration.push(totalDuration);

      //    console.log("duration", totalDuration);
    });

    if (validPaths.length === 0) {
      setCriticalPathJSX({ jsxData: "INVALID Data", duration: 0 });
      return;
    }

    if (getAllValidTasks.length !== taskData.length) {
      setCriticalPathJSX({ jsxData: "Not All Path Valid ", duration: 0 });
      return;
    }

    validPathsDuration.forEach((duration, index) => {
      if (duration === maxDuration) {
        criticalPaths.push(validPaths[index]);
      }
    });
    let currTask1;

    const cpjsx = (
      <React.Fragment>
        {criticalPaths.length > 1 ? (
          <p style={{ fontSize: 16, fontWeight: "bolder" }}> Total Critical Path : {criticalPaths.length}</p>
        ) : null}

        {criticalPaths.map((cPath, index) => (
          <div>
            {criticalPaths.length > 1 ? <p style={{ fontWeight: "bold" }}> Path No : {index + 1}</p> : null}

            {getCriticalJSX(cPath)}
          </div>
        ))}
      </React.Fragment>
    );

    function getCriticalJSX(cPath) {
      return cPath.map((y) => {
        const currTask = taskData.find((task) => task.id === y);
        return (
          <p>
            {" "}
            {currTask.desc +
              (y === endTaskID
                ? " "
                : y === startTaskID
                ? " => "
                : ` [ ${currTask.id} ] Dur: ${currTask.duration} Min. => `)}{" "}
          </p>
        );
      });
    }

    setCriticalPathJSX({ jsxData: cpjsx, duration: maxDuration });

    //console.log("cp and duration", criticalPath, maxDuration, criticalPathJSX);
  }
};

export default CPMTesting;
