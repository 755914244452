
	import React from 'react'
	import CardList from './CardList'
	import { DndProvider } from 'react-dnd'
	import Backend from 'react-dnd-html5-backend'
//    import Backend from 'react-dnd-touch-backend'

const hasNative =
document && (document.elementsFromPoint || document.msElementsFromPoint)

function getDropTargetElementsAtPoint(x, y, dropTargets) {
return dropTargets.filter(t => {
  const rect = t.getBoundingClientRect()
  return (
    x >= rect.left &&
    x <= rect.right &&
    y <= rect.bottom &&
    y >= rect.top
  )
})
}

// use custom function only if elementsFromPoint is not supported
const backendOptions = {
	getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
	}

	

	const  carDNDExample = () => {
		return (
			<div className="App">
				<DndProvider 
				backend={Backend}
				options={backendOptions}
				>
					<CardList />
				</DndProvider>
			</div>
		)
	}

 export default carDNDExample;
