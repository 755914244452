import React from "react";
import { startTaskID, endTaskID } from "./checkList-manager";
import { convertToDaysHoursMinutes } from "shared/utility"
//const startTaskID = startTaskID;
//const endTaskID = endTaskID;

export let validPaths = [];
export let criticalPaths = [];
export let criticalPath = 0;
export let maxDuration = 0;

const clone = (currPath) => {
  return JSON.parse(JSON.stringify(currPath));
};

const traverse = (taskData, node, path) => {
  if (node === undefined) {
    node = taskData[0];
  }
  if (path === undefined) {
    path = [];
  }
  path.push(node.taskID);

  //    console.log("Current Path", path);

  if (node.taskID === endTaskID) {
    //      console.log("Found Valid", path);
    validPaths.push(clone(path));
    return;
  }
  node.successor.forEach((x) => {
    const currNode = taskData.find((task) => task.taskID === x);

    if (currNode) {
      if (path.indexOf(currNode.taskID) === -1) {
        let newPath = clone(path);
        traverse(taskData, currNode, newPath);
      }
    }
  });
};

export function calculateCriticalPath(taskData) {
  validPaths = [];
  let validPathsDuration = [];
  criticalPath = 0;
  criticalPaths = [];
  maxDuration = 0;

  traverse(taskData);

  let totalDuration = 0;

  const getAllValidTasks = [];

  //console.log("valid paths: ", validPaths);

  validPaths.forEach((x, index) => {
    totalDuration = 0;
    x.forEach((y) => {
      const currTask = taskData.find((task) => task.taskID === y);
      totalDuration = totalDuration + currTask.duration;
      if (getAllValidTasks.indexOf(y) === -1) {
        getAllValidTasks.push(y);
      }
    });

    if (totalDuration > maxDuration) {
      maxDuration = totalDuration;
      criticalPath = index;
    }

    validPathsDuration.push(totalDuration);

    //    console.log("duration", totalDuration);
  });

  if (validPaths.length === 0) {
    return { error: true, jsxData: "INVALID Data", duration: 0 };
  }

  if (getAllValidTasks.length !== taskData.length) {
    return { error: true, jsxData: "Not All Path Valid ", duration: 0 };
  }

  validPathsDuration.forEach((duration, index) => {
    if (duration === maxDuration) {
      criticalPaths.push(validPaths[index]);
    }
  });
  let currTask1;

  const cpjsx = (
    <React.Fragment>
      {criticalPaths.length > 1 ? (
        <p style={{ fontSize: 16, fontWeight: "bolder" }}> Total Critical Path : {criticalPaths.length}</p>
      ) : null}

      {criticalPaths.map((cPath, index) => (
        <div key={"cpJSXdiv-" + index}> 
          {criticalPaths.length > 1 ? <p style={{ fontWeight: "bold" }}> Path No : {index + 1}</p> : null}

          {getCriticalJSX(cPath)}
        </div>
      ))}
    </React.Fragment>
  );

  function getCriticalJSX(cPath) {
    return cPath.map((y) => {
      const currTask = taskData.find((task) => task.taskID === y);
      return (
        <p key={"cpJSX-" + y}>
          {" "}
          {currTask.name +
            (y === endTaskID
              ? " "
              : y === startTaskID
              ? " => "
              : ` [ ${currTask.taskID} ] Dur: ${convertToDaysHoursMinutes(currTask.duration)} => `)}{" "}
        </p>
      );
    });
  }

  return { error: false, jsxData: cpjsx, duration: maxDuration };
}

/**
 * Implementation of the Critical Path Method (CPM) with variation
 * @see http://en.wikipedia.org/wiki/Critical_path_method
 * 
 * Shows all the critical Paths, returns a subset of the graph
 * containing the critical activities
 */

/**
 * Activity Class
 * XE: new Activity({
 *		id: 'A',
 *		duration: 8,
 *      	predecessors: ['C','B']
 *	});
 */
export function Activity(configs) {
  var self = this;
  configs = configs || {};
  self.id = configs.id;
  self.duration = configs.duration;
  self.est = configs.est; //Earliest Start Time
  self.lst = configs.lst; //Latest Start Time
  self.eet = configs.eet; //Earliest End Time
  self.let = configs.let; //Latest End Time 
  self.h 	 = configs.h; //clearance (holgura)
  self.successors = [];
  self.predecessors = configs.predecessors || [];
  return self;
}

/**
* List of Activities Class
*  
*/
export function ActivityList() {
//Private vars
  var self = this;
  var processed = false;
  var list = {};
  var start, end;

  /**
   * Add an activity to the list
   */
  self.addActivity = function (activity) {
      list[activity.id] = activity;
  };

  /**
  * Private method Pre process list
  * Adds and sets the start and end node
  * Replaces successors and predecessors Ids for pointers
  * @throws Error if a predecessor not exists 
  */
  function processList(){
    if(processed){
      return; //Already processed
      }
    processed = true;
      
    start = new Activity({id : 'start', duration : 0});
    
      //Replaces id for pointers to actvities   
      for(var i in list){
          //console.log("list i", i);
          var current = list[i];
          var predecessorsIds = current.predecessors;
          var predecessors = [];

          if(predecessorsIds.length == 0){
            predecessors.push(start);
            start.successors.push(current);
          }else{
            for(var j in predecessorsIds){
                var previous = list[predecessorsIds[j]];
                if(!previous){
                    throw new Error('Node ' + j + ' dont exists');
                }
                predecessors.push(previous); 
                previous.successors.push(current); 
            }
          }
          current.predecessors = predecessors;
      }
      
  }

  /**
   * Private function set Earliest Times
   * Earliest Start Time (est) and Earliest End Time (eet)
   * Recursively browse starting from root
   */
  function setEarlyTimes(root){
      for(var i in root.successors){
          var node = root.successors[i];
          
          var predesessors = node.predecessors;
          for (var j in predesessors) {
            var activity = predesessors[j];
              if (node.est == null || node.est < activity.eet)
                node.est = activity.eet;
          }
          node.eet = node.est + node.duration;
          setEarlyTimes(node);
      }
  }


  /**
   * Private function set Latest Times
   * Latest Start Time (est) and Latest End Time (eet)
   * Recursively browse starting from root
   */
  function setLateTimes(root){
      if(root.successors.length == 0){
        root.let = root.eet;
          root.lst = root.let - root.duration;
          root.h   = root.eet - root.let;
      }else{
        for(var i in root.successors){
            var node = root.successors[i];
            setLateTimes(node);
            if(root.let == null || root.let > node.lst){
             root.let = node.lst;
            }
        }

        root.lst = root.let - root.duration;
        root.h = root.let - root.eet;
      }
  }


  /**
   * Build Critical Path Tree recursively
   *
   */
function buildCriticalPath(root, path){
  if(root.h == 0){
    var predecessors = root.predecessors;
    for(var i in predecessors){
      var node = predecessors[i];
      if(node.h == 0){
        var clone = new Activity({
          id : node.id,
          duration : node.duration,
          est : node.est,
          lst : node.lst,
          eet : node.eet,
          let : node.let,
          h : node.h
        });
        //Dont add start ... its just a fake node
        if(node !== start){
          path.predecessors.push(clone);
          buildCriticalPath(node, clone);
        }
        
      }
    }
  }
          
}

/**
  * Applies the PEM, with little modification
 * Returns a Graph subset with the structure of the involved activities 
 */
  self.getCriticalPath = function(endid){
    if(!endid){
      throw new Error('End activity id is required!');
    }
    end = list[endid];
    if(!end){
      throw new Error('Node end dont not match');
    }
  //Make sure that list is well defined
    processList();
    
      //Setup Start Times
      start.est = 0;
      start.eet = 0;
      setEarlyTimes(start);

      //Setup End Times
      end.let = end.eet;
      end.lst = end.let - end.duration;
      end.h  	= end.eet - end.let;
      setLateTimes(start);

      //Assemble Critical Path (tree)
  var path = null;
  if(end.h == 0){
    var path = new Activity({
      id : end.id,
      duration : end.duration,
      est : end.est,
      lst : end.lst,
      eet : end.eet,
       let : end.let,
      h	: end.h
    });

    buildCriticalPath(end, path);
  }
      return path;
  };

  /**
   * Get the activity list, does a preprocessing (only once)  
   */
  self.getList = function () {
    processList();
      return list;
  };
  return self;
};
