import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
//import { useHotkeys } from "react-hotkeys-hook";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import RootRef from "@material-ui/core/RootRef";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from "@material-ui/icons/Menu";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";

import { KeyboardTimePicker, KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import FormDialog from "components/UI/dialog-form";
import TaskForm from "components/checkList/addAmmendTask";
import AddCheckListDataForm from "./addCheckListUserEtc";
import CheckListUsers from "./checkListUsers";
import AutoCompleteFormik from "../UI/autocomplete-formik";

import TaskItem from "./task-item-checkList";

import { Typography } from "@material-ui/core";

import * as chkListManager from "shared/checkList-manager";
import * as chkListCalculation from "shared/checkList-calculation";
//import { Activity, ActivityList } from "../utils/calculate-cpm-etc";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as axiosAPI from "../utils/getSetAPIData";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import * as tzUtils from "shared/timeZonesUtils";

import { useWidth, convertToDaysHoursMinutes } from "shared/utility";
import moment from "moment";
import momenttz from "moment-timezone";

// import { DisplayFormikState } from './formikHelper';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 330;

const useStyles = makeStyles((theme) => ({
  textField123: {
    color: "red",
  },
  paper: {
    display: "flex",
    flex: 1,
    //overflowY: "scroll",
    marginLeft: 0,
    marginRight: 0,
    marginTop: "0px", //theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 1}px`,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      width: "100%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100%",
      width: "100%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: `calc(100% - ${drawerWidth}px)`,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    //flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
}));

const startTaskID = chkListManager.startTaskID;
const endTaskID = chkListManager.endTaskID;
let taskFormData = { task: {} };
let addCheckListData = {};
let currrentActivityList = new chkListCalculation.ActivityList();
let criticalPathData;

const getIntitialData = () => {
  return chkListManager.getIntitialData(true);
};

let stateData = {};
let formTitle = "Add Check List";
let isNew = true;
let initialValues = {};
let deletedIDs = [];
let branchData = null;
let customerData = null;
let isDateChange = false;

const validationSchema = Yup.object({
  // branch: Yup.object().shape({_id: Yup.string("Branch ID").required("Invalid Branch"), name: Yup.string("Branch Name").required("Invalid Branch")}),
  name: Yup.string("Enter a name").required("Name is required"),
  customer: Yup.string("Enter Customer").required("Customer is requird").nullable(),
  branch: Yup.string("Enter Site").required("Site is requird").nullable(),
  /*     .test("branch-customer-check", "Branch not belong to Customer", function (value) {
      const { customer } = this.parent;
      if (!customer || !value) return true;
      else {
      console.log("test",value._id,customer._id)
      return (customer._id !== value.customer._id);
      }
    }),
 */
  startDate: Yup.date("checkList Date").nullable(),
  endDate: Yup.date("checkList Date").nullable(),
  /*     .test("at-least-one-date", "Both Starting and Ending Dates should not Empty", function (value) {
      const { startDate } = this.parent;
      return !!(startDate || value);
    }),
 */
});

function ScrollTop(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 650, // (props.addButtonTop),
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div role="presentation">{children}</div>
    </Zoom>
  );
}

const CheckListForm = (props) => {
  const [taskData, setTaskData] = useState([]);

  const [showPredecessor, setShowPredecessor] = useState(true);
  const [criticalPathJSX, setCriticalPathJSX] = useState({ jsxData: " ", duration: 0 });
  const [showSuccessor, setShowSuccessor] = useState(true);
  const [alertOpen, setAlertOpen] = useState({ open: false, message: " ", severity: "error" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [initialValues1, setInitialValues1] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [autoCompleteKey, setAutoCompleteKey] = useState();
  const [showCalcs, setShowCalcs] = useState(false);
  const [addDataDialogOpen, setAddDataDialogOpen] = useState(false);
  const [checkListUsers, setCheckListUsers] = useState([]);
  const [mobMenuAnchorEl, setMobMenuAnchorEl] = React.useState(null);
  const open = Boolean(mobMenuAnchorEl);
  const [lockDateFlag, setLockDateFlag] = React.useState(2); // 1=> Lock Start Data, 2=> Lock End Date

  //  const [addTaskLeftPosition, setAddTaskLeftPosition] = React.useState(0);
  //  const [addButtonTop, setAddButtonTop] = React.useState(0);
  //  const addTaskReference1 = React.useRef(null);
  //  const addTaskReference2 = React.useRef(null);

  const theme = useTheme();
  const classes = useStyles();

  swal2.setTheme(theme);
  const currWidth = useWidth();
  const isSMUp = currWidth !== "sm" && currWidth !== "xs" ? true : false; //    useMediaQuery(theme.breakpoints.up("sm"));

  /*   React.useLayoutEffect(() => {
    function updatePosition() {
      //console.log("left position", addTaskReference.current.getBoundingClientRect().left);
      if (addTaskReference1.current) {
        setAddTaskLeftPosition(addTaskReference1.current.getBoundingClientRect().left);
        setAddButtonTop(addTaskReference1.current.getBoundingClientRect().top);
      } else if (addTaskReference2.current) {
        setAddTaskLeftPosition(addTaskReference2.current.getBoundingClientRect().left);
        setAddButtonTop(addTaskReference2.current.getBoundingClientRect().top);
      }
    }
    window.addEventListener("resize", updatePosition);
    updatePosition();
    return () => window.removeEventListener("resize", updatePosition);
  }, []);

 */
  const handleMobileMenuClose = () => {
    setMobMenuAnchorEl(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  const handleAddCheckListDataClose = () => {
    setAddDataDialogOpen(false);
  };

  const handleCheckListUserDelete = (deleteUser) => {
    const newUserList = checkListUsers.filter((user) => user._id !== deleteUser._id);
    setCheckListUsers(newUserList);
    //props.deleteTask(taskID);
  };

  /*   useHotkeys(
    "ctrl-a,ctrl-c",
    (e, handler) => {
      //    if (!isLoading && !addDataDialogOpen && !dialogOpen && !infoDialogOpen) {
      //      alert("capture ctrl+a");
      //    }
      e.preventDefault();
      switch (handler.key) {
        case "ctrl-a":
          openTaskFormDialog();
          break;
        case "ctrl-c":
          //calculateCriticalPath();
          alert("I am still can RUN");
          break;
        default:
          alert(e);
      }
    },
    { splitKey: "-" }
  );
 */
  useEffect(() => {
    //    setAutoCompleteKey(Math.random());
    (async () => {
      initialValues = {
        name: "",
        branch: null,
        customer: null,
        startDate: null,
        endDate: null,
      };

      //checkListUsers=[];
      branchData = null; // [{ _id: "", name: "" }];
      customerData = null;
      formTitle = "Add Check List";
      isNew = true;
      stateData = {};
      let users = [];

      if (props.location.state && props.location.state.stateData) {
        stateData = props.location.state.stateData;
        isNew = false;
        formTitle = "Edit Check List";

        setIsLoading(true);

        let dataUrl = "/api/v1/checkLists/masterData/" + props.location.state.stateData._id;

        let respData = await axiosAPI.getSetAxiosData("get", dataUrl);

        if (respData.status === "success") {
          //toastMessage.DisplayToast("Job Started", { type: toastMessage.messageType.SUCCESS, });
          stateData = respData.data.data.data;

          if (stateData.status !== "pending") {
            toastMessage.DisplayToast('Cannot update started/completed Check List', { type: toastMessage.messageType.ERROR });
            props.history.replace("/checkList", { isNew: false });
            return;
          }
          //console.log("state dataaaa", stateData);
        } else {
          toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
          props.history.replace("/checkList", { isNew: false });
          return;
        }

        initialValues.name = stateData.name;

        if (stateData.branch) {
          branchData = {
            _id: stateData.branch._id,
            name: stateData.branch.name,
            timeZone: stateData.branch.timeZone,
            customer: { _id: stateData.customer._id, name: stateData.customer.name },
          };
        }

        if (stateData.customer) {
          customerData = {
            _id: stateData.customer._id,
            name: stateData.customer.name,
            //branch: { _id: stateData.branch._id, name: stateData.branch.name },
          };
        }

        initialValues.branch = branchData;
        initialValues.customer = customerData;

        //initialValues.startDate = stateData.startDateTime;
        //initialValues.endDate = stateData.endDateTime;

        /*         
        const convStartDate = moment(stateData.startDateTime).tz(branchData.timeZone);
        const convEndDate = moment(stateData.endDateTime).tz(branchData.timeZone);

        initialValues.startDate = convStartDate.tz(moment.tz.guess(), true).toDate();
        initialValues.endDate = convEndDate.tz(moment.tz.guess(), true).toDate();
        */

        initialValues.startDate = tzUtils.convertSpecificTZtoLocalKeepTime(stateData.startDateTime, branchData.timeZone);
        initialValues.endDate = tzUtils.convertSpecificTZtoLocalKeepTime(stateData.endDateTime, branchData.timeZone);

        if (stateData.users.length > 0) {
          users = stateData.users;
        }

        dataUrl = "/api/v1/checkLists/" + stateData._id;

        respData = await axiosAPI.getSetAxiosData("get", dataUrl);

        if (respData.status === "success") {
          setTaskData(respData.data.data.data);
        } else {
          toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
          props.history.replace("/checkList", { isNew: false });
          return;
        }

        if(stateData.otherInfo.lockDateFlag) {

          setLockDateFlag(stateData.otherInfo.lockDateFlag);

        }

        setIsLoading(false);
      } else {
        setTaskData(getIntitialData());
        //console.log("task data init", getIntitialData());
      }

      setInitialValues1(initialValues);
      setCheckListUsers(users);
      setAutoCompleteKey(Math.random());
    })();
  }, [props.location.state]);

  //let criticalPathJSX = "dddddd "

  const calculateCriticalPath = (openInfoDialog = false, calcOnly = false, showError = true) => {
    //console.log(taskData);

    try {
      if (!taskData || taskData.length === 0) return;

      criticalPathData = chkListCalculation.calculateCriticalPath(taskData);

      if (criticalPathData.error) {
        //console.log(criticalPathData);
        if (showError) {
          toastMessage.DisplayToast(criticalPathData.jsxData, { type: toastMessage.messageType.ERROR });
          setCriticalPathJSX({ error: true, jsxData: "", duration: 0 });
        }
        return;
      }

      currrentActivityList = new chkListCalculation.ActivityList();
      taskData.forEach(function (activity) {
        currrentActivityList.addActivity(
          new chkListCalculation.Activity({
            id: activity.taskID,
            duration: parseInt(activity.duration),
            predecessors: activity.predecessor,
          })
        );
        //     console.log(activity.predecessor);
      });

      currrentActivityList.getCriticalPath(endTaskID);
      const activityListData = currrentActivityList.getList();
      //console.log("activit log", activityListData);

      const newTaskData = Array.from(taskData);

      Object.keys(activityListData).map((currID, i) => {
        /*       console.log(
        currID +
          "  Dur:" +
          activityListData[currID].duration +
          "  EST:" +
          activityListData[currID].est +
          "  LST:" +
          activityListData[currID].lst +
          "  EET:" +
          activityListData[currID].eet +
          "  LET:" +
          activityListData[currID].let
      );
 */
        const taskIindex = newTaskData.findIndex((x) => x.taskID === currID);

        if (taskIindex !== -1) {
          newTaskData[taskIindex].taskEST = activityListData[currID].est;
          newTaskData[taskIindex].taskLST = activityListData[currID].lst;
          newTaskData[taskIindex].taskEFT = activityListData[currID].eet;
          newTaskData[taskIindex].taskLFT = activityListData[currID].let;
        }
      });

      //    console.log(newTaskData);

      if (lockDateFlag === 2) {
        if (values.endDate)
          setFieldValue("startDate", moment(values.endDate).subtract(criticalPathData.duration, "minutes").toDate());
      } else {
        //if (values.startDate) {
        if (values.startDate)
          setFieldValue("endDate", moment(values.startDate).add(criticalPathData.duration, "minutes").toDate());
      }

      setTaskData(newTaskData);

      if (!calcOnly) {
        if (criticalPathData) {
          setCriticalPathJSX(criticalPathData);
        }

        if (openInfoDialog) {
          setInfoDialogOpen(true);
        }
      }
    } catch (error) {
      if (showError) {
        toastMessage.DisplayToast(error.message, { type: toastMessage.messageType.ERROR });
        setCriticalPathJSX({ error: true, jsxData: "", duration: 0 });
      }
    }

    return;
  };

  const handleTaskFormSubmit = (taskFormData) => {
    handleDialogClose();

    const taskDetails = {
      duration: parseInt(taskFormData.duration),
      name: taskFormData.name,
      description: taskFormData.description,
    };

    if (taskFormData.isNew) {
      addNewTask(taskDetails);
    } else {
      taskDetails.taskID = taskFormData.taskID;
      editTask(taskDetails);
    }
  };

  const editTask = (taskDetails) => {
    const newTaskData = chkListManager.editTask(taskData, taskDetails);

    //    console.log(newTaskData);

    if (newTaskData) {
      setTaskData(newTaskData);
    }
  };

  const addNewTask = (taskDetails) => {
    //console.log("new task data", taskDetails);

    const newTaskData = chkListManager.addNewTask(taskData, taskDetails, true);

    if (newTaskData) {
      setTaskData(newTaskData);
    }
  };

  const openTaskFormDialog = () => {
    if (isLoading || addDataDialogOpen || dialogOpen || infoDialogOpen) {
      return;
    }

    taskFormData = {
      isNew: true,
      task: {
        taskID: "",
      },
    };

    setDialogOpen(true);
    return;
  };

  const openLoadTemplateDialog = () => {
    addCheckListData = {
      formTitle: "Load Check List Template",
      fldTitle: "Check List Template",
      url: "/api/v1/checkListTemplates?sort=name",
      handleSubmit: handleLoadTemplateSubmit,
    };

    setAddDataDialogOpen(true);
    return;
  };

  const handleLoadTemplateSubmit = async (templateData) => {
    handleAddCheckListDataClose();

    setIsLoading(true);

    let dataUrl = "/api/v1/checkListTemplates/" + templateData._id;

    const respData = await axiosAPI.getSetAxiosData("get", dataUrl);

    //console.log(respData);

    if (respData.status === "success") {
      const newTaskData = respData.data.data.data.map((task) => {
        delete task["masterID"];
        delete task["indexNo"];
        delete task["_id"];
        //console.log(task);
        return { ...task, ...chkListManager.defaultCheckListProps };
      });
      //console.log("load template", newTaskData);
      toastMessage.DisplayToast("Template Loaded", { type: toastMessage.messageType.SUCCESS });
      setTaskData(newTaskData);
    } else {
      toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
    }

    setIsLoading(false);

    //    setCheckListUsers(newCheckListUsers);
  };

  const openAddCheckListUserDialog = () => {
    addCheckListData = {
      formTitle: "Add Check List User",
      fldTitle: "User",
      url: "/api/v1/users?sort=name",
      handleSubmit: handleAddUserSubmit,
    };

    setAddDataDialogOpen(true);
    return;
  };

  const handleAddUserSubmit = (userData) => {
    handleAddCheckListDataClose();

    const userIindex = checkListUsers.findIndex((x) => x._id === userData._id);

    if (userIindex === -1) {
      const newCheckListUsers = Array.from(checkListUsers);

      newCheckListUsers.push(userData);
      setCheckListUsers(newCheckListUsers);
    }
  };

  const fillTaskDataForEdit = (task) => {
    taskFormData = {
      isNew: false,
      task,
      //      taskID: task.taskID,
      //      duration: parseInt(task.duration),
      //      description: task.description,
      //      name: task.name,
    };

    setDialogOpen(true);
    return;
  };

  const displayTaskInfo = (task) => {
    taskFormData = {
      displayOnly: true,
      isNew: false,
      isCheckList: true,
      startDate: values.startDate,
      task,
    };

    setDialogOpen(true);
    return;
  };

  function onDragEnd(result) {
    const newTaskData = chkListManager.onDragEnd(taskData, result);

    if (newTaskData) {
      setTaskData(newTaskData);
    }
  }

  const getDependencyData = (dependencyType, taskID) => {
    return chkListManager.getDependencyData(taskData, dependencyType, taskID);
  };

  const deleteDependencyRelation = (sourceTaskID, depedencyType, relatedTaskID) => {
    const newTaskData = chkListManager.deleteDependencyRelation(taskData, sourceTaskID, depedencyType, relatedTaskID);

    if (newTaskData) {
      setTaskData(newTaskData);
    }
  };

  const deleteTask = async (taskID) => {
    const confirm = await swal2.confirmationDialog("Are you sure to Delete Task: [ " + taskID + " ]", "Confirm Deletion", {
      type: "danger",
    });

    if (confirm.status === ":OK") {
      const returnData = chkListManager.deleteTask(taskData, taskID);
      if (returnData) {
        //console.log(returnData.deletedTask);
        if (returnData.deletedTask._id) {
          deletedIDs.push(returnData.deletedTask._id);
        }
        setTaskData(returnData.newTaskData);
      }
    }
  };

  const checkAndSubmitData = () => {
    if (checkListUsers.length === 0) {
      toastMessage.DisplayToast("No User Added", { type: toastMessage.messageType.ERROR });
      return;
    }

    if (lockDateFlag === 2) {
      if (!values.endDate) {
        toastMessage.DisplayToast("Enter CheckList End Date", { type: toastMessage.messageType.ERROR });
        return;
      }
    } else if (lockDateFlag === 1) {
      if (!values.startDate) {
        toastMessage.DisplayToast("Enter CheckList Start Date", { type: toastMessage.messageType.ERROR });
        return;
      }
    } else {
      toastMessage.DisplayToast("Enter CheckList Date Flag", { type: toastMessage.messageType.ERROR });
      return;
    }

    calculateCriticalPath(false, true);

    if (criticalPathData.error) {
      //toastMessage.DisplayToast(criticalPathData.jsxData, { type: toastMessage.messageType.ERROR });
      //        actions.setSubmitting(false);
      return;
    }

    handleSubmit();
  };

  const criticalPathJSXDisplay = (
    <React.Fragment>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography variant="h6">
          Critical Path{" "}
          {criticalPathJSX.duration === 0 ? " " : "( " + convertToDaysHoursMinutes(criticalPathJSX.duration) + " )"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {criticalPathJSX.jsxData}
      </Grid>
    </React.Fragment>
  );

  const drawer = (
    <Grid container spacing={2}>
      <Grid item xs={12} align="center" style={{ marginTop: "15px" }}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setShowPredecessor(!showPredecessor)}
          style={{ textTransform: "none", marginRight: "5px" }}
        >
          Toggle Predecessor
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setShowSuccessor(!showSuccessor)}
          style={{ textTransform: "none" }}
        >
          Toggle Successors
        </Button>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "center", marginTop: "5px" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => calculateCriticalPath()}
          style={{ textTransform: "none", marginRight: "5px" }}
        >
          Calc. Critical Path etc.
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setShowCalcs(!showCalcs)}
          style={{ textTransform: "none" }}
        >
          Toggle Calcs.
        </Button>
      </Grid>
      {criticalPathJSXDisplay}
    </Grid>
  );

  const mobileMenu = (
    <div>
      <Button
        aria-controls="fade-mob-menu"
        aria-haspopup="true"
        variant="contained"
        size={currWidth === "xs" ? "small" : "medium"}
        color="primary"
        onClick={(event) => setMobMenuAnchorEl(event.currentTarget)}
        style={{ textTransform: "none", marginRight: "5px" }}
        startIcon={<MenuIcon />}
      >
        Actions
      </Button>

      <Menu
        id="fade-mob-menu"
        anchorEl={mobMenuAnchorEl}
        keepMounted
        open={open}
        onClose={handleMobileMenuClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            setShowPredecessor(!showPredecessor);
            handleMobileMenuClose();
          }}
        >
          Toggle Predecessor
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowSuccessor(!showSuccessor);
            handleMobileMenuClose();
          }}
        >
          Toggle Successors
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowCalcs(!showCalcs);
            handleMobileMenuClose();
          }}
        >
          Toggle Calcs.
        </MenuItem>
      </Menu>
      <Button
        variant="contained"
        color="secondary"
        size={currWidth === "xs" ? "small" : "medium"}
        onClick={() => calculateCriticalPath(true)}
        style={{ textTransform: "none" }}
      >
        Calc. Crit. Path Etc
      </Button>
    </div>
  );

  const {
    handleSubmit,
    setFieldValue,
    errors,
    isSubmitting,
    isValid,
    touched,
    getFieldProps,
    setTouched,
    resetForm,
    dirty,
    setSubmitting,
    values,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (data, actions) => {
      if (data.branch.customer._id !== data.customer._id) {
        toastMessage.DisplayToast("Site do not Belong to Customer", { type: toastMessage.messageType.ERROR });
        return;
      }

      actions.setSubmitting(true);

      //console.log("submit", moment(data.startDate).utcOffset(0, true).format());
      //      moment.utc(moment(stateData.startDateTime).format('YYYY-MM-DD HH:mm')).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm ZZ')

      //console.log("submit", data);
      //console.log(moment(data.startDate).tz(data.branch.timeZone, true).format("YYYY-MM-DD HH:mm"));

      // const convStartDate = moment(data.startDate).tz(data.branch.timeZone, true).toDate();
      // const convEndDate = moment(data.endDate).tz(data.branch.timeZone, true).toDate();

      const convStartDate = tzUtils.convertSpecificTZtoLocal(data.startDate, data.branch.timeZone);
      const convEndDate = tzUtils.convertSpecificTZtoLocal(data.endDate, data.branch.timeZone);

      //console.log(convStartDate, convEndDate, data.branch.timeZone);
      //console.log(data.startDate, data.endDate);

      const confirm = await swal2.confirmationDialog(
        "Are you sure to " + (isNew ? "add new " : "update") + "  Check List",
        "Confirmation"
      );

      if (confirm.status === ":OK") {
        let dataUrl = "/api/v1/checkLists" + (isNew ? "" : "/" + stateData._id);

        const users = checkListUsers.map((user) => user._id);
        let otherInfo = { criticalPathDuration: criticalPathData.duration, lockDateFlag };
        if (!isNew) {
          if (stateData.otherInfo) {
            otherInfo = { ...stateData.otherInfo, criticalPathDuration: criticalPathData.duration, lockDateFlag };
          }
        }

        const apiData = {
          masterData: {
            name: data.name,
            branch: data.branch._id,
            customer: data.customer._id,
            startDateTime: convStartDate,
            endDateTime: convEndDate,
            users,
            otherInfo,
          },
          transactionData: taskData,
        };

        if (!isNew) {
          apiData.deletedIDs = deletedIDs;
        }

        const respData = await axiosAPI.getSetAxiosData(isNew ? "post" : "patch", dataUrl, apiData);

        if (respData.status === "success") {
          toastMessage.DisplayToast(isNew ? "Check List Added" : "Check List  Updated", {
            type: toastMessage.messageType.SUCCESS,
          });

          deletedIDs = [];

          if (isNew) {
            actions.resetForm({});
            setTaskData(getIntitialData());
            setCheckListUsers([]);
            branchData = null; //[{ _id: "", name: "" }];
            customerData = null;
            setAutoCompleteKey(Math.random());
            actions.setSubmitting(false);
          } else {
            props.history.replace("/checkList", { isNew: false });
          }
        } else {
          toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
          actions.setSubmitting(false);
        }
      } else {
        actions.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (isDateChange) {
      calculateCriticalPath(false, true, false);
      isDateChange = false;
    }
  }, [values.startDate, values.endDate]);

  //  if (isDateChange) {
  //    console.log("checking render", values.endDate);
  //    isDateChange = false;
  // }

  return (
    //    <DragDropContext onDragEnd={this.onDragEnd}>
    <React.Fragment>
      <div className={classes.container}>
        <ScrollTop>
          <div
            style={{
              position: "fixed",
              //zIndex: 110000,
              //left: 0,
              margin: 0,
              padding: 0,
              top: isSMUp ? 80 : 70,

              //              left: addTaskLeftPosition - (theme.spacing.unit * isSMUp ? 2 : 0),
            }}
          >
            <Tooltip title="Add New Task" aria-label="add-template">
              <Fab color="secondary" size="small" onClick={openTaskFormDialog} style={{ bottom11: "5px" }}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </div>
        </ScrollTop>
        <Grid container spacing={2}>
          <Grid item xs={8} spacing={0}>
            <Typography variant="h6" style={{ color: theme.sidedrawer.subListTextColor }}>
              {formTitle}
            </Typography>
          </Grid>
          {
            <Grid item xs={4} spacing={0} align="right">
              {!values.branch || !values.branch.timeZone ? null : (
                <Typography variant="subtitle1" style={{ marginRight: "10px" }}>
                  <span style={{ color: theme.sidedrawer.subListTextColor }}>{"Time Zone: "}</span>
                  <span style={{ paddingLeft: "5px" }}>{values.branch.timeZone}</span>
                </Typography>
              )}
            </Grid>
          }

          {!isLoading ? (
            <React.Fragment>
              <Grid item xs={12}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="name"
                  autoComplete="nope"
                  helperText={touched.name ? errors.name : ""}
                  error={touched.name && Boolean(errors.name)}
                  label="Check List Name"
                  fullWidth
                  {...getFieldProps("name")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <AutoCompleteFormik
                  updateValue={(value) => {
                    customerData = value ? value : null;
                    //                    setFieldValue("branch", value ? value : "");
                    setFieldValue("customer", value ? value : null);
                  }}
                  getOptionLabel={(option) => option.name} //+ " - Customer: " + option.customer.name}
                  fldName="customer"
                  labelName="Customer"
                  value1={customerData}
                  key={"customer-" + autoCompleteKey}
                  touched={touched}
                  errors={errors}
                  setTouched={setTouched}
                  url="/api/v1/customers?sort=name"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <AutoCompleteFormik
                  updateValue={(value) => {
                    //                    setValues({"customer": (value ? value.customer.name : ""),
                    //                    "branch": (value ? value : null)}, true);
                    branchData = value ? value : null;
                    setFieldValue("branch", value ? value : null);
                    //setFieldValue("customer", value ? value.customer.name : "");
                  }}
                  getOptionLabel={(option) => option.name} //+ " - Customer: " + option.customer.name}
                  fldName="branch"
                  labelName="Site"
                  value1={branchData}
                  key={"branch-" + autoCompleteKey}
                  touched={touched}
                  errors={errors}
                  setTouched={setTouched}
                  url={"/api/v1/branches?sort=name&customer=" + (!values.customer ? "" : values.customer._id)}
                  disabled={!values.customer}
                />
              </Grid>

              {/*               <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="customer"
                  autoComplete="nope"
                  helperText={touched.customer ? errors.customer : ""}
                  error={touched.customer && Boolean(errors.customer)}
                  label="Customer"
                  fullWidth
                  {...getFieldProps("customer")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
 */}
              <Grid item xs={12} md={5}>
                <KeyboardDateTimePicker
                  disabled={lockDateFlag === 2 ? true : false}
                  fullWidth
                  id="startDate"
                  name="startDate"
                  label={"Start Time"} // ( Time Zone: " + (!values.branch || !values.branch.timeZone ? "N/A" : values.branch.timeZone) + " )"  }
                  format="MM/dd/yyyy hh:mm a"
                  value={values.startDate}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    if (value) {
                      isDateChange = true;
                    }
                    setFieldValue("startDate", value ? value : "");
                  }}
                  helperText={touched.startDate ? errors.startDate : ""}
                  error={touched.startDate && Boolean(errors.startDate)}
                  KeyboardButtonProps={{
                    "aria-label": "change start date",
                  }}
                />
              </Grid>

              <Grid item xs={9} md={5}>
                <KeyboardDateTimePicker
                  disabled={lockDateFlag === 1 ? true : false}
                  fullWidth
                  id="endDate"
                  name="endDate"
                  label="End Time"
                  format="MM/dd/yyyy hh:mm a"
                  value={values.endDate}
                  onBlur={handleBlur}
                  onChange={(value) => {
                    if (value) {
                      isDateChange = true;
                    }
                    setFieldValue("endDate", value ? value : "");
                  }}
                  helperText={touched.endDate ? errors.endDate : ""}
                  error={touched.endDate && Boolean(errors.endDate)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
              <Grid item xs={3} md={2}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      size="medium"
                      checked={lockDateFlag === 2 ? true : false}
                      onChange={(event) => {
                        setLockDateFlag(event.target.checked ? 2 : 1);
                      }}
                    />
                  }
                  label="Constraint"
                  labelPlacement="top"
                />
              </Grid>

              {isSMUp || 1 === 1 ? null : (
                <React.Fragment>
                  <Grid item xs={12} sm={7} align="center" style={{ marginTop: "15px" }}>
                    <Button
                      variant="contained"
                      size={currWidth === "xs" ? "small" : "medium"}
                      color="primary"
                      onClick={() => setShowPredecessor(!showPredecessor)}
                      style={{ textTransform: "none", marginRight: "5px" }}
                    >
                      Toggle Predecessor
                    </Button>
                    <Button
                      variant="contained"
                      size={currWidth === "xs" ? "small" : "medium"}
                      color="primary"
                      onClick={() => setShowSuccessor(!showSuccessor)}
                      style={{ textTransform: "none" }}
                    >
                      Toggle Successors
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={5} align="center" style={{ marginTop: "15px" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => calculateCriticalPath(true)}
                      style={{ textTransform: "none" }}
                    >
                      Calculate Critical Path
                    </Button>
                  </Grid>
                </React.Fragment>
              )}

              <Grid item xs={12}>
                <CheckListUsers checkListUsers={checkListUsers} handleCheckListUserDelete={handleCheckListUserDelete} />
              </Grid>

              <Grid
                item
                xs={12}
                md={10}
                style={{ display: "flex", justifyContent: isSMUp ? "flex-start" : "space-between" }}
              >
                <Button
                  variant="contained"
                  size={currWidth === "xs" ? "small" : "medium"}
                  color="primary"
                  onClick={openAddCheckListUserDialog}
                  style={{ textTransform: "none", marginRight: "5px" }}
                >
                  Add User
                </Button>
                {!isNew ? null : (
                  <Button
                    variant="contained"
                    alignItems="right"
                    size={currWidth === "xs" ? "small" : "medium"}
                    color="primary"
                    onClick={openLoadTemplateDialog}
                    style={{ textTransform: "none" }}
                  >
                    Load Template
                  </Button>
                )}
              </Grid>

              {isSMUp ? (
                <Grid item xs={2} align="right">
                  <div>
                    <Tooltip title="Add New Task" aria-label="add-template">
                      <Fab color="primary" size="small" onClick={openTaskFormDialog}>
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
                    {mobileMenu}
                  </Grid>
                  <Grid item xs={2} align="right">
                    <div>
                      <Tooltip title="Add New Task" aria-label="add-template">
                        <Fab color="primary" size="small" onClick={openTaskFormDialog}>
                          <AddIcon />
                        </Fab>
                      </Tooltip>
                    </div>
                  </Grid>
                </React.Fragment>
              )}

              <Grid item xs={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="taskitems" type="TASK" isCombineEnabled>
                    {(provided, snapshot) => (
                      <Paper elevation={1} className={classes.paper} ref={provided.innerRef} {...provided.droppableProps}>
                        {taskData.map((task, index) => (
                          <TaskItem
                            key={task.taskID}
                            task={task}
                            index={index}
                            successorDependencyData={getDependencyData("S", task.taskID)}
                            predecessorDependencyData={getDependencyData("P", task.taskID)}
                            showPredecessor={showPredecessor}
                            showSuccessor={showSuccessor}
                            showCalcs={showCalcs}
                            deleteDependencyRelation={deleteDependencyRelation}
                            deleteTask={deleteTask}
                            fillTaskDataForEdit={fillTaskDataForEdit}
                            displayTaskInfo={displayTaskInfo}
                            startDate={values.startDate}
                          />
                        ))}
                        {provided.placeholder}
                      </Paper>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid>
            </React.Fragment>
          ) : null}
        </Grid>

        {!isSubmitting && !isLoading ? (
          <Grid container spacing={2} style={{ marginTop: "15px" }}>
            <Grid item xs={12} align={isSMUp ? "right" : "center"}>
              <Button
                variant="contained"
                color="primary"
                onClick={checkAndSubmitData}
                style={{ textTransform: "none", marginRight: "10px" }}
              >
                Save Check List
              </Button>
              {isNew ? null : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.history.replace("/checkList", { isNew: false });
                  }}
                  style={{ textTransform: "none" }}
                >
                  Cancel
                </Button>
              )}
            </Grid>
          </Grid>
        ) : (
          <div style={{ marginTop: "15px", marginBottomxx: "30px" }}>
            <CircularProgress />
          </div>
        )}
      </div>

      <Hidden smDown implementation="css">
        <div style={{ width: drawerWidth }}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>
        </div>
      </Hidden>

      <FormDialog
        open={dialogOpen}
        currWidth={currWidth}
        title={
          taskFormData.isNew
            ? "Add New Task"
            : (taskFormData.displayOnly ? "Task Information" : "Update Task") +
              " ( TaskID : " +
              taskFormData.task.taskID +
              " )"
        }
        disableBackdrop={taskFormData.displayOnly ? false : true}
        handleClose={handleDialogClose}
      >
        <TaskForm taskDetails={taskFormData} handleClose={handleDialogClose} handleSubmit={handleTaskFormSubmit}></TaskForm>
      </FormDialog>

      <FormDialog
        open={infoDialogOpen}
        currWidth={currWidth}
        title="Information"
        titleStyle={{ textAlign: "center" }}
        disableBackdrop={false}
        handleClose={handleInfoDialogClose}
      >
        {criticalPathJSXDisplay}
      </FormDialog>

      <FormDialog
        open={addDataDialogOpen}
        title={addCheckListData.formTitle}
        //titleStyle={{ textAlign: "center" }}
        handleClose={handleAddCheckListDataClose}
      >
        <AddCheckListDataForm
          fldDetails={addCheckListData}
          handleClose={handleAddCheckListDataClose}
          handleSubmit={addCheckListData.handleSubmit}
        ></AddCheckListDataForm>
      </FormDialog>
    </React.Fragment>
  );
};

export default CheckListForm;
