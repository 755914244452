import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MaterialTable, { MTableToolbar } from "material-table";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import useSWR, { mutate } from "swr";

import { KeyboardDatePicker } from "@material-ui/pickers";

import moment from "moment";

//import axios from "axios";

import AutoCompleteFormik from "../UI/autocomplete-formik";
import * as axiosAPI from "../utils/getSetAPIData";
import tableIcons, { addCustomButton } from "../utils/materialTableIcons";
import * as materialTableDefaults from "../utils/materialTableDefaults";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import { convertToDaysHoursMinutes, formatOnlyDateToISOString, formatOnlyDateToStartEndOfDay } from "shared/utility";
import mtQueryClass from "shared/mtQueryDataClass";
import { date } from "yup";

//const styles = theme => ({
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      width: "100",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
      maxWidth: "90%",
      width: "90%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
    padding: 0,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
}));

let tableColumns = [];

const title = "Jobs Summary";
//let tableClickActions = {};
const mtQuery = new mtQueryClass();
mtQuery.searchUrl = "&searchField=name&searchValue=";
mtQuery.url = "/api/v1/checkLists/jobSummary?sort=customerName";
const dateFormat = "MMM D, YYYY h:mm A";
const swrKey = "/api/v1/checkLists/jobSummary";
let stateData = {};

function JobSummaryList(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [autoCompleteKey, setAutoCompleteKey] = useState(Math.random());
  const [serverError, setServerError] = useState({ isError: false, message: " ", type: "SERVER", statusCode: 0 });
  const [startSWRFetch, setStartSWRFetch] = useState(false);
  const [isStartupLoading, setIsStartupLoading] = useState(true);
  const [tablePage, setTablePage] = useState(0);
  const [jobQueryParams, setJobQueryParams] = useState({
    jobStartDate:
      process.env.NODE_ENV === "development"
        ? formatOnlyDateToISOString(moment("2020-08-01T00:00:00.000Z").toDate())
        : new Date(),
    jobEndDate: new Date(),
    customerData: null,
  });

  const isSMUp = useMediaQuery(theme.breakpoints.up("sm"));
  swal2.setTheme(theme);
  const tableRef = React.useRef();

  const { userRole, userCustomer, otherInfo: {jobSummaryPollingIntervel} } = useSelector((state) => state.auth, shallowEqual);
  //console.log(userCustomer);
  //const userCustomer = authData.userCustomer;

  const getMTActions = () => {
    const tableClickActions = {
      onClickRefreshFab: (event) => {
        if (startSWRFetch === false) {
          setStartSWRFetch(true);
        } else {
          mutate(swrKey);
        }
      },
    };

    addCustomButton("execJob", "Details");

    const mtActions = materialTableDefaults.mtActions(props, tableIcons, { tableClickActions }, "Jobs");

    mtActions.push({
      icon: tableIcons.execJob,
      //iconProps: { style: { fontSize: "14px", color: "blue" } },
      tooltip: "See Jobs Detail",
      onClick: async (event, rowData) => {
        setStartSWRFetch(false);
        //const { tableData, ...stateData } = rowData;
        props.history.push("/jobDetail", {
          summaryState: {
          isNew: false,
          customerData: jobQueryParams.customerData,
          stateData: {
            ...jobQueryParams,
            customerData: { _id: rowData.customerID, name: rowData.customerName },
            userRole,
            statusData: null,
          }},
        });
      },
    });

    return mtActions;
  };

  if (isSMUp) {
    tableColumns = [
      { title: "Customer Name", field: "customerName" },
      { title: "Pending", field: "pendingJobs" },
      { title: "Started", field: "startedJobs" },
      { title: "Completed", field: "completedJobs" },
      { title: "Total Jobs", field: "totalJobs" },

      /*       {
      { title: "Status", field: "status", width: 100, maxWidth: 100 },
        title: "Start Date/TIme",
        field: "startDateTime",
        render: (rowData) => moment(rowData.startDateTime).format(dateFormat),
      }, */
    ];
  } else {
    tableColumns = [
      { title: "Customer Name", field: "customerName" },
      //{ title: "Soyadı", field: "surname" },
      //{ title: "Doğum Yılı", field: "birthYear", type: "numeric" },
      //{ title: "Email", field: "email" }
    ];
  }

  const detailPanel = (rowData) => {
    let fieldArray = [];

    if (1 === 1) {
      //(isSMUp) {
      fieldArray = [
        { heading: "Pending Jobs :", value: "Total: " + rowData.pendingJobs + " ( Late: " + rowData.latePendingJobs + " )" },
        { heading: "Started Jobs :", value: "Total: " + rowData.startedJobs + " ( Late: " + rowData.lateStartedJobs + " )" },
        {
          heading: "Completed Jobs :",
          value: "Total: " + rowData.completedJobs + " ( Late: " + rowData.lateCompletedJobs + " )",
        },
      ];
    } else {
      fieldArray = [
        { heading: "Customer :", value: rowData.customer.name },
        { heading: "Site :", value: rowData.branch.name },
        { heading: "Users :", value: rowData.users.map((user) => user.name).join(", ") },
        { heading: "Status :", value: rowData.status },
        { heading: "Time Zone :", value: rowData.branch.timeZone },
        { heading: "Start Date/Time :", value: moment(rowData.startDateTime).format(dateFormat) },
        { heading: "End Date/Time :", value: moment(rowData.endDateTime).format(dateFormat) },
        { heading: "Total Time :", value: convertToDaysHoursMinutes(rowData.otherInfo.criticalPathDuration) },
      ];
    }

    return (
      <div style={{ marginTop: "15px", marginBottom: "15px" }}>
        {fieldArray.map((tableField) => {
          return (
            <Grid container spacing={1} alignItems="center" flex={1}>
              <Grid item xs={5} sm={3} style={{ paddingLeft: "10px" }}>
                <Typography variant="subtitle2" component="span" color="primary">
                  {tableField.heading}
                </Typography>
              </Grid>
              <Grid item xs={7} sm={9}>
                <Typography variant="body2" component="span">
                  {/* {tableField[1] === "country" ? rowData.country.name : rowData[tableField[1]]} */}
                  {tableField.value}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      stateData = { ...jobQueryParams, customerData: userCustomer };
      //console.log("stateData:", props.location.state ? props.location.state.stateData : "no State");
      if (props.location.state && props.location.state.stateData) {
        stateData = props.location.state.stateData;
        //console.log(stateData);
        setJobQueryParams(stateData);
      }
      else if (userRole === "viewer") {
        setJobQueryParams({ ...jobQueryParams, customerData: userCustomer });
      }

      setIsStartupLoading(false);

      setAutoCompleteKey(Math.random());
    })();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [isSMUp]);

  useEffect(() => {
    setStartSWRFetch(false);

    mtQuery.url = "/api/v1/checkLists/jobSummary?startDate=";
    mtQuery.url = mtQuery.url + formatOnlyDateToStartEndOfDay(jobQueryParams.jobStartDate, "start") + "&endDate=";
    mtQuery.url = mtQuery.url + formatOnlyDateToStartEndOfDay(jobQueryParams.jobEndDate, "end");

    if (jobQueryParams.customerData) {
      if (jobQueryParams.customerData._id) {
        mtQuery.url = mtQuery.url + "&customerID=" + jobQueryParams.customerData._id;
      }
    }

    if (tableRef.current) {
      tableRef.current.onChangePage(0);
    }

    setStartSWRFetch(true);
  }, [jobQueryParams]);

  const fetchExecutionData = () => {
    if (startSWRFetch && tableRef.current) {
      tableRef.current.onQueryChange();
    }

    return [];
  };

  useSWR(startSWRFetch ? swrKey : null, fetchExecutionData, {
    onFailure: (data, key, config) => {},
    onSuccess: (data, key, config) => {
      //      if (data.length > 0) {
      //        if (isLoading) setIsLoading(false);
      //        setTaskData(data);
      //        if (stateData.completedAt) {
      //          setStartSWRFetch(false);
      //        }
      //      }
    },
    refreshInterval: 1000 * jobSummaryPollingIntervel,
  });

  //  useEffect(() => {
  //console.log("tableref", tableRef);
  //  }, [tableRef]);

  //  console.log("PAGE RENDERED");

  const queryParamChange = () => {};

  const GetQueryData = (query) => {
    const returnData = mtQuery.getMaterialTableQueryData(query, setServerError);

    if (startSWRFetch === false) {
      setStartSWRFetch(true);
    }
    return returnData;
  };

  //console.log("currdata", currentData) ;
  //  alert("render");
  return (
    <React.Fragment>
      {/* <ToastMessages alertOpen={alertOpen} setAlertOpen={setAlertOpen} /> */}
      {/* <ToastContainer /> */}
      <div className={classes.container}>
        {/* <Paper elevation={1} className={classes.paper}> */}
        {/* <h2>User Form</h2> */}
        <div className={classes.paper}>
          {serverError.isError ? (
            <div style={{ width: "100%" }}>
              <h1 style={{ textAlign: "center" }}>{serverError.statusCode === "403" ? "UNAUTHORIZED" : "SERVER ERROR"}</h1>
              <h2 style={{ textAlign: "center" }}>{serverError.message}</h2>
            </div>
          ) : (
            <React.Fragment>
              <Grid container spacing={2} style={{ marginBottom: "15px" }}>
                <Grid item xs={6} md={3}>
                  <KeyboardDatePicker
                    fullWidth
                    value={jobQueryParams.jobStartDate}
                    id="startDate"
                    name="startDate"
                    label={"From Date"} // ( Time Zone: " + (!values.branch || !values.branch.timeZone ? "N/A" : values.branch.timeZone) + " )"  }
                    format="MM/dd/yyyy"
                    onChange={(value) => {
                      setJobQueryParams({ ...jobQueryParams, jobStartDate: value ? value : null });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <KeyboardDatePicker
                    fullWidth
                    value={jobQueryParams.jobEndDate}
                    id="endDate"
                    name="endDate"
                    label="To Date"
                    format="MM/dd/yyyy"
                    onChange={(value) => {
                      setJobQueryParams({ ...jobQueryParams, jobEndDate: value ? value : null });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AutoCompleteFormik
                    updateValue={(value) => {
                      setJobQueryParams({ ...jobQueryParams, customerData: value ? value : null });
                    }}
                    getOptionLabel={(option) => option.name} //+ " - Customer: " + option.customer.name}
                    disabled={userRole === "viewer"}
                    fldName="customer"
                    labelName="Customer"
                    value1={stateData.customerData ? stateData.customerData : null}
                    key={"customer" + autoCompleteKey}
                    touched={{}}
                    errors={{}}
                    setTouched={(x) => {}}
                    url="/api/v1/customers?sort=name"
                  />
                </Grid>
              </Grid>
              {isStartupLoading ? (
                <div style={{ marginTop: "15px", marginBottomxx: "30px" }}>
                  <CircularProgress />
                </div>
              ) : (
                <MaterialTable
                  //              isLoading={true}
                  tableRef={tableRef}
                  components={{
                    Toolbar: (props) => (
                      <React.Fragment>
                        {!isSMUp ? (
                          <div style={{ width: "100%", marginTop: "15px" }}>
                            <Typography variant="h6" color="primary" align="center" gutterBottom>
                              {" "}
                              {title}{" "}
                            </Typography>
                          </div>
                        ) : null}

                        <div style={{ color: theme.sidedrawer.subListTextColor }}>
                          <MTableToolbar {...props} />
                        </div>
                      </React.Fragment>
                    ),
                  }}
                  style={{ width: "100%", paddingLeft: "0px" }}
                  columns={tableColumns}
                  //data={[{ name: "Mehmet", surname: "Baran", birthYear: 1987, birthCity: 63 }]}
                  data={GetQueryData}
                  title={isSMUp ? title : ""}
                  icons={tableIcons}
                  detailPanel={detailPanel}
                  onRowClick={(event, rowData, togglePanel) => togglePanel()}
                  actions={getMTActions()}
                  options={{
                    initialPage: jobQueryParams.tablePage,
                    sorting: false,
                    search: false,
                    loadingType: "linear",
                    //detailPanelType: "single",
                    actionsColumnIndex: -1,
                    actionsCellStyle: {
                      //backgroundColor: "#ffccdd",
                      color: theme.sidedrawer.subListTextColor,
                      textAlign: "center",
                      minWidth: "100px", // "rgba(255, 175, 0, 0.6)"
                    },
                    headerStyle: {
                      backgroundColor: "#01579b", //'#e8eaf5',
                      color: "#FFF",
                    },
                  }}
                  localization={{
                    pagination: {
                      labelDisplayedRows: "{from}-{to} of {count}",
                    },
                  }}
                />
              )}
            </React.Fragment>
          )}

          {/* </Hidden> */}
        </div>
        {/* </Paper> */}
      </div>
    </React.Fragment>
  );
}

export default JobSummaryList;
