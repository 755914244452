import React from "react";
import { ToastContainer, toast, Slide } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import transitions from "@material-ui/core/styles/transitions";



 toast.configure({
    autoClose: 3000,
    position: toast.POSITION.BOTTOM_CENTER,
    transition: Slide,
    style: {
      zIndex: 90000
    }
//    draggable: false,
    //same as ToastContainer props
  });

//  console.log(toast.TYPE);

export const messageType = { ...toast.TYPE };

export const DisplayToast = (message, options) => {

    toast(message, options);

}   