import React, { Component, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
//import { Form } from "./customerForm";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as axiosAPI from "../utils/getSetAPIData";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import AutoCompleteFormik from "../UI/autocomplete-formik";

//const styles = theme => ({
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "90%",
      width: "90%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "70%",
      width: "70%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
}));

const validationSchema = Yup.object({
  name: Yup.string("Enter a name").required("Name is required"),
  email: Yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
  contactPerson: Yup.string("Enter Contact Person").required("Contact Person is required"),
  address: Yup.string("Enter Address").required("Address is required"),
  state: Yup.string("Enter State/Province").required("State/Province is required"),
  city: Yup.string("Enter City").required("City is required"),
  country: Yup.string("Enter Country").required("Country is requird"),
  postalCode: Yup.string("Enter Zip/Postal Code").required("Zip/Postal Code is requird"),
  telephone: Yup.string("Enter Telephone").required("Telephone is requird"),
});

let resetForm = false;

//class FormikInputForm extends Component {
function AddAmmendCustomer(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [autoCompleteKey, setAutoCompleteKey] = useState(Math.random());

  const submit = async (data, actions) => {
    actions.setSubmitting(true);

    setTimeout(() => {
      actions.setSubmitting(false);
    }, 3000);
  };

  let isNew = true;
  let formTitle = "Add New Customer";
  const initialValues = {
    name: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    telephone: "",
    postalCode: "",
    contactPerson: "",
  };

  let countryData = null; // [{ _id: "", name: "" }];

  const { customerData } = props.location.state;

  if (props.location.state) {
    if (props.location.state.customerData) {
      isNew = false;
      formTitle = "Edit Customer";

      initialValues.name = customerData.name;
      initialValues.contactPerson = customerData.contactPerson;
      initialValues.email = customerData.email;
      initialValues.address = customerData.address;
      initialValues.city = customerData.city;
      initialValues.state = customerData.state;
      initialValues.country = !customerData.country ? null : customerData.country._id;
      initialValues.telephone = customerData.telephone;
      initialValues.postalCode = customerData.postalCode;

      if (customerData.country) {
        countryData = { _id: customerData.country._id, name: customerData.country.name };
      }
    }
  }

  const { handleSubmit, setFieldValue, errors, isSubmitting, isValid, touched, getFieldProps, setTouched, dirty } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: async (data, actions) => {
      actions.setSubmitting(true);

//      const confirm = await swal2.confirmationDialog("Are you sure to Update Record", "Confirmation");
      const confirm = await swal2.confirmationDialog("Are you sure to " + (isNew ? "add new" : "update") +  " Customer", "Confirmation");

      if (confirm.status === ":OK") {
        const dataUrl = "/api/v1/customers" + (isNew ? "" : "/" + customerData._id);

        const respData = await axiosAPI.getSetAxiosData(isNew ? "post" : "patch", dataUrl, data);

        if (respData.status === "success") {
          toastMessage.DisplayToast(isNew ? "Customer Added" : "Customer Updated...", {
            type: toastMessage.messageType.SUCCESS,
          });

          if (isNew) {
            actions.resetForm({});
            countryData = null; //[{ _id: "", name: "" }];
            setAutoCompleteKey(Math.random());
            actions.setSubmitting(false);
          } else {
            props.history.replace("/customer", { isNew: false });
          }
        } else {
          toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
          actions.setSubmitting(false);
        }
      } else {
        actions.setSubmitting(false);
      }
    },
  });

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Paper elevation={1} className={classes.paper}>
          <h2 style={{ color: theme.sidedrawer.subListTextColor }}>{formTitle}</h2>
          {/* {props.location.state ? props.location.state.Name : null} */}
          {/* <Form {...props} formik={formik} isNew={isNew} isSub={formik.isSubmitting} /> */}
          <form autoComplete="nope" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="name"
                  autoComplete="nope"
                  helperText={touched.name ? errors.name : ""}
                  error={touched.name && Boolean(errors.name)}
                  label="Name"
                  fullWidth
                  {...getFieldProps("name")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="contactPerson"
                  autoComplete="nope"
                  helperText={touched.contactPerson ? errors.contactPerson : ""}
                  error={touched.contactPerson && Boolean(errors.contactPerson)}
                  label="Contact Person"
                  fullWidth
                  {...getFieldProps("contactPerson")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="email"
                  helperText={touched.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)}
                  label="Email"
                  fullWidth
                  {...getFieldProps("email")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "10px" }}
                  id="telephone"
                  helperText={touched.telephone ? errors.telephone : ""}
                  error={touched.telephone && Boolean(errors.telephone)}
                  label="Telephone"
                  fullWidth
                  {...getFieldProps("telephone")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="address"
                  helperText={touched.address ? errors.address : ""}
                  error={touched.address && Boolean(errors.address)}
                  label="Address"
                  fullWidth
                  {...getFieldProps("address")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="city"
                  helperText={touched.city ? errors.city : ""}
                  error={touched.city && Boolean(errors.city)}
                  label="City"
                  fullWidth
                  {...getFieldProps("city")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="state"
                  helperText={touched.state ? errors.state : ""}
                  error={touched.state && Boolean(errors.state)}
                  label="State/Province"
                  fullWidth
                  {...getFieldProps("state")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="postalCode"
                  helperText={touched.postalCode ? errors.postalCode : ""}
                  error={touched.postalCode && Boolean(errors.postalCode)}
                  label="Zip/Postal Code"
                  fullWidth
                  {...getFieldProps("postalCode")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              {/*               <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="country"
                  helperText={touched.country ? errors.country : ""}
                  error={touched.country && Boolean(errors.country)}
                  label="Country"
                  fullWidth
                  {...getFieldProps("country")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>
 */}
              <Grid item xs={12} md={6}>
                <AutoCompleteFormik
                  updateValue={(value) => setFieldValue("country", value ? value._id : "")}
                  getOptionLabel={(option) => option.name}
                  fldName="country"
                  labelName="Country"
                  value1={countryData}
                  key={"country-" + autoCompleteKey}
                  touched={touched}
                  errors={errors}
                  setTouched={setTouched}
                  url="/api/v1/other/getCountries?sort=name"
                />
              </Grid>

              <div style={{ width: "100%", textAlign: "center" }}>
                {!isSubmitting ? (
                  <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                    <Button
                      //                  style={{ marginTop: "15px", marginBottom: "15px" }}
                      type="submit"
                      //fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting || !dirty}
                    >
                      Submit
                    </Button>
                    {isNew ? null : (
                      <Button
                        style={{ marginLeft: "15px" }}
                        //type="button"
                        //fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          props.history.replace("/customer", { isNew: false });
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                ) : (
                  <div style={{ marginTop: "15px", marginBottom: "30px" }}>
                  <CircularProgress  />
                  </div>
                )}
              </div>
              {/* <div> {isSubmitting.toString()} </div> */}
            </Grid>
          </form>
        </Paper>
      </div>
    </React.Fragment>
  );
}

export default AddAmmendCustomer;
