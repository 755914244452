import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function AutoCompleteFormikStatic(props) {

  const {touched, errors, setTouched, fldName, labelName} = props;
  
/*   React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
 */

  return (
    <Autocomplete
      disabled={props.disabled}
      id={"autocomplete-" + fldName}
      name={fldName}
      //style={{ width: 300 }}
      defaultValue={props.value}
      getOptionLabel={(option) => option.name}
      options={props.options}
      onBlur={ () => setTouched({ [fldName]: true }) }
      onChange={(event, newValue) => { props.updateValue(newValue); }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={labelName}
          id={fldName}
          fullWidth
          helperText={touched ? errors : ""}
          error={touched && Boolean(errors)}
        />
      )}
    />
  );
}
