import React, { Component } from "react";
import { Formik } from "formik";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Form } from "./formik-form";
import Paper from "@material-ui/core/Paper";
import * as Yup from "yup";

//const styles = theme => ({
const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
      [theme.breakpoints.up("sm")]: {
        maxWidth: "70%",
        width: "70%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`  
      },
    width: "100%"
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
//    flexDirection: "column",
//    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
//    margin: 'auto',
  },
}));

const validationSchema = Yup.object({
  name: Yup.string("Enter a name").required("Name is required"),
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string("")
    .min(8, "Password must contain atleast 8 characters")
    .required("Enter your password"),
  confirmPassword: Yup.string("Enter your password")
    .required("Confirm your password")
    .oneOf([Yup.ref("password")], "Password does not match")
});

//class FormikInputForm extends Component {
function FormikInputForm(props) {
  const submit = data => {
    console.log(data);
  };

  const classes = useStyles();
  const values = { name: "", email: "", confirmPassword: "", password: "" };
  return (
    <React.Fragment>
      <div className={classes.container}>
        <Paper elevation={1} className={classes.paper}>
          <h2>User Form</h2>
          {props.location.state ? props.location.state.Name : null}
          <Formik
            render={props => <Form {...props} />}
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={submit}
          />
        </Paper>
      </div>
    </React.Fragment>
  );
}

export default FormikInputForm;
