import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import moment from "moment";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

import DependencyArray from "./task-dependency";

import { Draggable } from "react-beautiful-dnd";

import { AddMinutesToTaskTimeAndFormat, convertToDaysHoursMinutes } from "shared/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginBottom: theme.spacing.unit * 1,
  },
  paper: {
    padding: 0, //theme.spacing(2),
    margin: "auto",
    maxWidth: "100%",
    minWidth: "100%",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const boxBorderColor = "rgba(0, 0, 255, 0.1)";

const boxProps = {
  //    bgcolor: 'background.paper',
  bgcolor: "rgba(255, 175, 0, 0.6)",
  m: 1,
  border: 1,
  padding: "5px",
  margin: 0,
  style: { width: "100%", height: "100%" },

  //style: { width: '5rem', height: '5rem' },
};

const spanValueStyle = { color: "blue" };
const spanCalcValueStyle = { color: "blue", width: "50%" };


export default function TaskItem(props) {
  const classes = useStyles();
  const { task, index } = props;
  const isDragDisabled = task.taskID === "0" || task.taskID === "9999";

  const displayDeleteTaskButton = task.predecessor[0] === "0" && task.successor[0] === "9999" ? true : false;
  const displayEditTaskButton = task.taskID === "0" || task.taskID === "9999" ? false : true;

  //const dependencyData = props.getDependencyData("P", task.taskID);

  const getTaskTime = (minutes) => {

    //console.log(moment(props.startDate).add(minutes, "minutes"));
    if(!props.startDate || (task.taskEST === 0 && task.taskEFT === 0)) {
      return "";
    }

    return AddMinutesToTaskTimeAndFormat(props.startDate, minutes);
  }
  
  const handleDependencyDelete = (dependencyData, dependencyType) => {
    props.deleteDependencyRelation(props.task.taskID, dependencyType, dependencyData.taskID);
    return;
  };

  const handleDeleteTask = (taskID) => {
    props.deleteTask(taskID);
  };

  //console.log("depS_P", props.successorDependencyData, props.predecessorDependencyData);

  return (
    <Draggable draggableId={task.taskID} key={task.taskID + "drag"} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          className={classes.root}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          isDragDisabled={isDragDisabled}
        >
          <Paper elevation={3} className={classes.paper}>
            <Grid container>
              {props.showPredecessor ? (
                <Grid item xs={12} sm={3}>
                  <Box borderColor={boxBorderColor} {...boxProps}>
                    {/* <Typography variant="subtitle1">Column 1</Typography> */}
                    <DependencyArray
                      key={task.taskID + "-1"}
                      taskID={task.taskID}
                      dependencyType={"P"}
                      dependencyData={props.predecessorDependencyData}
                      handleDependencyDelete={handleDependencyDelete}
                    />
                  </Box>
                </Grid>
              ) : null}
              <Grid item xs={12} sm>
                <Box borderColor={boxBorderColor} {...boxProps}>
                  <div>
                    <Typography variant="subtitle1">
                      {"ID: " + task.taskID + " => "} <span style={spanValueStyle}>{task.name}</span>
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div>
                      {/* {"ID: " + task.taskID + " => "} <span style={{ color: "blue" }}>{task.name}</span> */}
                      {task.taskID !== "0" && task.taskID !== "9999" ? (
                        <Typography variant="subtitle1">
                          Duration: <span style={spanValueStyle}>{convertToDaysHoursMinutes(task.duration)}</span>
                        </Typography>
                      ) : null}
                    </div>

                    <div style={{ display: "flex" }}>
                      {displayEditTaskButton ? (
                        <div>
                          <Tooltip title="Task Info" aria-label="info-template">
                            <IconButton aria-label="info" size="small" onClick={() => props.displayTaskInfo(task)}>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : null}

                      {displayEditTaskButton ? (
                        <div>
                          <Tooltip title="Edit Task" aria-label="edit-template">
                            <IconButton aria-label="edit" size="small" onClick={() => props.fillTaskDataForEdit(task)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : null}

                      {displayDeleteTaskButton ? (
                        <div>
                          <Tooltip title="Delete Task" aria-label="delete-template">
                            <IconButton aria-label="delete" size="small" onClick={() => props.deleteTask(task.taskID)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    {props.showCalcs && task.taskID !== "0" && task.taskID !== "9999" ? (
                      <React.Fragment>
                        <Divider style={{ marginBottom: "5px", marginTop: "5px" }} />
                        <Typography variant="subtitle1">
                          <div style={{ display: "flex" }}>
                            <div style={{ flex: 1 }}>
                              EST: <span style={spanValueStyle}>{getTaskTime(task.taskEST)} </span>
                            </div>
                            <div style={{ flex: 1 }}>
                              EFT: <span style={spanValueStyle}>{getTaskTime(task.taskEFT)} </span>
                            </div>
                          </div>
                        </Typography>
                        <Typography variant="subtitle1">
                          <div style={{ display: "flex" }}>
                            <div style={{ flex: 1 }}>
                              LST: <span style={spanValueStyle}>{getTaskTime(task.taskLST)} </span>
                            </div>
                            <div style={{ flex: 1 }}>
                              LFT: <span style={spanValueStyle}>{getTaskTime(task.taskLFT)}</span>
                            </div>
                          </div>
                        </Typography>
                      </React.Fragment>
                    ) : null}
                  </div>
                </Box>
              </Grid>
              {props.showSuccessor ? (
                <Grid item xs={12} sm={3}>
                  <Box borderColor={boxBorderColor} {...boxProps}>
                    {/* <Typography variant="subtitle1">Column 2</Typography> */}
                    <DependencyArray
                      key={task.taskID + "-2"}
                      taskID={task.taskID}
                      dependencyType={"S"}
                      dependencyData={props.successorDependencyData}
                      handleDependencyDelete={handleDependencyDelete}
                    />
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </div>
      )}
    </Draggable>
  );
}
