import React, { Component, forwardRef, useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MaterialTable, { MTableToolbar } from "material-table";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//import axios from "axios";

import * as axiosAPI from "../utils/getSetAPIData";
import tableIcons from "../utils/materialTableIcons";
import * as materialTableDefaults from "../utils/materialTableDefaults";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Redirect } from "react-router-dom";

//const styles = theme => ({
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "95%",
      width: "95%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
      maxWidth: "90%",
      width: "90%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
    padding: 0,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
}));

let currentSearch = "";
let currentPageNo = 0;
let currentData = [];
let currentTotalCount = 0;
let respData = {};
let useInitialQuery = true;
let subscriptionUsers = 0,
  activeUsers = 0;

//class FormikInputForm extends Component {
function UserList(props) {
  const classes = useStyles();
  const theme = useTheme();
  //const [alertOpen, setAlertOpen] = useState({ open: false, message: " ", severity: "error" });
  const { userId } = useSelector((state) => state.auth, shallowEqual);  
  const [serverError, setServerError] = useState({ isError: false, message: " ", type: "SERVER", statusCode: 0 });
  const [licenseDetail, setLicenseDetail] = useState({ subscriptionUsers: 0, activeUsers: 0 });
  //const [useInitialQuery, setUseInitialQuery] = useState(true);

  const isSMUp = useMediaQuery(theme.breakpoints.up("sm"));
  let tableRef = React.useRef();

  useEffect(() => {
    (async () => {
      const dataUrl = "/api/v1/users/checkSubscriptionUsers";

      //          respData = await axiosAPI.getSetAxiosData("get", dataUrl, { userID: isNew ? null : stateData._id });
      respData = await axiosAPI.getSetAxiosData("get", dataUrl);

      if (respData.status === "success") {
        const { subscriptionUsers, activeUsers } = respData.data.data.data;
        setLicenseDetail({ subscriptionUsers, activeUsers });
      } else {
        toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
        return;
      }
    })();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      //console.log("tableref", tableRef);
      tableRef.current.onQueryChange();
    }
  }, [isSMUp]);

  //  useEffect(() => {
  //console.log("tableref", tableRef);
  //  }, [tableRef]);

  //  console.log("PAGE RENDERED");

  swal2.setTheme(theme);

  let tableColumns = {};
  if (isSMUp) {
    tableColumns = [
      { title: "User Name", field: "name" }, // width: 500, minWidth: 500 },
      { title: "Email", field: "email" },
      { title: "Role", field: "role" },
      { title: "Customer", field: "customer.name" },
      {
        title: "Active",
        field: "active",
        render: (rowData) => rowData.active ? "Yes" : "No",
      },      
      //{ title: "Doğum Yılı", field: "birthYear", type: "numeric" },
      /*       {
        title: "Doğum Yeri",
        field: "birthCity",
        lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      },
 */
    ];
  } else {
    tableColumns = [
      { title: "User Name", field: "name" },
      //{ title: "Soyadı", field: "surname" },
      //{ title: "Doğum Yılı", field: "birthYear", type: "numeric" },
      //{ title: "Email", field: "email" }
    ];
  }

  const detailPanel = (rowData) => {
    const fieldArray = [
      ["Email :", "email"],
      ["Role :", "role"],
      ["Customer :", "customer"],
      ["Active :", "active"],
    ];

    return (
      <div style={{ marginTop: "15px", marginBottom: "15px" }}>
        {fieldArray.map((tableField) => {
          return (
            <Grid container spacing={1} alignItems="center" flex={1}>
              <Grid item xs={4} style={{ paddingLeft: "10px" }}>
                <Typography variant="subtitle2" component="span" color="primary">
                  {tableField[0]}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" component="span">
                {tableField[1] === "customer" ? (rowData.customer ? rowData.customer.name : null) : 
                tableField[1] === "active" ? (rowData.active ? "Yes" : "No") : rowData[tableField[1]]}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  };

  const title = "Users";

  const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const getQueryData = (query) => {
    respData = {};

    return new Promise(async (resolve, reject) => {
      //console.log(query, tableRef.current);
      let searchQueryString = "";
      /*       if (useInitialQuery) {
        query.page = 1;
      }
 */
      if (currentSearch !== query.search) {
        //alert("Search Changed");
        const lastCurrentSearch = currentSearch;
        currentSearch = query.search;
        if (query.search.length < 3) {
          if (lastCurrentSearch.length >= 3) {
            searchQueryString = "";
          } else {
            resolve({
              data: currentData,
              page: currentPageNo,
              totalCount: currentTotalCount,
            });
            return;
          }
        }
      }

      if (currentSearch.length >= 3) {
        searchQueryString = "&searchField=name&searchValue=" + query.search;
      }

      const dataUrl =
        "/api/v1/users?sort=name&page=" + (query.page * 1 + 1) + "&limit=" + query.pageSize + searchQueryString;

      respData = await axiosAPI.getSetAxiosData("get", dataUrl);

      //      console.log("axiosData", respData, dataUrl);

      if (respData.status === "success") {
        currentData = respData.data.data.data;
        currentPageNo = currentData.length > 0 ? respData.data.page - 1 : 0;
        currentTotalCount = currentData.length > 0 ? respData.data.totalCount : 0;
        return resolve({
          data: currentData,
          page: currentPageNo,
          totalCount: currentTotalCount, //testdata.length,
        });
      } else {
        resolve({
          data: [],
          page: 0,
          totalCount: 0,
        });
        setServerError({
          isError: true,
          message: respData.message,
          statusCode: respData.statusCode ? respData.statusCode : 0,
        });
        //setAlertOpen({ open: true, message: respData.message, severity: "error" });
        //console.log("server error", respData);
      }

      /*       axios
        .get(dataUrl)
        .then((response) => {
          //console.log("Template Load", response.data.data.data);
          //console.log(response.data.data.totalCount);

          currentPageNo = query.page;
          currentData = response.data.data.data;
          currentTotalCount = response.data.totalCount;
          resolve({
            data: response.data.data.data,
            page: currentPageNo,
            totalCount: response.data.totalCount, //testdata.length,
          });
        })
        .catch((error) => {
          console.log("Template Load Error", error.response.data, error.response.status, error.response.data.message);
          //templateNameRef.current.error = true;
          //templateNameRef.current.helperText = "Not FOOOOND";
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
        });
 */
    });
  };

  const tableClickActions = {
    onClickAdd: async (event) => {

      if (licenseDetail.activeUsers >= licenseDetail.subscriptionUsers) {
        toastMessage.DisplayToast("Active Users should not Greater than Subscription Users", {
          type: toastMessage.messageType.ERROR,
        });
        return;
      }

      const dataUrl = "/api/v1/users/checkSubscriptionUsers";

      //          respData = await axiosAPI.getSetAxiosData("get", dataUrl, { userID: isNew ? null : stateData._id });
      respData = await axiosAPI.getSetAxiosData("get", dataUrl);

      if (respData.status === "success") {
        const { subscriptionUsers, activeUsers } = respData.data.data.data;

        if (activeUsers >= subscriptionUsers) {
          toastMessage.DisplayToast("Active Users should not Greater than Subscription Users", {
            type: toastMessage.messageType.ERROR,
          });

          if (subscriptionUsers !== licenseDetail.subscriptionUsers || activeUsers !== licenseDetail.activeUsers) {
            setLicenseDetail({ subscriptionUsers, activeUsers });
          }

          return;
        }
      } else {
        toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
        return;
      }

      props.history.push("/userForm", { isNew: true });
    },
    onClickEdit: (event, rowData) => {
      const { tableData, ...stateData } = rowData;
      props.history.replace("/userForm", { isNew: false, stateData });
    },
    onClickDelete: async (event, rowData) => {
      //alert("You want to delete " + rowData.name);
      //setAlertOpen({ open: true, message: "Record Deleted", severity: "success" });
      //toastMessage.DisplayToast("Record Deleted", { type: toastMessage.messageType.SUCCESS });

      //const ss = await swal2.confirmationDialog("AMir Zulfiqar Deleted", "Confirm Deletion",  { type: 'danger' });

      //console.log("sweet result", ss);
      if (rowData._id === userId) {
        toastMessage.DisplayToast("Cannot delete this user", {
          type: toastMessage.messageType.ERROR,
        });
        return;
      }

      const lastQuery = tableRef.current.state.query;
      //console.log("last query bef delete",lastQuery);

      const confirm = await swal2.confirmationDialog("Are you sure to Delete User", "Confirm Deletion", { type: "danger" });

      if (confirm.status === ":OK") {
        const dataUrl = "/api/v1/users/" + rowData._id;

        const respData = await axiosAPI.getSetAxiosData("delete", dataUrl);

        if (respData.status === "success") {
          toastMessage.DisplayToast("User Deleted...", { type: toastMessage.messageType.SUCCESS });

          setTimeout(function () {
            tableRef.current.onQueryChange(lastQuery);
          }, 300);
        } else {
          toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
        }
      }
    },
  };

  //console.log("currdata", currentData) ;
  //  alert("render");
  return (
    <React.Fragment>
      {/* <ToastMessages alertOpen={alertOpen} setAlertOpen={setAlertOpen} /> */}
      {/* <ToastContainer /> */}

      <div className={classes.container}>
        <div className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                <span style={{ color: theme.sidedrawer.subListTextColor }}>{"Total Licenses: "}</span>
                <span style={{ paddingLeft: "5px" }}>{licenseDetail.subscriptionUsers}</span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                <span style={{ color: theme.sidedrawer.subListTextColor }}>{"Used: "}</span>
                <span style={{ paddingLeft: "5px" }}>{licenseDetail.activeUsers}</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {serverError.isError ? (
                <div style={{ width: "100%" }}>
                  <h1 style={{ textAlign: "center" }}>
                    {serverError.statusCode === "403" ? "UNAUTHORIZED" : "SERVER ERROR"}
                  </h1>
                  <h2 style={{ textAlign: "center" }}>{serverError.message}</h2>
                </div>
              ) : (
                <MaterialTable
                  tableRef={tableRef}
                  components={{
                    Toolbar: (props) => (
                      <React.Fragment>
                        {!isSMUp ? (
                          <div style={{ width: "100%", marginTop: "15px" }}>
                            <Typography variant="h6" color="primary" align="center" gutterBottom>
                              {" "}
                              {title}{" "}
                            </Typography>
                          </div>
                        ) : null}

                        <div style={{ color: theme.sidedrawer.subListTextColor }}>
                          <MTableToolbar {...props} />
                        </div>
                      </React.Fragment>
                    ),
                  }}
                  style={{ width: "100%" }}
                  columns={tableColumns}
                  //data={[{ name: "Mehmet", surname: "Baran", birthYear: 1987, birthCity: 63 }]}
                  data={getQueryData}
                  title={isSMUp ? title : ""}
                  icons={tableIcons}
                  {...(!isSMUp && { detailPanel: detailPanel })}
                  {...(!isSMUp && { onRowClick: (event, rowData, togglePanel) => togglePanel() })}
                  actions={materialTableDefaults.mtActions(props, tableIcons, { tableClickActions }, "User")}
                  options={{
                    sorting: false,
                    detailPanelType: "single",
                    actionsColumnIndex: -1,
                    actionsCellStyle: {
                      //backgroundColor: "#ffccdd",
                      color: theme.sidedrawer.subListTextColor, // "rgba(255, 175, 0, 0.6)"
                    },
                    headerStyle: {
                      backgroundColor: "#01579b", //'#e8eaf5',
                      color: "#FFF",
                    },
                  }}
                  localization={{
                    pagination: {
                      labelDisplayedRows: "{from}-{to} of {count}",
                    },
                  }}
                />
              )}
            </Grid>
          </Grid>
          {/* </Hidden> */}
        </div>
        {/* </Paper> */}
      </div>
    </React.Fragment>
  );
}

export default UserList;
