import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from "@material-ui/icons/Done";
import Chip from "@material-ui/core/Chip";

import DependencyArray from "./task-dependencyExec";

import { Draggable } from "react-beautiful-dnd";

import { AddMinutesToTaskTimeAndFormat, convertToDaysHoursMinutes, formatTaskDateTime } from "shared/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginBottom: theme.spacing.unit * 1,
  },
  paper: {
    padding: 0, //theme.spacing(2),
    margin: "auto",
    maxWidth: "100%",
    minWidth: "100%",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const boxBorderColor = "rgba(0, 0, 255, 0.1)";

const boxProps = {
  //    bgcolor: 'background.paper',
  bgcolor: "rgba(255, 175, 0, 0.6)",
  m: 1,
  border: 1,
  padding: "5px",
  margin: 0,
  style: { width: "100%", height: "100%" },

  //style: { width: '5rem', height: '5rem' },
};

const spanValueStyle = { color: "blue" };
const spanCalcValueStyle = { color: "blue", width: "50%" };

const canStartTask1 = (task, taskPredecessor) => {
  //console.log(task.completedAt ? true : false);
  if (task.taskID === "0" || task.taskID === "9999" || task.startedAt) return false;

  const res = taskPredecessor.find((pTask) => pTask.taskID !== "0" && !pTask.taskData.completedAt);
  //console.log(res);
  if (res) return false;

  return true;
};

const canEndTask1 = (task, taskPredecessor) => {
  //console.log(task.completedAt ? true : false);
  if (task.taskID === "0" || task.taskID === "9999" || !task.startedAt || task.completedAt) return false;

  return true;
};

export default function TaskItem(props) {
  const classes = useStyles();
  const { task, index, currentUser } = props;
  const isDragDisabled = task.taskID === "0" || task.taskID === "9999";

  const displayInfoButton = task.taskID === "0" || task.taskID === "9999" ? false : true;

  const displayStartTaskButton = !props.isViewer && props.canStartTask(task, props.predecessorDependencyData);
  const displayEndTaskButton = !props.isViewer && props.canEndTask(task, props.predecessorDependencyData);

  //const dependencyData = props.getDependencyData("P", task.taskID);

  const getTaskTime = (minutes) => {
    //console.log(moment(props.startDate).add(minutes, "minutes"));
    if (!props.startDate || (task.taskEST === 0 && task.taskEFT === 0)) {
      return "";
    }

    return AddMinutesToTaskTimeAndFormat(props.startDate, minutes);
  };

  const handleDependencyDelete = (dependencyData, dependencyType) => {
    props.deleteDependencyRelation(props.task.taskID, dependencyType, dependencyData.taskID);
    return;
  };

  //console.log("curr user", currentUser, task)
  const diffUserJSX = (task) => {
    if (task.startedAt && (task.startedUser._id !== currentUser.id || props.isViewer)) {
      return <Chip size="small" color="primary" label={task.startedUser.name} style={{ marginRight: "5px" }}/>;
    }

    return null;
  };

  const statusJSX = (task) => {
    if (task.completedAt) {

      //const lateMinutes = moment(task.completedAt).diff(props.startDate, "minutes") - (task.taskLFT + 0);
      if(task.taskInfo.lateMinutes > 0) {
        return <Chip size="small" icon={<DoneIcon style={{color: "white"}} />} label={"Done (" + task.taskInfo.lateMinutes + " min)"} style={{backgroundColor: "red", color: "white"}} />;
      } else {
        return <Chip size="small" icon={<DoneIcon style={{color: "white"}} />} label="Done" style={{backgroundColor: "green", color: "white"}} />;
      }
    }

    return null;
  };
  //console.log("depS_P", props.successorDependencyData, props.predecessorDependencyData);

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.paper}>
        <Grid container>
          {props.showPredecessor ? (
            <Grid item xs={12} sm={3}>
              <Box borderColor={boxBorderColor} {...boxProps}>
                {/* <Typography variant="subtitle1">Column 1</Typography> */}
                <DependencyArray
                  key={task.taskID + "-1"}
                  taskID={task.taskID}
                  dependencyType={"P"}
                  dependencyData={props.predecessorDependencyData}
                  handleDependencyDelete={handleDependencyDelete}
                />
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12} sm>
            <Box borderColor={boxBorderColor} {...boxProps}>
              <div>
                <Typography variant="subtitle1">
                  {"ID: " + task.taskID + " => "} <span style={spanValueStyle}>{task.name}</span>
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div>
                  {/* {"ID: " + task.taskID + " => "} <span style={{ color: "blue" }}>{task.name}</span> */}
                  {task.taskID !== "0" && task.taskID !== "9999" ? (
                    <Typography variant="subtitle1">
                      Duration: <span style={spanValueStyle}>{convertToDaysHoursMinutes(task.duration)}</span>
                    </Typography>
                  ) : null}
                </div>

                <div style={{ display: "flex" }}>
                  <div>
                    {displayInfoButton ? (
                      <Tooltip title="Task Info" aria-label="info-template">
                        <IconButton aria-label="info" size="small" onClick={() => props.displayTaskInfo(task)}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    {displayStartTaskButton ? (
                      <Button
                        variant="contained"
                        //size="small"
                        //color="primary"
                        onClick={() => props.updateTaskExecutionData("startTask", task)}
                        style={{ backgroundColor: "white", color: "black", textTransform: "none", maxHeight: "22px" }}
                      >
                        Start
                      </Button>
                    ) : null}
                    {displayEndTaskButton && task.startedUser._id === currentUser.id ? (
                      <Button
                        variant="contained"
                        //size="small"
                        //color="secondary"
                        onClick={() => props.updateTaskExecutionData("endTask", task)}
                        style={{ backgroundColor: "black", color: "white", textTransform: "none", maxHeight: "22px" }}
                      >
                        End
                      </Button>
                    ) : null}

                    {diffUserJSX(task)}

                    {statusJSX(task)}
                  </div>
                </div>
              </div>
              {displayInfoButton ? (
                <div>
                  <Typography variant="subtitle1">
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        Start Time:{" "}
                        <span style={spanValueStyle}>{task.startedAt ? formatTaskDateTime(task.startedAt) : ""} </span>
                      </div>
                      <div style={{ flex: 1 }}>
                        End Time:{" "}
                        <span style={spanValueStyle}>{task.completedAt ? formatTaskDateTime(task.completedAt) : ""} </span>
                      </div>
                    </div>
                  </Typography>
                </div>
              ) : null}

              <div>
                {props.showCalcs && task.taskID !== "0" && task.taskID !== "9999" ? (
                  <React.Fragment>
                    <Divider style={{ marginBottom: "5px", marginTop: "5px" }} />
                    <Typography variant="subtitle1">
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                          EST: <span style={spanValueStyle}>{getTaskTime(task.taskEST)} </span>
                        </div>
                        <div style={{ flex: 1 }}>
                          EFT: <span style={spanValueStyle}>{getTaskTime(task.taskEFT)} </span>
                        </div>
                      </div>
                    </Typography>
                    <Typography variant="subtitle1">
                      <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                          LST: <span style={spanValueStyle}>{getTaskTime(task.taskLST)} </span>
                        </div>
                        <div style={{ flex: 1 }}>
                          LFT: <span style={spanValueStyle}>{getTaskTime(task.taskLFT)}</span>
                        </div>
                      </div>
                    </Typography>
                  </React.Fragment>
                ) : null}
              </div>
            </Box>
          </Grid>
          {props.showSuccessor ? (
            <Grid item xs={12} sm={3}>
              <Box borderColor={boxBorderColor} {...boxProps}>
                {/* <Typography variant="subtitle1">Column 2</Typography> */}
                <DependencyArray
                  key={task.taskID + "-2"}
                  taskID={task.taskID}
                  dependencyType={"S"}
                  dependencyData={props.successorDependencyData}
                  handleDependencyDelete={handleDependencyDelete}
                />
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    </div>
  );
}
