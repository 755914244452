import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const useStyles = makeStyles((theme) => createStyles({  muiTheme: {
    primaryColor: `${theme.palette.primary.main}`
  }
}));

//const classes = useStyles();

let muiTheme;

const MySwal = withReactContent(Swal);

export const setTheme = (theme) => {
  muiTheme = theme;
}

const confirmBoxSwal = MySwal.mixin({
  position: "top",
  //  showConfirmButton: false,
});
export const testConfirm = async (message) => {
  const result = await confirmBoxSwal.fire({
    /*     customClass: {
      container: "my-swal2",
    },
 */
    title: <p>Hello World</p>,
    text: message,
    onOpen: () => {
      // `MySwal` is a subclass of `Swal`
      //   with all the same instance & static methods
      //MySwal.clickConfirm()
    },
  });

  return result;
};

export const confirmationDialog = async (message, title = "", options = {}) => {
  const messageOptions = {
    text: message,
  };



  if (title.trim().length > 0) messageOptions.title = title;

  if (options.type === 'danger')
    messageOptions.confirmButtonColor = 'darkRed';
  else
    messageOptions.confirmButtonColor = muiTheme.palette.primary.main;


  const result = await confirmBoxSwal.fire({
    ...messageOptions,
    showCancelButton: true,
  //  confirmButtonColor:  muiTheme.palette.primary.main, //muiTheme.palette.error.main,
    cancelButtonColor: muiTheme.palette.primary.main,
    confirmButtonText: "OK",
    focusCancel: true,
  });

  const returnResult = { status: ":CANCEL", value: "", reason: "" };
  if (result.isConfirmed) {
    returnResult.status = ":OK";
  } else {
    returnResult.reason = result.dismiss;
  }

  return returnResult;
};
