let moment = require("moment-timezone");

export const timeZoneList = moment.tz
  .names()
  .map((tzName, index) => {
    //const timezoneAtBeginningOfYear = moment.tz('2018-01-01', name)
    const currentTimtZone = moment.tz(tzName);

    return {
      utc: currentTimtZone.utcOffset(),
      name: `(GMT${currentTimtZone.format("Z")}) ${tzName}`,
      timeZone: tzName,
    };
  })
  .sort((a, b) => a.utc - b.utc);

//console.log(stateData.startDateTime);
/*         const convStartDate = moment(moment(stateData.startDateTime).format("YYYY-MM-DD HH:mm"))
          .tz(branchData.timeZone)
          .format("YYYY-MM-DD HH:mm ZZ");
        const convEndDate = moment(moment(stateData.endDateTime).format("YYYY-MM-DD HH:mm"))
          .tz(branchData.timeZone)
          .format("YYYY-MM-DD HH:mm ZZ");
 */
//console.log("conv date", convStartDate);

export const convertSpecificTZtoLocalKeepTime = (date, timeZone) => {
  const convDate = moment(date).tz(timeZone);

  return convDate.tz(moment.tz.guess(), true).toDate();
};

//food      const convStartDate = moment.utc(moment(data.startDate).format("l LT")).tz(data.branch.timeZone, true).toDate();
//      const convEndDate = moment.utc(moment(data.endDate).format("l LT")).tz(data.branch.timeZone, true).toDate();

//      .tz(data.branch.timeZone)
//.format("YYYY-MM-DDTHH:mm:ssZ");
//.tz(moment.tz.guess())

export const convertSpecificTZtoLocal = (date, timeZone) => {

  return moment(date).tz(timeZone, true).toDate();

};
