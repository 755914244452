import axios from "axios";

export const getSetAxiosData = async (restType, url, data, options = {}) => {
  let response = null;
  const returnResponse = { status: "error", statusCode: -1, message: "Some Thing Went Wrong..", data: {} };

  try {
    if (data === {} || !data) {
      response = await axios({ method: restType, url });
    } else {
      response = await axios({ method: restType, url, data });
    }
    getResponseData(returnResponse, response);
  } catch (error) {
    if (error.response) {
      getResponseData(returnResponse, error.response, true);
    } else {
      if (error.message) {
        returnResponse.message = error.message;
      }
    }
  }

  return returnResponse;

};

const getResponseData = (returnResponse, response, isCatchError = false) => {
  //const returnResponse = { status: "error", statusCode: -1, message: "Some Thing Went Wrong..", data: {} };

  if(!response)
    return;

  if (response.data) {
    const respData = response.data;
    returnResponse.data = respData;

    if (respData.status) {
      returnResponse.status = respData.status;
      if (respData.status === "error" || respData.status === "fail") {
        returnResponse.message = respData.message;
        if (respData.error) {
          returnResponse.statusCode = respData.error.statusCode;
        }
      } else if (respData.status === "success") {
        returnResponse.message = " ";
        returnResponse.statusCode = 0;
      }
    }
  } else if (isCatchError === true) {
    //console.log(response);
  }

  return returnResponse;
};
