import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import useSWR, { mutate } from "swr";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";

import { KeyboardDateTimePicker } from "@material-ui/pickers";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import FormDialog from "components/UI/dialog-form";
import TaskForm from "components/checkList/addAmmendTask";
//import AddCheckListDataForm from "./addCheckListUserEtc";

import TaskItem from "./task-item-checkListExec";

import { Typography } from "@material-ui/core";

import * as chkListManager from "shared/checkList-manager";
import * as chkListCalculation from "shared/checkList-calculation";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as axiosAPI from "../utils/getSetAPIData";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import { useWidth, convertToDaysHoursMinutes } from "shared/utility";
import * as tzUtils from "shared/timeZonesUtils";
import moment from "moment";
//import momenttz from "moment-timezone";

// import { DisplayFormikState } from './formikHelper';

const drawerWidth = 330;

const useStyles = makeStyles((theme) => ({
  textField123: {
    color: "red",
  },
  paper: {
    display: "flex",
    flex: 1,
    //overflowY: "scroll",
    marginLeft: 0,
    marginRight: 0,
    marginTop: "0px", //theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 1}px`,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      width: "100%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100%",
      width: "100%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: `calc(100% - ${drawerWidth}px)`,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    //flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
}));

const startTaskID = chkListManager.startTaskID;
const endTaskID = chkListManager.endTaskID;
let taskFormData = { task: {} };
let addCheckListData = {};
let currrentActivityList = new chkListCalculation.ActivityList();
let criticalPathData;
let executionData = {};
const dateFormat = "MMM D, YYYY h:mm A";

const getIntitialData = () => {
  return chkListManager.getIntitialData(true);
};

let stateData = {};
let formTitle = "Add Check List";
let isNew = true;
let initialValues = {};
let deletedIDs = [];
let isViewer = false;

const swrKey = "/api/v1/checkLists";

const validationSchema = Yup.object({
  name: Yup.string("Enter a name"),
  customer: Yup.string("Enter Customer"),
  branch: Yup.string("Enter Branch"),
  startDate: Yup.string("checkList Date"),
  endDate: Yup.string("checkList Date"),
});

const JobExecutionForm = (props) => {
  const [taskData, setTaskData] = useState([]);

  const [showPredecessor, setShowPredecessor] = useState(true);
  const [criticalPathJSX, setCriticalPathJSX] = useState({ jsxData: " ", duration: 0 });
  const [showSuccessor, setShowSuccessor] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [initialValues1, setInitialValues1] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showCalcs, setShowCalcs] = useState(false);
  const [isJobCompleted, setIsJobCompleted] = useState(false);
  const [checkListUsers, setCheckListUsers] = useState([]);
  const [mobMenuAnchorEl, setMobMenuAnchorEl] = React.useState(null);
  const [startSWRFetch, setStartSWRFetch] = React.useState(false);
  const [showActiveTasks, setShowActiveTasks] = useState(false);

  const open = Boolean(mobMenuAnchorEl);

  const authData = useSelector((state) => state.auth, shallowEqual);
  const currentUserID = authData.userId;
  const currentUserEmail = authData.email;
  const currentUserRole = authData.userRole;
  const jobExecutionPollingIntervel = authData.otherInfo.jobExecutionPollingIntervel;

  const theme = useTheme();
  const classes = useStyles();

  swal2.setTheme(theme);
  const currWidth = useWidth();
  const isSMUp = currWidth !== "sm" && currWidth !== "xs" ? true : false; //    useMediaQuery(theme.breakpoints.up("sm"));

  const handleMobileMenuClose = () => {
    setMobMenuAnchorEl(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  const isActiveTask = (task) => {
    if (task.taskID === "0" || task.taskID === "9999" || !showActiveTasks) return true;

    if (task.completedAt) return true;

    if (canStartTask(task, getDependencyData("P", task.taskID))) return true;

    if (canEndTask(task)) return true;

    return false;
  };

  const canStartTask = (task, taskPredecessor) => {
    //console.log(task.completedAt ? true : false);
    if (task.taskID === "0" || task.taskID === "9999" || task.startedAt) return false;

    const res = taskPredecessor.find((pTask) => pTask.taskID !== "0" && !pTask.taskData.completedAt);
    //console.log(res);
    if (res) return false;

    return true;
  };

  const canEndTask = (task, taskPredecessor) => {
    //console.log(task.completedAt ? true : false);
    if (task.taskID === "0" || task.taskID === "9999" || !task.startedAt || task.completedAt) return false;

    return true;
  };

  const getRemainingTimeJSX = (stateData, isJobCompleted) => {
    if (isJobCompleted) {
      return (
        <Typography variant="h5" style={{ marginRight: "10px" }}>
          <span style={{ color: "green" }}>Completed</span>
        </Typography>
      );
    }

    const startTime = moment(stateData.checkListStartTime);
    const endTime = moment();
    const timeRemainingInMinutes = stateData.otherInfo.criticalPathDuration - endTime.diff(startTime, "minutes"); // - 60;
    //console.log(endTime.diff(startTime, "minutes"), stateData.otherInfo.criticalPathDuration, endTime.diff(startTime, "minutes") - stateData.otherInfo.criticalPathDuration)

    let timeStatus = "Times Up";
    let statusStyle = { paddingLeft: "5px", color: "red" };

    if (timeRemainingInMinutes > 0) {
      timeStatus = convertToDaysHoursMinutes(timeRemainingInMinutes);
      statusStyle = { paddingLeft: "5px" };
    } else if (timeRemainingInMinutes < 0) {
      timeStatus = "- " + convertToDaysHoursMinutes(Math.abs(timeRemainingInMinutes));
    }

    return (
      <Typography variant="h6" style={{ marginRight: "10px" }}>
        <span style={{ color: theme.sidedrawer.subListTextColor }}>{"Time Left: "}</span>
        <span style={statusStyle}>{timeStatus}</span>
      </Typography>
    );
  };

  const updateTaskExecutionData = async (updateType, task) => {
    const confirm = await swal2.confirmationDialog("Are you sure to Continue?");

    if (confirm.status === ":OK") {
      executionData = { updateType: updateType, masterID: stateData._id, transID: task ? task._id : null, updateData: {} };

      const dataUrl = "/api/v1/checkLists/updateExecutionData";

      const respData = await axiosAPI.getSetAxiosData("post", dataUrl, executionData);

      if (respData.status === "success") {
        //console.log("resp", respData.data.data);

        if (updateType === "endJob") {
          setIsJobCompleted(true);
          setStartSWRFetch(false);
          toastMessage.DisplayToast("Job Completed...", { type: toastMessage.messageType.SUCCESS });
        } else {
          toastMessage.DisplayToast("Task Data Updated...", { type: toastMessage.messageType.SUCCESS });
        }

        mutate(swrKey);
      } else {
        toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
      }
    }
  };

  const fetchExecutionData = async () => {
    //console.log("fetching " + Date.now().toString());

    const dataUrl = "/api/v1/checkLists/" + stateData._id;

    const respData = await axiosAPI.getSetAxiosData("get", dataUrl);

    if (respData.status === "success") {
      //      setTaskData(respData.data.data.data);

      return respData.data.data.data;
    } else {
      toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
      return [];
    }
  };

  useEffect(() => {
    //    setAutoCompleteKey(Math.random());
    (async () => {
      initialValues = {
        name: "",
        branch: null,
        customer: null,
        startDate: null,
        //endDate: null,
      };

      //checkListUsers=[];
      formTitle = "Job Execution";
      isNew = true;
      isViewer = false;
      stateData = {};
      let users = [];
      let dataUrl;

      if (props.location.state && props.location.state.stateData) {
        if (props.location.state.isViewer) {
          isViewer = true;
          formTitle = "View Job Execution";

          dataUrl = "/api/v1/checkLists/masterData/" + props.location.state.stateData._id;

          const respData = await axiosAPI.getSetAxiosData("get", dataUrl);

          if (respData.status === "success") {
            //toastMessage.DisplayToast("Job Started", { type: toastMessage.messageType.SUCCESS, });
            stateData = respData.data.data.data;
            //console.log("state dataaaa", stateData);
          } else {
            toastMessage.DisplayToast("Check List Not found", { type: toastMessage.messageType.ERROR });
            props.history.push("/jobDetail", {
              isNew: false,
              stateData: props.location.state.jobQueryParams,
              isViewer,
            });
          }
        } else {
          stateData = props.location.state.stateData;
        }
        isNew = false;

        //        console.log(stateData);

        //        setIsLoading(true);

        initialValues.name = stateData.name;

        initialValues.branch = stateData.branch.name;
        initialValues.customer = stateData.customer.name;

        initialValues.startDate = moment(stateData.startDateTime).format(dateFormat);
        initialValues.endDate = moment(stateData.endDateTime).format(dateFormat);

        if (!stateData.startedAt) {
          dataUrl = "/api/v1/checkLists/updateExecutionData";

          executionData = { updateType: "startJob", masterID: stateData._id, transID: null, updateData: {} };

          const respData = await axiosAPI.getSetAxiosData("post", dataUrl, executionData);

          if (respData.status === "success") {
            toastMessage.DisplayToast("Job Started", {
              type: toastMessage.messageType.SUCCESS,
            });
            stateData.startedAt = respData.data.data.startedAt;
          } else {
            toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
            props.history.replace("/jobExecutionList", { isNew: false });
          }
        }
      } else {
        //        setTaskData(getIntitialData());
        //        console.log("task data init", getIntitialData());
      }

      stateData.checkListStartTime = stateData.startDateTime;
      //stateData.checkListStartTime = stateData.startedAt;

      setInitialValues1(initialValues);
      setCheckListUsers(users);
      if (stateData.completedAt) {
        setIsJobCompleted(true);
        //        setIsLoading(false)
      }
      setStartSWRFetch(true);

      //setAutoCompleteKey(Math.random());
    })();
  }, []);

  useSWR(startSWRFetch ? swrKey : null, fetchExecutionData, {
    onFailure: (data, key, config) => {},
    onSuccess: (data, key, config) => {
      if (data.length > 0) {
        if (isLoading) setIsLoading(false);

        setTaskData(data);
        if (stateData.completedAt) {
          setStartSWRFetch(false);
        }
      }
    },
    refreshInterval: 1000 * jobExecutionPollingIntervel,
  });

  //let criticalPathJSX = "dddddd "

  const calculateCriticalPath = (openInfoDialog = false, calcOnly = false) => {
    criticalPathData = chkListCalculation.calculateCriticalPath(taskData);

    if (criticalPathData.error) {
      //console.log(criticalPathData);
      toastMessage.DisplayToast(criticalPathData.jsxData, { type: toastMessage.messageType.ERROR });
      setCriticalPathJSX({ error: true, jsxData: "", duration: 0 });
      return;
    }

    if (!calcOnly) {
      if (criticalPathData) {
        setCriticalPathJSX(criticalPathData);
      }

      if (openInfoDialog) {
        setInfoDialogOpen(true);
      }
    }

    return;
  };

  const openTaskFormDialog = () => {
    taskFormData = {
      isNew: true,
      task: {
        taskID: "",
      },
    };

    setDialogOpen(true);
    return;
  };

  const displayTaskInfo = (task) => {
    taskFormData = {
      displayOnly: true,
      isNew: false,
      isCheckList: true,
      startDate: values.startDate,
      task,
    };

    setDialogOpen(true);
    return;
  };

  const getDependencyData = (dependencyType, taskID) => {
    return chkListManager.getDependencyData(taskData, dependencyType, taskID, true);
  };

  const deleteDependencyRelation = (sourceTaskID, depedencyType, relatedTaskID) => {
    const newTaskData = chkListManager.deleteDependencyRelation(taskData, sourceTaskID, depedencyType, relatedTaskID);

    if (newTaskData) {
      setTaskData(newTaskData);
    }
  };

  const checkAndSubmitData = () => {
    if (checkListUsers.length === 0) {
      toastMessage.DisplayToast("No User Added", { type: toastMessage.messageType.ERROR });
      return;
    }

    calculateCriticalPath(false, true);

    if (criticalPathData.error) {
      //toastMessage.DisplayToast(criticalPathData.jsxData, { type: toastMessage.messageType.ERROR });
      //        actions.setSubmitting(false);
      return;
    }

    handleSubmit();
  };

  const criticalPathJSXDisplay = (
    <React.Fragment>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography variant="h6">
          Critical Path{" "}
          {criticalPathJSX.duration === 0 ? " " : "( " + convertToDaysHoursMinutes(criticalPathJSX.duration) + " )"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {criticalPathJSX.jsxData}
      </Grid>
    </React.Fragment>
  );

  const drawer = (
    <Grid container spacing={2}>
      <Grid item xs={12} align="center" style={{ marginTop: "15px" }}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setShowPredecessor(!showPredecessor)}
          style={{ textTransform: "none", marginRight: "5px" }}
        >
          Toggle Predecessor
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setShowSuccessor(!showSuccessor)}
          style={{ textTransform: "none" }}
        >
          Toggle Successors
        </Button>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "center", marginTop: "5px" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => calculateCriticalPath()}
          style={{ textTransform: "none", marginRight: "5px" }}
        >
          Show Critical Path
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setShowCalcs(!showCalcs)}
          style={{ textTransform: "none" }}
        >
          Toggle Calcs.
        </Button>
      </Grid>
      <Grid item xs={12} align="center" style={{ marginTop: "5px" }}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setShowActiveTasks(!showActiveTasks)}
          style={{ textTransform: "none", marginRight: "5px" }}
        >
          {showActiveTasks ? "Show All Tasks" : "Show Active Tasks"}
        </Button>
      </Grid>

      {criticalPathJSXDisplay}
    </Grid>
  );

  const mobileMenu = (
    <div>
      <Button
        aria-controls="fade-mob-menu"
        aria-haspopup="true"
        variant="contained"
        size={currWidth === "xs" ? "small" : "medium"}
        color="primary"
        onClick={(event) => setMobMenuAnchorEl(event.currentTarget)}
        style={{ textTransform: "none", marginRight: "5px" }}
        startIcon={<MenuIcon />}
      >
        Actions
      </Button>

      <Menu
        id="fade-mob-menu"
        anchorEl={mobMenuAnchorEl}
        keepMounted
        open={open}
        onClose={handleMobileMenuClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            setShowActiveTasks(!showActiveTasks);
            handleMobileMenuClose();
          }}
        >
          {showActiveTasks ? "Show All Tasks" : "Show Active Tasks"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowPredecessor(!showPredecessor);
            handleMobileMenuClose();
          }}
        >
          Toggle Predecessor
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowSuccessor(!showSuccessor);
            handleMobileMenuClose();
          }}
        >
          Toggle Successors
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowCalcs(!showCalcs);
            handleMobileMenuClose();
          }}
        >
          Toggle Calcs.
        </MenuItem>
      </Menu>
      <Button
        variant="contained"
        color="secondary"
        size={currWidth === "xs" ? "small" : "medium"}
        onClick={() => calculateCriticalPath(true)}
        style={{ textTransform: "none" }}
      >
        Show Critical Path
      </Button>
    </div>
  );

  const {
    handleSubmit,
    setFieldValue,
    errors,
    isSubmitting,
    isValid,
    touched,
    getFieldProps,
    setTouched,
    resetForm,
    dirty,
    setSubmitting,
    values,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (data, actions) => {
      if (data.branch.customer._id !== data.customer._id) {
        toastMessage.DisplayToast("Branch do not Belong to Customer", { type: toastMessage.messageType.ERROR });
        return;
      }

      actions.setSubmitting(true);

      const convStartDate = moment(data.startDate).tz(data.branch.timeZone, true).toDate();
      const convEndDate = moment(data.endDate).tz(data.branch.timeZone, true).toDate();

      const confirm = await swal2.confirmationDialog(
        "Are you sure to " + (isNew ? "add new " : "update") + "  Check List",
        "Confirmation"
      );

      if (confirm.status === ":OK") {
        let dataUrl = "/api/v1/checkLists" + (isNew ? "" : "/" + stateData._id);

        const users = checkListUsers.map((user) => user._id);

        const apiData = {
          masterData: {
            name: data.name,
            branch: data.branch._id,
            customer: data.customer._id,
            startDateTime: convStartDate,
            endDateTime: convEndDate,
            users,
            otherInfo: { criticalPathDuration: criticalPathData.duration },
          },
          transactionData: taskData,
        };

        if (!isNew) {
          apiData.deletedIDs = deletedIDs;
        }

        const respData = await axiosAPI.getSetAxiosData(isNew ? "post" : "patch", dataUrl, apiData);

        if (respData.status === "success") {
          toastMessage.DisplayToast(isNew ? "Check List Added" : "Check List  Updated", {
            type: toastMessage.messageType.SUCCESS,
          });

          deletedIDs = [];

          if (isNew) {
            actions.resetForm({});
            setTaskData(getIntitialData());
            setCheckListUsers([]);
            //branchData = null; //[{ _id: "", name: "" }];
            //setAutoCompleteKey(Math.random());
            actions.setSubmitting(false);
          } else {
            props.history.replace("/checkList", { isNew: false });
          }
        } else {
          toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
          actions.setSubmitting(false);
        }
      } else {
        actions.setSubmitting(false);
      }
    },
  });

  return (
    //    <DragDropContext onDragEnd={this.onDragEnd}>
    <React.Fragment>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={5} spacing={0}>
            <Typography variant="h6" style={{ color: theme.sidedrawer.subListTextColor }}>
              {formTitle}
            </Typography>
          </Grid>
          <Grid item xs={7} spacing={0} align="right">
            {!stateData.checkListStartTime ? null : getRemainingTimeJSX(stateData, isJobCompleted)}
          </Grid>

          {!isLoading ? (
            <React.Fragment>
              <Grid item xs={12}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="name"
                  autoComplete="nope"
                  label="Check List Name"
                  fullWidth
                  {...getFieldProps("name")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="customer"
                  autoComplete="nope"
                  label="Customer"
                  fullWidth
                  {...getFieldProps("customer")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="branch"
                  autoComplete="nope"
                  label="Site"
                  fullWidth
                  {...getFieldProps("branch")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="startDate"
                  autoComplete="nope"
                  label="Start Time"
                  fullWidth
                  {...getFieldProps("startDate")}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {!stateData.startedAt ? null : (
                  <Typography variant="subtitle1" style={{ marginRight: "10px", marginTop: "25px" }}>
                    <span style={{ color: theme.sidedrawer.subListTextColor }}>{"Started At: "}</span>
                    <span style={{ paddingLeft: "5px" }}>{moment(stateData.startedAt).format(dateFormat)}</span>
                  </Typography>
                )}
              </Grid>

              {isSMUp || 1 === 1 ? null : (
                <React.Fragment>
                  <Grid item xs={12} sm={7} align="center" style={{ marginTop: "15px" }}>
                    <Button
                      variant="contained"
                      size={currWidth === "xs" ? "small" : "medium"}
                      color="primary"
                      onClick={() => setShowPredecessor(!showPredecessor)}
                      style={{ textTransform: "none", marginRight: "5px" }}
                    >
                      Toggle Predecessor
                    </Button>
                    <Button
                      variant="contained"
                      size={currWidth === "xs" ? "small" : "medium"}
                      color="primary"
                      onClick={() => setShowSuccessor(!showSuccessor)}
                      style={{ textTransform: "none" }}
                    >
                      Toggle Successors
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={5} align="center" style={{ marginTop: "15px" }}>
                    <Button
                      variant="contained"
                      size={currWidth === "xs" ? "small" : "medium"}
                      color="secondary"
                      onClick={() => calculateCriticalPath(true)}
                      style={{ textTransform: "none" }}
                    >
                      Calculate Critical Path
                    </Button>
                  </Grid>
                </React.Fragment>
              )}

              <Grid
                item
                xs={12}
                md={10}
                style={{ display: "flex", justifyContent: isSMUp ? "flex-start" : "space-between" }}
              ></Grid>

              {isSMUp ? (
                <Grid item xs={2} align="right">
                  {isJobCompleted ? null : (
                    <Tooltip title="Refresh Data" aria-label="add-template">
                      <Fab color="primary" size="small">
                        <RefreshIcon />
                      </Fab>
                    </Tooltip>
                  )}
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
                    {mobileMenu}
                  </Grid>
                  <Grid item xs={2} align="right">
                    {isJobCompleted ? null : (
                      <Tooltip title="Refresh Data" aria-label="add-template">
                        <Fab color="primary" size="small">
                          <RefreshIcon />
                        </Fab>
                      </Tooltip>
                    )}
                  </Grid>
                </React.Fragment>
              )}

              <Grid item xs={12}>
                <Paper elevation={1} className={classes.paper}>
                  {taskData.map((task, index) => {
                    if (!isActiveTask(task)) {
                      return null;
                    } else {
                      return (
                        <TaskItem
                          key={task.taskID}
                          task={task}
                          index={index}
                          successorDependencyData={getDependencyData("S", task.taskID)}
                          predecessorDependencyData={getDependencyData("P", task.taskID)}
                          showPredecessor={showPredecessor}
                          showSuccessor={showSuccessor}
                          showCalcs={showCalcs}
                          deleteDependencyRelation={deleteDependencyRelation}
                          displayTaskInfo={displayTaskInfo}
                          updateTaskExecutionData={updateTaskExecutionData}
                          startDate={stateData.checkListStartTime}
                          currentUser={{ id: currentUserID, role: currentUserRole, email: currentUserEmail }}
                          isViewer={isViewer}
                          canStartTask={canStartTask}
                          canEndTask={canEndTask}
                        />
                      );
                    }
                  })}
                </Paper>
              </Grid>
            </React.Fragment>
          ) : null}
        </Grid>

        {!isSubmitting && !isLoading ? (
          <Grid
            container
            xs={12}
            spacing={2}
            style={{ marginTop: "15px" }}
            //direction="column"
            //alignItems="center"
            //justify={isSMUp ? "right" : "center"}
          >
            <Grid item xs={12} align={isSMUp ? "right" : "center"}>
              {isJobCompleted || isViewer ? null : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    updateTaskExecutionData("endJob");
                  }}
                  style={{ textTransform: "none", marginRight: "5px" }}
                >
                  End Job
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (isViewer) {
                    props.history.push("/jobDetail", {
                      summaryState: props.location.state.summaryState,
                      detailState: {
                        isNew: false,
                        customerData: props.location.state.jobQueryParams.customerData,
                        stateData: {
                          ...props.location.state.jobQueryParams,
                          userRole: props.location.state.stateData.userRole,
                        },
                        isViewer,
                      },
                    });
                  } else {
                    props.history.replace("/jobExecutionList", { isNew: false });
                  }
                }}
                style={{ textTransform: "none" }}
              >
                Exit
              </Button>
            </Grid>
          </Grid>
        ) : (
          <div style={{ marginTop: "15px", marginBottomxx: "30px" }}>
            <CircularProgress />
          </div>
        )}
      </div>
      <Hidden smDown implementation="css">
        <div style={{ width: drawerWidth }}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>
        </div>
      </Hidden>
      <FormDialog
        open={dialogOpen}
        currWidth={currWidth}
        title={
          taskFormData.isNew
            ? "Add New Task"
            : (taskFormData.displayOnly ? "Task Information" : "Update Task") +
              " ( TaskID : " +
              taskFormData.task.taskID +
              " )"
        }
        disableBackdrop={taskFormData.displayOnly ? false : true}
        handleClose={handleDialogClose}
      >
        <TaskForm taskDetails={taskFormData} handleClose={handleDialogClose}></TaskForm>
      </FormDialog>
      <FormDialog
        open={infoDialogOpen}
        currWidth={currWidth}
        title="Information"
        titleStyle={{ textAlign: "center" }}
        disableBackdrop={false}
        handleClose={handleInfoDialogClose}
      >
        {criticalPathJSXDisplay}
      </FormDialog>
      {/*       <FormDialog
        open={addDataDialogOpen}
        title={addCheckListData.formTitle}
        //titleStyle={{ textAlign: "center" }}
        handleClose={handleAddCheckListDataClose}
      >
        <AddCheckListDataForm
          fldDetails={addCheckListData}
          handleClose={handleAddCheckListDataClose}
          handleSubmit={addCheckListData.handleSubmit}
        ></AddCheckListDataForm>
      </FormDialog>
 */}{" "}
    </React.Fragment>
  );
};

export default JobExecutionForm;
