import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as axiosAPI from "../utils/getSetAPIData";

export default function AutoCompleteFormik(props) {

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const loading = open && options.length === 0;
  const {touched, errors, setTouched, fldName, url, labelName} = props;

  const limitLength = 2;

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleInputChange = async (event, newInputValue, reason) => {

    setInputValue(newInputValue);

    if (newInputValue.length > limitLength - 1 && reason === "input") {
      setOptions([]);
      setOpen(true);
    } else {
      setOpen(false);
      return;
    }

    const searchQueryString = "&searchField=name&searchValue=" + newInputValue;

//    const dataUrl = "/api/v1/other/getCountries?sort=name" + searchQueryString;
    const dataUrl = url + searchQueryString;

    const respData = await axiosAPI.getSetAxiosData("get", dataUrl);

    if (respData.status === "success") {
      const currentData = respData.data.data.data;
      if (currentData.length > 0) {
        setOptions(currentData); //(Object.keys(countries).map(key => countries[key].item[0]));
      } else {
        setOpen(false);
      }
    } else setOpen(false);
  };

  const handleOpen = () => {
    if (inputValue.length > limitLength - 1) {
      //setOpen(true);
    }
  };

  //useEffect(() => {
    //setValue(props.value1);
  //}, [props.value1]);

  //console.log("url", props.url);

  return (
    <Autocomplete
      id={"autocomplete-" + fldName}
      autoComplete="nope"
      fullWidth
      name={fldName}
      defaultValue={props.value1}
      open={open}
      onOpen={handleOpen}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => props.getOptionLabel(option)} //option.name
      options={options}
      loading={loading}
      {...(props.disabled && { disabled: true })}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onBlur={ () => setTouched({ [fldName]: true }) }
      onChange={(event, newValue) => { props.updateValue(newValue); }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={labelName}
          id={fldName}
          //name={fldName}
          fullWidth
          helperText={touched[fldName] ? errors[fldName] : ""}
          error={touched[fldName] && Boolean(errors[fldName])}
          InputProps={{
            ...params.InputProps,
            autoComplete:"nope",
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
