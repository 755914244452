import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from "@material-ui/core/Box";

import IconButton from "@material-ui/core/IconButton";
import  DeleteIcon from '@material-ui/icons/Delete';
import  EditIcon from '@material-ui/icons/Edit';

import DependencyArray from "./task-dependency";

import { Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    marginBottom: theme.spacing.unit * 1,
  },
  paper: {
    padding: 0, //theme.spacing(2),
    margin: "auto",
    maxWidth: "100%",
    minWidth: "100%",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));

const boxBorderColor = "rgba(0, 0, 255, 0.1)";

const boxProps = {
  //    bgcolor: 'background.paper',
  bgcolor: "rgba(255, 175, 0, 0.6)",
  m: 1,
  border: 1,
  padding: "5px",
  margin: 0,
  style: { width: "100%", height: "100%" },

  //style: { width: '5rem', height: '5rem' },
};

export default function TaskItem(props) {
  const classes = useStyles();
  const { task, index } = props;
  const isDragDisabled = task.id === "0" || task.id === "9999";

  const displayDeleteTaskButton = task.predecessor[0] === "0" && task.successor[0] === "9999" ? true : false;
  const displayEditTaskButton = task.id === "0" || task.id === "9999" ? false : true;
  
  //const dependencyData = props.getDependencyData("P", task.id);

  const handleDependencyDelete = (dependencyData, dependencyType) => {
    props.deleteDependencyRelation(props.task.id, dependencyType, dependencyData.id);
    return;
  };

  const handleDeleteTask = (taskID) => {
    props.deleteTask(taskID);
  };

  //console.log("depS_P", props.successorDependencyData, props.predecessorDependencyData);

  return (
    <Draggable draggableId={task.id} key={task.id + "drag"} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div
          className={classes.root}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          isDragDisabled={isDragDisabled}
        >
          <Paper elevation={3} className={classes.paper}>
            <Grid container>
              {props.showPredecessor ? (
                <Grid item xs={12} sm={3}>
                  <Box borderColor={boxBorderColor} {...boxProps}>
                    {/* <Typography variant="subtitle1">Column 1</Typography> */}
                    <DependencyArray
                      key={task.id + "-1"}
                      taskID={task.id}
                      dependencyType={"P"}
                      dependencyData={props.predecessorDependencyData}
                      handleDependencyDelete={handleDependencyDelete}
                    />
                  </Box>
                </Grid>
              ) : null}
              <Grid item xs={12} sm>
                <Box borderColor={boxBorderColor} {...boxProps}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      xxxxheight: "100%",
                    }}
                  >
                    <div>
                      <Typography variant="subtitle1">
                        {"ID: " + task.id + " => "} <span style={{ color: "blue" }}>{task.desc}</span>
                        {task.id != "0" && task.id != "9999" ? " Duration: " + task.duration : null}
                      </Typography>
                    </div>

                    <div style={{display: "flex"}}> 
                    {displayEditTaskButton ? (
                        <div>
                          <IconButton aria-label="edit" size="small" onClick={() => props.fillTaskDataForEdit(task)}>
                            <EditIcon />
                          </IconButton>
                        </div>
                      ) : null}

                      {displayDeleteTaskButton ? (
                        <div>
                          <IconButton aria-label="delete" size="small" onClick={() => props.deleteTask(task.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Box>
              </Grid>
              {props.showSuccessor ? (
                <Grid item xs={12} sm={3}>
                  <Box borderColor={boxBorderColor} {...boxProps}>
                    {/* <Typography variant="subtitle1">Column 2</Typography> */}
                    <DependencyArray
                      key={task.id + "-2"}
                      taskID={task.id}
                      dependencyType={"S"}
                      dependencyData={props.successorDependencyData}
                      handleDependencyDelete={handleDependencyDelete}
                    />
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </div>
      )}
    </Draggable>
  );
}
