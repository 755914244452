import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { ExpandLess, ExpandMore, VerticalSplit, StarBorder, PersonAdd, ListAlt, Autorenew } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";

import moment from "moment";

//import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import HomeIcon from "@material-ui/icons/Home";

import { Link } from "react-router-dom";

let subscriptionAlertTitle = null;
let subscriptionDaysRemaining = "";

const SideDrawer = (props) => {
  const [open, setOpen] = React.useState(false);
  const isDevelopment = process.env.NODE_ENV === "development" ? true : false;

  const { classes, container, mobileOpen, theme, authInfo } = props;
  let isTempDrawer = false;
  if (theme.globalVariables.currentSize === "sm" || theme.globalVariables.currentSize === "xs") {
    isTempDrawer = true;
  }

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    if (isTempDrawer) {
      props.DrawerToggle();
    }
  };

  let subscriptionAlertTitle = null;
  let daysRemaining = moment(authInfo.subscriptionDate).diff(moment().startOf("day"), "days");
  const subscriptionDaysRemainingTitle = `${daysRemaining} day(s) remaining`;

  if (authInfo.subscriptionType.toUpperCase() === "TRIAL") {
    subscriptionAlertTitle = "Trial Version";
  } else if(authInfo.subscriptionType.toUpperCase() !== "DEMO" && daysRemaining <= 10) {
    subscriptionAlertTitle = "Subscription Expiry";
  }

  //console.log("sidedrclass", classes.drawersubListText);

  const drawer = (
    <div>
      {/* <div className={classes.toolbar} /> */}
      <Divider />

      <List>
        <ListItem
          disabled={props.userRole === "user"}
          button
          component={Link}
          to="/"
          className={classes.drawerOptionText}
          onClick={handleClose}
        >
          <ListItemIcon>
            <HomeIcon className={classes.drawerOptionIcon} />
          </ListItemIcon>
          {/* <ListItemText primary={"Home " + theme.globalVariables.currentSize} /> */}
          <ListItemText primary={"Dashboard"} />
        </ListItem>
        <ListItem
          disabled={props.userRole !== "admin"}
          button
          component={Link}
          to="/user"
          className={classes.drawerOptionText}
          onClick={handleClose}
        >
          <ListItemIcon>
            <PersonAdd className={classes.drawerOptionIcon} />
          </ListItemIcon>
          <ListItemText primary={"Manage Users"} />
        </ListItem>
        <ListItem disabled={props.userRole !== "admin" && props.userRole !== "super-user"} button onClick={handleClick}>
          <ListItemIcon>
            <VerticalSplit className={classes.drawerSubListIcon} />
          </ListItemIcon>
          <ListItemText primary="Master Files" className={classes.drawerSubListText} />
          {open ? (
            <ExpandLess className={classes.drawerSubListIcon} />
          ) : (
            <ExpandMore className={classes.drawerSubListIcon} />
          )}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <ListItem button className={classes.nested + " " + classes.drawerOptionText} onClick={handleClose}> */}

            <ListItem
              button
              component={Link}
              to="/customer"
              disabled={props.userRole !== "admin"}
              className={classes.drawerOptionText}
              onClick={handleClose}
            >
              <ListItemText inset primary="Customers" />
            </ListItem>

            {/*
            <ListItem button className={classes.drawerOptionText} onClick={handleClose}>
              <ListItemText inset primary="Branches" />
            </ListItem>

             <ListItem button className={classes.drawerOptionText} onClick={handleClose}>
              <ListItemText inset primary="Tasks" />
            </ListItem>
 */}
            <ListItem
              button
              component={Link}
              to="/checkListTemplate"
              className={classes.drawerOptionText}
              onClick={handleClose}
            >
              <ListItemText inset primary="CheckList Template" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem
          disabled={props.userRole !== "admin" && props.userRole !== "super-user"}
          button
          component={Link}
          to="/checkList"
          className={classes.drawerOptionText}
          onClick={handleClose}
        >
          <ListItemIcon>
            <ListAlt className={classes.drawerOptionIcon} />
          </ListItemIcon>
          <ListItemText primary={"Manage CheckLists"} />
        </ListItem>
        <ListItem
          disabled={props.userRole === "viewer"}
          button
          component={Link}
          to="/jobExecutionList"
          className={classes.drawerOptionText}
          onClick={handleClose}
        >
          <ListItemIcon>
            <Autorenew className={classes.drawerOptionIcon} />
          </ListItemIcon>
          <ListItemText primary={"CheckList Execution"} />
        </ListItem>
        <Divider />
        {/*         <ListItem button component={Link} to="/cpmTesting" className={classes.drawerOptionText} onClick={handleClose}>
          <ListItemIcon>
            <StarBorder className={classes.drawerOptionIcon} />
          </ListItemIcon>
          <ListItemText primary={"Edit CheckList - CPM"} />
        </ListItem>
 */}
        {subscriptionAlertTitle ? (
          <React.Fragment>
            <ListItem>
              <ListItemText
                primary={subscriptionAlertTitle}
                secondary={subscriptionDaysRemainingTitle}
                className={classes.drawerSubListText}
                style={{ textAlign: "center", marginTop: '20px' }}
              />
            </ListItem>
            {/*             <ListItem>
              <ListItemText
                primary={subscriptionDaysRemainingTitle}
                //className={classes.drawerSubListText}
                style={{ textAlign: "center", fontSize: "12px" }}
              />
            </ListItem>
 */}
          </React.Fragment>
        ) : null}

        {isDevelopment ? (
          <React.Fragment>
            <ListItem button component={Link} to="/test" className={classes.drawerOptionText} onClick={handleClose}>
              <ListItemIcon>
                <StarBorder className={classes.drawerOptionIcon} />
              </ListItemIcon>
              <ListItemText primary={"Test Form 2"} />
            </ListItem>

            <ListItem button component={Link} to="/formikNew1" className={classes.drawerOptionText} onClick={handleClose}>
              <ListItemIcon>
                <StarBorder className={classes.drawerOptionIcon} />
              </ListItemIcon>
              <ListItemText primary={"Formik New 1"} />
            </ListItem>

            <ListItem button component={Link} to="/pathFinder" className={classes.drawerOptionText} onClick={handleClose}>
              <ListItemIcon>
                <StarBorder className={classes.drawerOptionIcon} />
              </ListItemIcon>
              <ListItemText primary={"Path Finder"} />
            </ListItem>

            <ListItem button component={Link} to="/newPathFinder" className={classes.drawerOptionText} onClick={handleClose}>
              <ListItemIcon>
                <StarBorder className={classes.drawerOptionIcon} />
              </ListItemIcon>
              <ListItemText primary={"New Path Finder"} />
            </ListItem>
          </React.Fragment>
        ) : null}
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={props.DrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          //PaperProps={{ onClick: handleClose }}
        >
          {/* <div className={classes.toolbar} /> */}

          <Grid container direction="row" justify="space-between" alignItems="center" className={classes.toolbar}>
            <Grid item>
              <Typography variant="h5" className={classes.drawerMainHeaderText} style={{ marginLeft: "10px" }}>
                Main Menu
              </Typography>
            </Grid>

            <Grid item className={classes.cloooseMenuButton}>
              <IconButton onClick={props.DrawerToggle}>
                <CancelIcon color="inherit" className={classes.close + " " + classes.drawerMainHeaderText} />
              </IconButton>
            </Grid>

            {/*             <div style={{ float: "left" }}>
            </div>
            <div
              style={{ float: "right" }}
              className={classes.clooooseMenuButton}
            >
            </div>
 */}
          </Grid>
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.toolbar} />

          {drawer}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

export default SideDrawer;
