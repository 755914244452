import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useFormik } from "formik";
import { makeStyles, useTheme, duration } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Document, Page, pdfjs } from "react-pdf";

import * as Yup from "yup";

import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import { AddMinutesToTaskTimeAndFormat, convertToDaysHoursMinutes } from "shared/utility";

//import samplePDF from './test.pdf';
import termsPDF from "assets/other/msrt_terms.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

//const styles = theme => ({
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "80%",
      width: "80%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "80%",
      width: "80%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
  },
  container: {
    display: "flex",
    //justifyContent: "center",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",

    //maxWidth: "100%",
    width: "100%",
    //    margin: 'auto',
  },
  pdfDocument: {
    display: "flex",
    //justifyContent: "center",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",

    //maxWidth: "100%",
    //width: "80%",
    //    margin: 'auto',
  },
  pdfPage: {
    //width: "100%",
  },
}));

let formTitle = "Add Userr";

function TermsOfUse(props) {
  const theme = useTheme();
  const classes = useStyles();

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <React.Fragment>
      <div className={classes.container} style={{ marginTop: "0px", marginBottom: "0px" }}>
        {/* <Paper elevation={1} className={classes.paper}> */}
        <Document className={classes.pdfDocument} file={termsPDF} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <React.Fragment>
              <Page
                renderMode="svg"
                className={classes.pdfPage}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
              />
              <p>
                Page {index + 1} of {numPages}
              </p>
            </React.Fragment>
          ))}
        </Document>
        {/* </Paper> */}
      </div>
    </React.Fragment>
  );
}

export default TermsOfUse;
