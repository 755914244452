import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import RootRef from "@material-ui/core/RootRef";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import FormDialog from "components/UI/dialog-form";
import TaskForm from "components/checkList/addAmmendTask";

import TaskItem from "./task-item-template";
import { Typography } from "@material-ui/core";

import * as chkListManager from "shared/checkList-manager";
import * as chkListCalculation from "shared/checkList-calculation";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as axiosAPI from "../utils/getSetAPIData";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import { useWidth, convertToDaysHoursMinutes } from "shared/utility";

// import { DisplayFormikState } from './formikHelper';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 330;

const useStyles = makeStyles((theme) => ({
  textField123: {
    color: "red",
  },
  paper: {
    display: "flex",
    flex: 1,
    //overflowY: "scroll",
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 1}px`,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100%",
      width: "100%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("xs")]: {
      maxWidth: "100%",
      width: "100%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: `calc(100% - ${drawerWidth}px)`,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    //flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
}));

const startTaskID = chkListManager.startTaskID;
const endTaskID = chkListManager.endTaskID;
let taskFormData = { task: {} };

const getIntitialData = () => {
  return chkListManager.getIntitialData();
};

let stateData = {};
let formTitle = "Add Userr";
let isNew = true;
let initialValues = {};
let deletedIDs = [];

const validationSchema = Yup.object({
  name: Yup.string("Enter a name").required("Name is required"),
});

const CheckListTemplate = (props) => {
  const [taskData, setTaskData] = useState([]);

  const [showPredecessor, setShowPredecessor] = useState(true);
  const [criticalPathJSX, setCriticalPathJSX] = useState({ jsxData: " ", duration: 0 });
  const [showSuccessor, setShowSuccessor] = useState(true);
  const [alertOpen, setAlertOpen] = useState({ open: false, message: " ", severity: "error" });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  //  const [autoCompleteKey, setAutoCompleteKey] = useState(Math.random());
  const [initialValues1, setInitialValues1] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const classes = useStyles();

  swal2.setTheme(theme);
  const currWidth = useWidth();
  const isSMUp = currWidth !== "sm" && currWidth !== "xs" ? true : false; //    useMediaQuery(theme.breakpoints.up("sm"));

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
  };

  useEffect(() => {
    //    setAutoCompleteKey(Math.random());
    (async () => {
      initialValues = {
        name: "",
      };

      formTitle = "Add CheckList Template";
      isNew = true;
      stateData = {};

      if (props.location.state && props.location.state.stateData) {
        stateData = props.location.state.stateData;
        isNew = false;
        formTitle = "Edit CheckList Template";

        setIsLoading(true);

        initialValues.name = stateData.name;

        let dataUrl = "/api/v1/checkListTemplates/" + stateData._id;

        const respData = await axiosAPI.getSetAxiosData("get", dataUrl);

        //console.log(respData);

        if (respData.status === "success") {
          setTaskData(respData.data.data.data);
        } else {
          toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
          props.history.replace("/checkListTemplate", { isNew: false });
        }

        setIsLoading(false);

        //console.log("state", stateData);
      } else {
        setTaskData(getIntitialData());
      }

      setInitialValues1(initialValues);
    })();
  }, [props.location.state]);

  //let criticalPathJSX = "dddddd "

  const calculateCriticalPath = (openInfoDialog = false) => {
    const criticalPathData = chkListCalculation.calculateCriticalPath(taskData);

    //    console.log(newTaskData);

    if (criticalPathData) {
      setCriticalPathJSX(criticalPathData);
    }

    if (openInfoDialog) {
      setInfoDialogOpen(true);
    }
  };

  const handleTaskFormSubmit = (taskFormData) => {
    handleDialogClose();

    const taskDetails = {
      duration: parseInt(taskFormData.duration),
      name: taskFormData.name,
      description: taskFormData.description,
    };

    if (taskFormData.isNew) {
      addNewTask(taskDetails);
    } else {
      taskDetails.taskID = taskFormData.taskID;
      editTask(taskDetails);
    }
  };

  const editTask = (taskDetails) => {
    const newTaskData = chkListManager.editTask(taskData, taskDetails);

    //    console.log(newTaskData);

    if (newTaskData) {
      setTaskData(newTaskData);
    }
  };

  const addNewTask = (taskDetails) => {
    console.log("new task data", taskDetails);

    const newTaskData = chkListManager.addNewTask(taskData, taskDetails);

    if (newTaskData) {
      setTaskData(newTaskData);
    }
  };

  const openTaskFormDialog = () => {
    taskFormData = {
      isNew: true,
      task: {
        taskID: "",
      },
      //        duration: task.duration,
      //        description: task.description,
      //        name: task.name,
    };

    setDialogOpen(true);
    return;
  };

  const fillTaskDataForEdit = (task) => {
    taskFormData = {
      isNew: false,
      task,
      //      taskID: task.taskID,
      //      duration: parseInt(task.duration),
      //      description: task.description,
      //      name: task.name,
    };

    setDialogOpen(true);
    return;
  };

  const displayTaskInfo = (task) => {
    taskFormData = {
      displayOnly: true,
      isNew: false,
      task,
      //      taskID: task.taskID,
      //      duration: parseInt(task.duration),
      //      description: task.description,
      //      name: task.name,
    };

    setDialogOpen(true);
    return;
  };

  function onDragEnd(result) {
    const newTaskData = chkListManager.onDragEnd(taskData, result);

    if (newTaskData) {
      setTaskData(newTaskData);
    }
  }

  const getDependencyData = (dependencyType, taskID) => {
    return chkListManager.getDependencyData(taskData, dependencyType, taskID);
  };

  const deleteDependencyRelation = (sourceTaskID, depedencyType, relatedTaskID) => {
    const newTaskData = chkListManager.deleteDependencyRelation(taskData, sourceTaskID, depedencyType, relatedTaskID);

    if (newTaskData) {
      setTaskData(newTaskData);
    }
  };

  const deleteTask = async (taskID) => {
    const confirm = await swal2.confirmationDialog("Are you sure to Delete Task: [ " + taskID + " ]", "Confirm Deletion", {
      type: "danger",
    });

    if (confirm.status === ":OK") {
      const returnData = chkListManager.deleteTask(taskData, taskID);
      if (returnData) {
        //console.log(returnData.deletedTask);
        if (returnData.deletedTask._id) {
          deletedIDs.push(returnData.deletedTask._id);
        }
        setTaskData(returnData.newTaskData);
      }
    }
  };

  const criticalPathJSXDisplay = (
    <React.Fragment>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography variant="h6">
          Critical Path{" "}
          {criticalPathJSX.duration === 0 ? " " : "( " + convertToDaysHoursMinutes(criticalPathJSX.duration) + " )"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {criticalPathJSX.jsxData}
      </Grid>
    </React.Fragment>
  );

  const drawer = (
    <Grid container spacing={2}>
      <Grid item xs={12} align="center" style={{ marginTop: "15px" }}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setShowPredecessor(!showPredecessor)}
          style={{ textTransform: "none", marginRight: "5px" }}
        >
          Toggle Predecessor
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setShowSuccessor(!showSuccessor)}
          style={{ textTransform: "none" }}
        >
          Toggle Successors
        </Button>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "center", marginTop: "5px" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => calculateCriticalPath()}
          style={{ textTransform: "none" }}
        >
          Calculate Critical Path
        </Button>
      </Grid>
      {criticalPathJSXDisplay}
    </Grid>
  );

  const {
    handleSubmit,
    setFieldValue,
    errors,
    isSubmitting,
    isValid,
    touched,
    getFieldProps,
    setTouched,
    resetForm,
    dirty,
    setSubmitting,
  } = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (data, actions) => {
      actions.setSubmitting(true);

      const confirm = await swal2.confirmationDialog(
        "Are you sure to " + (isNew ? "add new " : "update") + "  CheckList Template",
        "Confirmation"
      );

      if (confirm.status === ":OK") {
        let dataUrl = "/api/v1/checkListTemplates" + (isNew ? "" : "/" + stateData._id);

        const apiData = {
          masterData: { name: data.name },
          transactionData: taskData,
        };

        if (!isNew) {
          apiData.deletedIDs = deletedIDs;
        }

        const respData = await axiosAPI.getSetAxiosData(isNew ? "post" : "patch", dataUrl, apiData);

        if (respData.status === "success") {
          toastMessage.DisplayToast(isNew ? "CheckList Template Added" : "CheckList Template  Updated", {
            type: toastMessage.messageType.SUCCESS,
          });

          deletedIDs = [];

          if (isNew) {
            actions.resetForm({});
            setTaskData(getIntitialData());
            //            setAutoCompleteKey(Math.random());
            actions.setSubmitting(false);
          } else {
            props.history.replace("/checkListTemplate", { isNew: false });
          }
        } else {
          toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
          actions.setSubmitting(false);
        }
      } else {
        actions.setSubmitting(false);
      }
    },
  });

  return (
    //    <DragDropContext onDragEnd={this.onDragEnd}>
    <React.Fragment>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ color: theme.sidedrawer.subListTextColor }}>
              {formTitle}
            </Typography>
          </Grid>
          {!isLoading ? (
            <React.Fragment>
              <Grid item xs={10}>
                <TextField
                  style={{ marginBottom: "0px" }}
                  id="name"
                  autoComplete="nope"
                  helperText={touched.name ? errors.name : ""}
                  error={touched.name && Boolean(errors.name)}
                  label="Template Name"
                  fullWidth
                  {...getFieldProps("name")}
                  {...(!isNew && { InputLabelProps: { shrink: true } })}
                />
              </Grid>

              <Grid item xs={2} align="right">
                <Tooltip title="Add New Task" aria-label="add-template">
                  <Fab color="primary" size="medium" onClick={openTaskFormDialog}>
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </Grid>

              {isSMUp ? null : (
                <React.Fragment>
                  <Grid item xs={12} sm={7} align="center" style={{ marginTop: "15px" }}>
                    <Button
                      variant="contained"
                      size={currWidth === "xs" ? "small" : "medium"}
                      color="primary"
                      onClick={() => setShowPredecessor(!showPredecessor)}
                      style={{ textTransform: "none", marginRight: "5px" }}
                    >
                      Toggle Predecessor
                    </Button>
                    <Button
                      variant="contained"
                      size={currWidth === "xs" ? "small" : "medium"}
                      color="primary"
                      onClick={() => setShowSuccessor(!showSuccessor)}
                      style={{ textTransform: "none" }}
                    >
                      Toggle Successors
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={5} align="center" style={{ marginTop: "15px" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => calculateCriticalPath(true)}
                      style={{ textTransform: "none" }}
                    >
                      Calculate Critical Path
                    </Button>
                  </Grid>
                </React.Fragment>
              )}

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="taskitems" type="TASK" isCombineEnabled>
                  {(provided, snapshot) => (
                    <Paper elevation={1} className={classes.paper} ref={provided.innerRef} {...provided.droppableProps}>
                      {taskData.map((task, index) => (
                        <TaskItem
                          key={task.taskID}
                          task={task}
                          index={index}
                          successorDependencyData={getDependencyData("S", task.taskID)}
                          predecessorDependencyData={getDependencyData("P", task.taskID)}
                          showPredecessor={showPredecessor}
                          showSuccessor={showSuccessor}
                          deleteDependencyRelation={deleteDependencyRelation}
                          deleteTask={deleteTask}
                          fillTaskDataForEdit={fillTaskDataForEdit}
                          displayTaskInfo={displayTaskInfo}
                        />
                      ))}
                      {provided.placeholder}
                    </Paper>
                  )}
                </Droppable>
              </DragDropContext>
            </React.Fragment>
          ) : null}
        </Grid>

        {!isSubmitting && !isLoading ? (
          <Grid container spacing={2} style={{ marginTop: "15px" }}>
            <Grid item xs={12} align={isSMUp ? "right" : "center"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ textTransform: "none", marginRight: "10px" }}
              >
                Save Check List
              </Button>
              {isNew ? null : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.history.replace("/checkListTemplate", { isNew: false });
                  }}
                  style={{ textTransform: "none" }}
                >
                  Cancel
                </Button>
              )}
            </Grid>
          </Grid>
        ) : (
          <div style={{ marginTop: "15px", marginBottomxx: "30px" }}>
            <CircularProgress />
          </div>
        )}
      </div>

      <Hidden smDown implementation="css">
        <div style={{ width: drawerWidth }}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
          >
            <div className={classes.toolbar} />
            {drawer}
          </Drawer>
        </div>
      </Hidden>

      <FormDialog
        open={dialogOpen}
        currWidth={currWidth}
        title={
          taskFormData.isNew
            ? "Add New Task"
            : (taskFormData.displayOnly ? "Task Information" : "Update Task") +
              " ( TaskID : " +
              taskFormData.task.taskID +
              " )"
        }
        disableBackdrop={taskFormData.displayOnly ? false : true}
        handleClose={handleDialogClose}
      >
        <TaskForm taskDetails={taskFormData} handleClose={handleDialogClose} handleSubmit={handleTaskFormSubmit}></TaskForm>
      </FormDialog>

      <FormDialog
        open={infoDialogOpen}
        currWidth={currWidth}
        title="Information"
        titleStyle={{ textAlign: "center" }}
        disableBackdrop={false}
        handleClose={handleInfoDialogClose}
      >
        {criticalPathJSXDisplay}
      </FormDialog>

      <Snackbar
        open={alertOpen.open}
        autoHideDuration={4000}
        onClose={() => {
          setAlertOpen({ open: false });
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setAlertOpen({ open: false });
          }}
          severity={alertOpen.severity}
        >
          {alertOpen.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default CheckListTemplate;
