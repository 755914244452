import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    flexWrap: "wrap",
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    width: "100%",
    minWidth: "100%",
    //    margin: 'auto',
  },
  paper: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    //padding: theme.spacing(0.5),
    //backgroundColor: "orange"
    flex: 1,
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const boxBorderColor = "rgba(0, 0, 255, 0.1)";

const boxProps = {
  //    bgcolor: 'background.paper',
  bgcolor: "rgba(255, 175, 0, 0.6)",
  m: 1,
  border: 1,
  padding: "1px",
  margin: 0,
  style: { width: "100%", height: "100%" },

  //style: { width: '5rem', height: '5rem' },
};

export default function CheckListUsers(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="subtitle1" style={{ marginRight: "5px" }}>
        {"Users:"}
      </Typography>
      <Paper elevation={3} className={classes.paper}>
        <Box borderColor={boxBorderColor} {...boxProps}>
          {props.checkListUsers.length === 0 ? (
            <Chip size="small" color="secondary" key={"nouser"} label="No user Added" className={classes.chip} />
          ) : (
            props.checkListUsers.map((user) => {
              let icon;

              const disableDelete = false; // (props.taskID === "0" || props.taskID === "9999" || data.taskID === "0" || data.taskID === "9999") ? true : false;

              return (
                <Chip
                  size="small"
                  color="primary"
                  key={user._id + "-user"}
                  icon={icon}
                  label={user.name}
                  onDelete={disableDelete ? undefined : () => props.handleCheckListUserDelete(user)}
                  className={classes.chip}
                />
              );
            })
          )}
        </Box>
      </Paper>
    </div>
  );
}
