import React, { Component, forwardRef, useState, useEffect, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MaterialTable, { MTableToolbar } from "material-table";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//import axios from "axios";

import * as axiosAPI from "../utils/getSetAPIData";
import tableIcons, { addCustomButton } from "../utils/materialTableIcons";
import * as materialTableDefaults from "../utils/materialTableDefaults";
import * as toastMessage from "../utils/toastMessages";
import * as swal2 from "../utils/sweet2-toast-dialog";
import mtQueryClass from "shared/mtQueryDataClass";
import isEqual from "lodash/isEqual";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { Redirect } from "react-router-dom";

//const styles = theme => ({
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing.unit * 2,
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "95%",
      width: "95%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    [theme.breakpoints.up("lg")]: {
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 0}px`,
      maxWidth: "80%",
      width: "80%",
      //      minHeight: `calc(100% - theme.mixins.toolbar.minHeight)`
    },
    width: "100%",
    padding: 0,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    //    flexDirection: "column",
    //    alignItems: "center",

    //maxWidth: "100%",
    //width: "100%",
    //    margin: 'auto',
  },
}));

const detailPanel = (rowData) => {
  const fieldArray = [
    ["Contact Person:", "contactPerson"],
    ["Email:", "email"],
    ["Address:", "address"],
    ["TelePhone:", "telephone"],
    ["City:", "city"],
    ["State:", "state"],
    ["Country:", "country"],
    ["Postal Code:", "postalCode"],
  ];

  return (
    <div style={{ marginTop: "15px", marginBottom: "15px" }}>
      {fieldArray.map((tableField) => {
        return (
          <Grid container spacing={1} alignItems="center" flex={1}>
            <Grid item xs={4} style={{ paddingLeft: "10px" }}>
              <Typography variant="subtitle2" component="span" color="primary">
                {tableField[0]}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" component="span">
                {tableField[1] === "country" ? rowData.country.name : rowData[tableField[1]]}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

let respData = {};
let lastMTQuery = {};
let tableColumns = [];
const title = "Customers";
//let tableClickActions = {};
const mtQuery = new mtQueryClass();
mtQuery.searchUrl = "&searchField=name&searchValue=";
mtQuery.url = "/api/v1/customers?sort=name";

//class FormikInputForm extends Component {

function CustomerList(props) {
  const classes = useStyles();
  const theme = useTheme();
  //const [alertOpen, setAlertOpen] = useState({ open: false, message: " ", severity: "error" });
  const [serverError, setServerError] = useState({ isError: false, message: " ", type: "SERVER", statusCode: 0 });
  //const [useInitialQuery, setUseInitialQuery] = useState(true);

  const isSMUp = useMediaQuery(theme.breakpoints.up("sm"));
  swal2.setTheme(theme);
  const tableRef = React.useRef();

  const getMTActions = () => {
    const tableClickActions = {
      onClickAdd: (event) => {
        props.history.push("/customerForm", { isNew: true });
      },
      onClickEdit: (event, rowData) => {
        const { tableData, ...stateData } = rowData;
        props.history.replace("/customerForm", { isNew: false, customerData: stateData });
      },

      onClickDelete: async (event, rowData) => {
        //alert("You want to delete " + rowData.name);
        //setAlertOpen({ open: true, message: "Record Deleted", severity: "success" });
        //toastMessage.DisplayToast("Record Deleted", { type: toastMessage.messageType.SUCCESS });

        //const ss = await swal2.confirmationDialog("AMir Zulfiqar Deleted", "Confirm Deletion",  { type: 'danger' });

        //console.log("sweet result", ss);

        const lastQuery = tableRef.current.state.query;
        //console.log("last query bef delete",lastQuery);

        const confirm = await swal2.confirmationDialog("Are you sure to Delete Customer", "Confirm Deletion", {
          type: "danger",
        });

        if (confirm.status === ":OK") {
          const dataUrl = "/api/v1/customers/" + rowData._id;

          const respData = await axiosAPI.getSetAxiosData("delete", dataUrl);

          if (respData.status === "success") {
            toastMessage.DisplayToast("Customer Deleted...", { type: toastMessage.messageType.SUCCESS });

            setTimeout(function () {
              tableRef.current.onQueryChange(lastQuery);
            }, 300);
          } else {
            toastMessage.DisplayToast(respData.message, { type: toastMessage.messageType.ERROR });
          }
        }
      },
    };

    addCustomButton("addBranch", "Sites");

    const mtActions = materialTableDefaults.mtActions(props, tableIcons, { tableClickActions }, "Customer");

    mtActions.push({
      icon: tableIcons.addBranch,
      //iconProps: { style: { fontSize: "14px", color: "blue" } },
      tooltip: "Add Sites",
      onClick: (event, rowData) => {
        props.history.push("/branch", { isNew: true, customerData: rowData });
      },
    });

    return mtActions;
  };

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [isSMUp]);

  if (isSMUp) {
    tableColumns = [
      { title: "Name", field: "name" }, // width: 500, minWidth: 500 },
      { title: "Email", field: "email" },
      { title: "City", field: "city" },
      { title: "Country", field: "country.name" },
      //{ title: "Doğum Yılı", field: "birthYear", type: "numeric" },
      /*       {
        title: "Doğum Yeri",
        field: "birthCity",
        lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
      },
 */
    ];
  } else {
    tableColumns = [
      { title: "Name", field: "name" },
      //{ title: "Soyadı", field: "surname" },
      //{ title: "Doğum Yılı", field: "birthYear", type: "numeric" },
      //{ title: "Email", field: "email" }
    ];
  }

  //  useEffect(() => {
  //console.log("tableref", tableRef);
  //  }, [tableRef]);

  //  console.log("PAGE RENDERED");

  const GetQueryData = (query) => {
    return mtQuery.getMaterialTableQueryData(query, setServerError);
  };

  //console.log("currdata", currentData) ;
  //  alert("render");
  return (
    <React.Fragment>
      {/* <ToastMessages alertOpen={alertOpen} setAlertOpen={setAlertOpen} /> */}
      {/* <ToastContainer /> */}
      <div className={classes.container}>
        {/* <Paper elevation={1} className={classes.paper}> */}
        {/* <h2>User Form</h2> */}
        <div className={classes.paper}>
          {serverError.isError ? (
            <div style={{ width: "100%" }}>
              <h1 style={{ textAlign: "center" }}>{serverError.statusCode === "403" ? "UNAUTHORIZED" : "SERVER ERROR"}</h1>
              <h2 style={{ textAlign: "center" }}>{serverError.message}</h2>
            </div>
          ) : (
            <MaterialTable
              //              isLoading={true}
              tableRef={tableRef}
              components={{
                Toolbar: (props) => (
                  <React.Fragment>
                    {!isSMUp ? (
                      <div style={{ width: "100%", marginTop: "15px" }}>
                        <Typography variant="h6" color="primary" align="center" gutterBottom>
                          {" "}
                          {title}{" "}
                        </Typography>
                      </div>
                    ) : null}

                    <div style={{ color: theme.sidedrawer.subListTextColor }}>
                      <MTableToolbar {...props} />
                    </div>
                  </React.Fragment>
                ),
              }}
              style={{ width: "100%" }}
              columns={tableColumns}
              //data={[{ name: "Mehmet", surname: "Baran", birthYear: 1987, birthCity: 63 }]}
              data={GetQueryData}
              title={isSMUp ? title : ""}
              icons={tableIcons}
              {...(!isSMUp && { detailPanel: detailPanel })}
              {...(!isSMUp && { onRowClick: (event, rowData, togglePanel) => togglePanel() })}
              actions={getMTActions()}
              options={{
                sorting: false,
                detailPanelType: "single",
                actionsColumnIndex: -1,
                actionsCellStyle: {
                  //backgroundColor: "#ffccdd",
                  color: theme.sidedrawer.subListTextColor, // "rgba(255, 175, 0, 0.6)"
                },
                headerStyle: {
                  backgroundColor: "#01579b", //'#e8eaf5',
                  color: "#FFF",
                },
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} of {count}",
                },
              }}
            />
          )}

          {/* </Hidden> */}
        </div>
        {/* </Paper> */}
      </div>
    </React.Fragment>
  );
}

export default CustomerList;
